import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Row, Space, Txt } from "@stenajs-webui/core";
import { StandardTable } from "@stenajs-webui/grid";
import { StandardTableHtmlCopyToClipboardButton } from "@stenajs-webui/grid-export";
import React, { useRef } from "react";
import { PaddedHeading } from "../../../../common/components/padded-heading/PaddedHeading";
import { transformSailingTypeCodeToSailingTypeName } from "../../../../common/transformers/SailingTypeTransformer";
import { PriceCalculatorFormData } from "../../types";
import { tableConfigHtmlExport } from "../config/PriceCalculatorHtmlRenderTableConfig";
import { tableConfig } from "../config/PriceDetailsTableConfig";
import { PriceDetailTableRow } from "../config/PriceDetailTableRow";
import {
  transformPriceDetailTableRows,
  transformQueryToPriceDetailTableRow,
} from "../transformers";

interface Props {
  customerCalculatedState: PriceCalculatorFormData;
  routeName: string;
}

export const PriceCalculatorSectionalResultTab: React.FC<Props> = ({
  customerCalculatedState,
  routeName,
}) => {
  const headingH5HolderForCopy = useRef<HTMLDivElement>(null);
  const priceCalculatorData =
    customerCalculatedState.customerResultData?.priceCalculatorData[0];

  const customerCalculatedStateDepartureTime =
    customerCalculatedState.departureTime?.slice(0, 2) +
    ":" +
    customerCalculatedState.departureTime?.slice(2, 4);

  let tripInformation = `${routeName}, ${customerCalculatedState.sailingDate},
  ${
    customerCalculatedState.sectionalDepartureTimeManual
      ? customerCalculatedState.departureTime
      : customerCalculatedStateDepartureTime
  }`;

  if (customerCalculatedState.sailingType) {
    tripInformation += `, ${transformSailingTypeCodeToSailingTypeName(
      customerCalculatedState.sailingType
    )}`;
  }

  const vehicleInformation = `${customerCalculatedState.vehicleShortDescription}, ${customerCalculatedState.vehicleLength}m`;

  const renderCopy = (html: string): string => {
    if (customerCalculatedState.vehicleType) {
      return `<b>${tripInformation}</b>
      <br>
      <br>
       <b>${vehicleInformation}</b>
      ${headingH5HolderForCopy.current?.innerHTML}
      ${html}`;
    }

    return `<b>${tripInformation}</b>
    <br>
    <br>
    ${headingH5HolderForCopy.current?.innerHTML}
    ${html}`;
  };

  const priceDetailTableRows: PriceDetailTableRow[] =
    (priceCalculatorData &&
      transformPriceDetailTableRows(
        priceCalculatorData,
        transformQueryToPriceDetailTableRow(priceCalculatorData)
      )) ??
    [];

  return (
    <>
      <Box spacing={3} indent={3} style={{ paddingBottom: "8px" }}>
        <Row justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <PaddedHeading
              variant={"h4"}
              padding={0}
              data-testid={testIdConstants.priceCalculatorResultTripInformation}
            >
              {tripInformation}
            </PaddedHeading>
          </Box>

          <StandardTableHtmlCopyToClipboardButton
            config={tableConfigHtmlExport}
            items={priceDetailTableRows.filter((tr) => tr.priceExclVat > 0)}
            renderContent={renderCopy}
            size={"medium"}
          />
        </Row>
      </Box>
      <Box indent={3} spacing={2} style={{ paddingTop: "0px" }}>
        <Txt
          size={"large"}
          data-testid={testIdConstants.priceCalculatorResultVehicleType}
        >
          {vehicleInformation}
        </Txt>
      </Box>
      <Row indent={3} spacing={1} style={{ paddingTop: "0px" }}>
        <Box ref={headingH5HolderForCopy}>
          <PaddedHeading variant={"h5"} padding={0}>
            Articles specification
          </PaddedHeading>
        </Box>
      </Row>
      <div data-testid={testIdConstants.priceCalculatorResultPriceTable}>
        <StandardTable config={tableConfig} items={priceDetailTableRows} />
      </div>
      <Space num={SpaceValues.SIXTEEN} />
    </>
  );
};
