import { Box, Indent, Row, Spacing } from "@stenajs-webui/core";

import {
  Banner,
  FlatButton,
  PrimaryButton,
  Spinner,
} from "@stenajs-webui/elements";
import * as React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { PriceCalculatorSearchInfoRowItem } from "./PriceCalculatorCustomerInfoRowItem";

import { PaddedHeading } from "../../../../common/components/padded-heading/PaddedHeading";
import { cssColor } from "@stenajs-webui/theme";
import {
  PriceCalculatorCustomerInformationQuery,
  PriceCalculatorCustomerInformationQueryVariables,
} from "@/gql/graphql";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

const query = gql`
  query PriceCalculatorCustomerInformation($customerId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          name
          custIndex
          custNo
          statisticalGrouping {
            id
            custNo
          }
          parent {
            id
            custNo
          }
        }
      }
    }
  }
`;

export interface Props {
  selectedCustomerNum: number;
  onChangeCustomer: () => void;
  mode: "booking" | "customer";
}

export const PriceCalculatorSearchCustomerDetails: React.FC<Props> = ({
  selectedCustomerNum,
  onChangeCustomer,
  mode,
}) => {
  const { loading, error, data } = useQuery<
    PriceCalculatorCustomerInformationQuery,
    PriceCalculatorCustomerInformationQueryVariables
  >(query, {
    variables: {
      customerId: String(selectedCustomerNum),
    },
  });
  const customerDetails = data?.productPrice.customers.byId;
  if (error) {
    return (
      <Box indent={3} spacing={3}>
        <Banner
          headerText={"Could not fetch customer information"}
          text={error.message}
          variant={"error"}
          contentRight={
            <PrimaryButton
              size={"medium"}
              label={mode === "customer" ? "Change customer" : "Change booking"}
              onClick={onChangeCustomer}
            />
          }
        />
      </Box>
    );
  }

  return (
    <Box indent={3}>
      <Spacing num={1.5} />
      <Box background={cssColor("--lhds-color-ui-200")} indent={4} spacing={1}>
        <Row justifyContent={"space-between"}>
          <PaddedHeading variant={"h4"} padding={2.5}>
            {loading && <Spinner size={"small"} />} {customerDetails?.name}
          </PaddedHeading>
          <Box width={"150px"} justifyContent={"center"} alignItems={"center"}>
            <FlatButton
              size={"medium"}
              label={mode === "customer" ? "Change customer" : "Change booking"}
              onClick={onChangeCustomer}
              data-testid={
                testIdConstants.priceCalculatorCalculateChangeCustomerButton
              }
            />
          </Box>
        </Row>
        <Row height={32}>
          <PriceCalculatorSearchInfoRowItem
            label={"No."}
            value={customerDetails?.custNo}
          />
          <Indent num={2} />
          <PriceCalculatorSearchInfoRowItem
            label={"Index"}
            value={customerDetails?.custIndex}
          />
          <Indent num={2} />
          <PriceCalculatorSearchInfoRowItem
            label={"Stat no."}
            value={customerDetails?.statisticalGrouping?.custNo}
          />
          <Indent num={2} />
          <PriceCalculatorSearchInfoRowItem
            label={"Parent no."}
            value={customerDetails?.parent?.custNo ?? customerDetails?.custNo}
          />
        </Row>
        <Spacing num={1} />
      </Box>
    </Box>
  );
};
