import { formatDate } from "@/common/formatters/DateFormatter";
import { FormModelChangeHandler } from "@/common/forms/types";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { PriceCalculatorAllMultiLegRoutesQuery } from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { Box, Row, Space, Text } from "@stenajs-webui/core";
import { Select } from "@stenajs-webui/select";
import { gql } from "apollo-boost";
import * as React from "react";
import { useMemo } from "react";

const query = gql`
  query PriceCalculatorAllMultiLegRoutes {
    productPrice {
      route {
        allMultiLeg {
          id
          name
          code
          routeLegs {
            id
            legNo
            legRoute {
              code
              id
              name
            }
          }
        }
      }
    }
  }
`;

export interface RouteLeg {
  id: string;
  legNo: number;
  legRoute: { code: string };
}

export interface MultiLegOptionData {
  code: string;
  id: string;
  name: string;
  routeLegs?: Array<RouteLeg>;
}
interface MultiLegOption {
  label: string;
  value: string;
  data: MultiLegOptionData;
}

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const transformToRouteLegs = (routeLegs: RouteLeg[]) =>
  routeLegs.map((routeLeg) => ({
    routeCode: routeLeg.legRoute.code,
    sailingTypeCode: "P",
    departureDate: formatDate(new Date().toISOString()),
    departureTime: "11:11",
  }));

export const PriceCalculatorSelectMultiLegRoute: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  const { loading, data } =
    useQuery<PriceCalculatorAllMultiLegRoutesQuery>(query);

  const multiLegRoutes = useMemo(() => {
    return data?.productPrice?.route?.allMultiLeg;
  }, [data]);

  const options = useMemo<MultiLegOption[] | undefined>(() => {
    return multiLegRoutes?.map((r) => ({
      label: r.code + " - " + r.name,
      value: r.code,
      data: r,
    }));
  }, [multiLegRoutes]);

  const selected = useMemo(
    () => options?.find((o) => o.value === formData.routeCode) || null,
    [options, formData.routeCode]
  );

  return (
    <Row alignItems={"center"}>
      <Text>Route</Text>
      <Space />
      <Box width={"240px"}>
        <Select
          isLoading={loading}
          options={options}
          isClearable
          value={selected}
          onChange={(value) => {
            onChangeFormData("multiLegRouteData", value?.data);
            onChangeFormData("routeCode", value?.data.code);
            value?.data?.routeLegs &&
              onChangeFormData(
                "routeLegs",
                transformToRouteLegs(value.data.routeLegs)
              );
          }}
          menuPortalTarget={document.body}
        />
      </Box>
    </Row>
  );
};
