import { ZIndex } from "@/common/utils/ZIndexEnum";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { Box } from "@stenajs-webui/core";
import {
  Tab,
  TabMenu,
  stenaBusinessNslh,
  stenaOverview,
} from "@stenajs-webui/elements";
import { PageHeader, PageHeaderRow, PageHeading } from "@stenajs-webui/panels";
import { cssColor } from "@stenajs-webui/theme";
import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routeFactory } from "../../RouteFactory";
import { NslhInvoiceTab, listInvoicesTabs } from "./config/tabConfig";
import { GapValues } from "@/common/utils/GapValues";

interface Props {
  actionBar?: React.ReactNode;
  breadCrumbs?: React.ReactNode;
  headingText: string;
}

export const NslhPageHeader: React.FC<Props> = ({
  actionBar = null,
  breadCrumbs,
  headingText,
}) => {
  const navigate = useNavigate();
  const { tab: selectedTab } = useParams() as { tab: string };
  return (
    <Box
      background={cssColor("--lhds-color-ui-50")}
      width={"100vw"}
      position={"sticky"}
      left={0}
      zIndex={ZIndex.lowest}
    >
      <PageHeader
        renderPageHeading={() => (
          <PageHeading icon={stenaBusinessNslh} heading={headingText} />
        )}
        renderTabs={() => (
          <TabMenu>
            {listInvoicesTabs.map((tab) => (
              <Fragment key={tab.value}>
                {tab.value === NslhInvoiceTab.overview}
                <Tab
                  data-testid={`tab_${tab.value}`}
                  leftIcon={
                    tab.value === NslhInvoiceTab.overview
                      ? stenaOverview
                      : faFileAlt
                  }
                  label={tab.label}
                  selected={
                    (!selectedTab && NslhInvoiceTab.overview === tab.value) ||
                    selectedTab === tab.value
                  }
                  onClick={() =>
                    navigate(
                      routeFactory.noShowLateHandling.followUpTabView(tab.value)
                    )
                  }
                />
              </Fragment>
            ))}
          </TabMenu>
        )}
      >
        <PageHeaderRow gap={GapValues.SIXTEEN} height={"54px"}>
          {actionBar}
        </PageHeaderRow>
      </PageHeader>
    </Box>
  );
};
