import {
  CustomerRouteFetcherHeadAgreementFragment,
  CustomerRouteFetcherRouteAgreementFragment,
} from "@/gql/graphql";
import { Column } from "@stenajs-webui/core";
import * as React from "react";
import { PageLayout } from "../../../../common/components/page-layout/PageLayout";
import { CustomerRouteStatisticsBoxes } from "../../../customer-route-statistic-boxes/components/CustomerRouteStatisticsBoxes";
import { useCustomer } from "../../customers/common/customer-head-agreement-header/UseCustomer";
import { useNegotiationYears } from "../../customers/common/negotiation-year/NegotiationYearGate";
import { CustomerViewInfoPairBoxes } from "../../info-boxes/CustomerViewInfoPairBoxes";
import { BulkUpdateManyBox } from "../../rate-sheet/bulk-update-rates/components/BulkUpdateManyBox";
import { RecalculationBoxForMultipleRouteAgreements } from "../../stats-calculation/recalc-stats-for-single-route-agreement-price/components/RecalculationBoxForMultipleRouteAgreements";
import { CustomerRateSheetList } from "./CustomerRateSheetList";
import { CustomerRouteHeader } from "./CustomerRouteHeader";
import { useMemo, useState } from "react";
import { routeAgreementCanBeBulkUpdated } from "../../rate-sheet/bulk-update-rates/validators/RouteAgreementBulkUpdateValidator";

interface Props {
  routePairId: string;
  customerId: string;
  headAgreement: CustomerRouteFetcherHeadAgreementFragment;
  routeAgreements: Array<CustomerRouteFetcherRouteAgreementFragment>;
}

export const CustomerRoute: React.FC<Props> = ({
  routePairId,
  headAgreement,
  customerId,
  routeAgreements,
}) => {
  const { defaultYear } = useNegotiationYears();
  const [bulkUpdateModeEnabled, setBulkUpdateModeEnabled] = useState(false);
  const { loading: loadingCustomer, customer } = useCustomer(
    customerId,
    defaultYear
  );

  const sectionalRouteAgreements = routeAgreements.filter(
    (ra) => !ra.multiLegAgrRouteId
  );

  const numRouteAgreementsCanBeBulkUpdated = useMemo(
    () =>
      sectionalRouteAgreements.filter(routeAgreementCanBeBulkUpdated).length,
    [sectionalRouteAgreements]
  );

  return (
    <Column>
      {customer && (
        <CustomerRouteHeader
          customer={customer}
          routePairId={routePairId}
          headAgreement={headAgreement}
          routeAgreementsForRoutePair={sectionalRouteAgreements}
          loadingCustomer={loadingCustomer}
        />
      )}
      <PageLayout>
        {customer && (
          <CustomerViewInfoPairBoxes
            customer={customer}
            headAgreement={headAgreement}
            headAgreementId={headAgreement.id}
            justifyContentRow={"flex-start"}
            switchButtonDisabled={true}
            customerPopoverEnabled
          />
        )}
        <CustomerRouteStatisticsBoxes />
        <RecalculationBoxForMultipleRouteAgreements
          customerId={customerId}
          routeAgreements={sectionalRouteAgreements}
        />
        <BulkUpdateManyBox
          setBulkUpdateModeEnabled={setBulkUpdateModeEnabled}
          bulkUpdateModeEnabled={bulkUpdateModeEnabled}
          totalNumRouteAgreements={sectionalRouteAgreements.length}
          numRouteAgreementsCanBeBulkUpdated={
            numRouteAgreementsCanBeBulkUpdated
          }
        />
        <CustomerRateSheetList
          bulkUpdateModeEnabled={bulkUpdateModeEnabled}
          customerId={customerId}
          headAgreement={headAgreement}
          routeAgreements={sectionalRouteAgreements}
          routePairId={routePairId}
        />
      </PageLayout>
    </Column>
  );
};
