import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";
import * as React from "react";
import { Crumb } from "../../../../../common/components/bread-crumbs/Crumb";
import { PageHeader } from "../../../../../common/components/page-header/PageHeader";
import { routeFactory } from "../../../../../RouteFactory";
import { renderRoutePairLabelWithIcon } from "../../../../../common/utils/RoutePairLabelWithDescriptionInTable";
import {
  BreadCrumbs,
  FlatButton,
  stenaStatisticsBar,
} from "@stenajs-webui/elements";
import { Space } from "@stenajs-webui/core";
import { NegotiationYearSelect } from "@/common/components/data-driven-inputs/selects/negotiation-year-select/NegotiationYearSelect";
import { CurrencySelect } from "@/common/components/data-driven-inputs/selects/currency-select/CurrencySelect";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { useStatisticsYearSelect } from "@/features/statistics/common/hooks/UseStatisticsYearSelector";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  StatisticsContext,
  StatisticsContextType,
} from "@/features/statistics/StatisticsContext";
import { useContext } from "react";
import { SearchFilterActionsContext } from "@stenajs-webui/filter";
import { transformStatusItemsToFilterStatus } from "@/features/statistics/common/utils/statisticsUtil";
import { useFilterWorkspace } from "@/features/search-filter/hooks/UseFilterWorkspace";
import {
  StatisticsByRouteDetailsHeaderQuery,
  StatisticsByRouteDetailsHeaderQueryVariables,
} from "@/gql/graphql";

interface Props {
  routeId: string;
  currency: string;
  handleCurrencyChange: (currency: string) => void;
}

const query = gql`
  query StatisticsByRouteDetailsHeader($routeId: ID!) {
    productPrice {
      routePair {
        byId(id: $routeId) {
          id
          code
          description
          seaArea {
            id
            name
          }
        }
      }
    }
  }
`;

export const StatisticsByRoutePairDetailsHeader: React.FC<Props> = ({
  routeId,
  currency,
  handleCurrencyChange,
}) => {
  const { data, loading } = useQuery<
    StatisticsByRouteDetailsHeaderQuery,
    StatisticsByRouteDetailsHeaderQueryVariables
  >(query, { variables: { routeId } });
  const navigate = useNavigate();

  const { year, handleYearChange } = useStatisticsYearSelect();
  const dispatch = useDispatch();

  const onSelectCurrencyChange = (currency: string) => {
    handleCurrencyChange(currency);
  };
  const filterWorkspace = useFilterWorkspace();

  const { routePairSelectedStatuses } = React.useContext(
    StatisticsContext
  ) as StatisticsContextType;
  const searchFilterActions = useContext(SearchFilterActionsContext);

  const routePair = data?.productPrice.routePair.byId;
  const updateAndSetCustomerTabState = () => {
    if (routePair) {
      dispatch(searchFilterActions.clearFormModel());
      dispatch(
        searchFilterActions.setFormModelFields({
          negotiationStatus: transformStatusItemsToFilterStatus(
            routePairSelectedStatuses
          ),
          routePairs: [
            {
              code: routePair.code,
              id: routePair.id,
              name: routePair.code,
              seaArea: {
                id: routePair.seaArea.id,
                name: routePair.seaArea.name,
              },
            },
          ],
        })
      );
      filterWorkspace.commit();
    }
  };

  return (
    <PageHeader
      zIndex={ZIndex.low}
      title={renderRoutePairLabelWithIcon(
        routePair?.description ?? "",
        routePair?.code
      )}
      icon={stenaStatisticsBar}
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"statistics"}
            path={routeFactory.statistics.totalTab()}
          />
          <Crumb label={"Route"} path={routeFactory.statistics.routeTab()} />
          <Crumb
            label={routePair?.code ?? ""}
            isLast
            path={
              routePair
                ? routeFactory.statistics.routeDetails(routePair.id)
                : ""
            }
          />
        </BreadCrumbs>
      }
      contentRight={
        <>
          <FlatButton
            disabled={loading}
            label="Show customer list "
            onClick={() => {
              updateAndSetCustomerTabState();
              navigate(routeFactory.statistics.customerTab());
            }}
          />
          <Space num={SpaceValues.SIXTEEN} />
          <NegotiationYearSelect
            value={year}
            onValueChange={(value) => handleYearChange(value)}
          />
          <Space num={SpaceValues.SIXTEEN} />
          <CurrencySelect
            value={currency}
            onValueChange={onSelectCurrencyChange}
            containerWidth={"80px"}
          />
        </>
      }
    />
  );
};
