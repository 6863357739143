import { formatDate } from "@/common/formatters/DateFormatter";
import { FormModelChangeHandler } from "@/common/forms/types";
import { useFormModelState } from "@/common/hooks/UseFormModelState";
import React, { PropsWithChildren, createContext, useState } from "react";
import {
  MovementType,
  PriceCalculatorFormData,
  SearchEntityType,
} from "./types";

const initialFormDataState: PriceCalculatorFormData = {
  selectedCustomerNum: undefined,
  movementType: MovementType.SECTIONAL,
  sectionalDepartureTimeManual: true,
  departureTime: "11:11",
  sailingType: "P",
  moreOptionsPanelCollapsed: true,
  loadingPreferenceEnabled: false,
  loadingPreference: undefined,
  routeCode: undefined,
  multiLegRouteData: undefined,
  conditionsStandby: false,
  conditionsTransit: false,
  conditionsGoods: [],
  conditionsAncillary: [],
  vehicleType: "",
  vehicleWidth: "0",
  vehicleWeight: "0",
  vehiclePlugins: 0,
  vehicleLength: "0",
  vehicleTradeUnits: 0,
  vehicleTradeWeight: "0",
  searchEntityType: SearchEntityType.CUSTOMER,
  passengersAdults: 0,
  passengersChildren: 0,
  passengersInfants: 0,
  passengersSleepers: 0,
  cargoHazardousGoods: false,
  cargoHazardousGoodsAmountRows: 0,
  cargoHazardousGoodsRowWeight: "0",
  sailingDate: formatDate(new Date().toISOString()),
  bookingResultData: undefined,
  customerResultData: undefined,
  cargoHazardousGoodsQualified: false,
  conditionsReceiverCountryCode: undefined,
  conditionsSenderCountryCode: undefined,
  drivers: undefined,
  includePendingOffered: false,
  livestock: false,
  livestockType: undefined,
  routeLegs: undefined,
  vehicleShortDescription: undefined,
  bookingNumber: undefined,
  version: undefined,
  customsClassificationCode: undefined,
};

export interface PriceCalculatorContextType {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  setState: React.Dispatch<React.SetStateAction<PriceCalculatorFormData>>;
  defaultFormState: PriceCalculatorFormData;
  setDefaultFormState: React.Dispatch<
    React.SetStateAction<PriceCalculatorFormData>
  >;
  customerCalculatedState: PriceCalculatorFormData;
  setCustomerCalculatedState: React.Dispatch<
    React.SetStateAction<PriceCalculatorFormData>
  >;
}

const PriceCalculatorContext = createContext<PriceCalculatorContextType | null>(
  null
);

const PriceCalculatorProvider = ({ children }: PropsWithChildren<{}>) => {
  const [defaultFormState, setDefaultFormState] =
    useState<PriceCalculatorFormData>(initialFormDataState);
  const [formData, onChangeFormData, setState] =
    useFormModelState<PriceCalculatorFormData>(defaultFormState);
  const [customerCalculatedState, setCustomerCalculatedState] =
    useState<PriceCalculatorFormData>(formData);

  return (
    <PriceCalculatorContext.Provider
      value={{
        formData,
        onChangeFormData,
        setState,
        setDefaultFormState,
        defaultFormState,
        customerCalculatedState,
        setCustomerCalculatedState,
      }}
    >
      {children}
    </PriceCalculatorContext.Provider>
  );
};

export { PriceCalculatorContext, PriceCalculatorProvider };
