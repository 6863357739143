import { gql } from "apollo-boost";

export const routeAgreementPageFragment = gql`
  fragment RouteAgreementPageRouteAgreement on RouteAgreement {
    id
    statusCode
    agreementNumber
    rowVersion
    hasArticles
    hasConditions
    externalComment
    note
    comment
    hasMatrix
    name
    routeDescription
    sailingTypeCodes
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        id
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        id
        name
      }
    }
    currency {
      id
      code
    }
    headAgreement {
      headAgreementNumber
      id
      name
      breadCrumbName
    }
  }
`;
export const rateSheetTablePricesFragment = gql`
  fragment RateSheetTablePrices on RouteAgreementPrice {
    id
    volume
    lengthFrom
    lengthTo
    weightFrom
    weightTo

    unaccompanied
    lastChargedFrom

    cargoStatusCode

    avgGrossPrice {
      amount
    }

    avgNetPrice {
      amount
    }

    avgRebate {
      amount
    }

    avgLength

    additionalFrom
    actual

    vehicleType {
      id
      name
      shortDescription
      accompanied
      code
    }

    lastPrice {
      amount
    }

    lastMeterPrice {
      amount
    }

    newPrice {
      amount
    }

    meterPrice {
      amount
    }
  }
`;
export const customerRouteRouteAgreementFragment = gql`
  ${rateSheetTablePricesFragment}
  fragment CustomerRouteRouteAgreement on RouteAgreement {
    id
    name
    agreementNumber
    routeDescription
    sailingTypeCodes
    externalDescription
    multiLegAgrRouteId
    isSalesReady
    typeCode
    statusCode
    rowVersion
    currency {
      id
      code
    }
    valid {
      start {
        isoString
      }
      end {
        isoString
      }
    }
    hasConditions
    prices {
      id
      ...RateSheetTablePrices
    }
    routes {
      id
      code
      routeType
      routePair {
        id
        code
        description
      }
    }
  }
`;
