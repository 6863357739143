import { SelectProps } from "@stenajs-webui/select";
import * as React from "react";
import { useAllCountries } from "../country-multi-select/CountryMultiSelect";
import { CountrySelectUi } from "./CountrySelectUi";

interface Props extends Omit<SelectProps<unknown>, "value" | "onChange"> {
  value: string | null;
  onValueChange: (value: string) => void;
}

export const CountrySelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const { data, loading } = useAllCountries();

  const countries = data?.productPrice.country.all ?? [];

  return (
    <CountrySelectUi
      isLoading={loading}
      countries={countries}
      onValueChange={(value: string) => onValueChange(value)}
      value={value}
      {...selectProps}
    />
  );
};
