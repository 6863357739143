import { Card, stenaSailingRoute } from "@stenajs-webui/elements";
import {
  createStandardTableInitialState,
  StandardTable,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TARGET_EDIT_CLAIM } from "../../../../common/claims/constants";
import { hasClaim } from "../../../../common/claims/utils";
import { UnAuthorizedView } from "../../../../common/components/unauthorized-view/UnAuthorizedView";
import { useDefaultYearSelection } from "../../../../common/redux/profile/hooks/UseDefaultYearSelection";
import { profileSelectors } from "../../../../common/redux/profile/selectors";

import { PageHeader } from "../../../../common/components/page-header/PageHeader";
import {
  routeTargetOverviewTableConfig,
  RouteTargetsListTableColumnKeys,
} from "../config/RouteTargetsListTableConfig";
import { useTargetOverviewsQuery } from "../hooks/UseTargetOverviewsQuery";
import { setRouteTargetsOverviewNegotiationYear } from "../redux/routeTargetsOverviewReducer";
import { RouteTargetsOverviewListHeader } from "./RouteTargetsOverviewListHeader";

interface Props {}

const noItemsLabel = "Please select negotiation year to list route targets.";

export const RouteTargetsOverviewList: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const { year: defaultYear, loading: loadingYears } =
    useDefaultYearSelection();
  const [year, setYear] = useState<number>(defaultYear);

  useEffect(() => {
    dispatch(setRouteTargetsOverviewNegotiationYear(year));
  }, [year, dispatch]);

  const { claims } = useSelector(profileSelectors.getGlobal);
  const { targetOverviews, loading, fetchTargetOverview } =
    useTargetOverviewsQuery();

  useEffect(() => {
    if (year) {
      fetchTargetOverview({ variables: { year } });
    }
  }, [year, fetchTargetOverview]);

  const { tableContext } = useLocalStateTableContext(
    "route-target-list",
    createStandardTableInitialState<RouteTargetsListTableColumnKeys>(
      "routePairCode",
      false
    )
  );

  return (
    <>
      <PageHeader
        icon={stenaSailingRoute}
        sticky
        offsetLeft={0}
        width={"100vw"}
        title={"Route targets"}
      />
      {hasClaim(claims, [TARGET_EDIT_CLAIM]) ? (
        <Card>
          <RouteTargetsOverviewListHeader setYear={setYear} />
          <StandardTable
            config={routeTargetOverviewTableConfig}
            items={targetOverviews ?? []}
            noItemsLabel={noItemsLabel}
            tableContext={tableContext}
            loading={loading || loadingYears}
            error={undefined}
          />
        </Card>
      ) : (
        <UnAuthorizedView />
      )}
    </>
  );
};
