import { transformSailingTypeCodeToSailingTypeName } from "@/common/transformers/SailingTypeTransformer";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import {
  FetchBookingFragment,
  FetchPriceCalculatorFragment,
} from "@/gql/graphql";
import { Box, Heading, Row, Space, Spacing } from "@stenajs-webui/core";
import { StandardTable } from "@stenajs-webui/grid";
import { StandardTableHtmlCopyToClipboardButton } from "@stenajs-webui/grid-export";
import React, { useRef } from "react";
import { BookingResultData } from "../../types";
import { tableConfigHtmlExport } from "../config/PriceCalculatorHtmlRenderTableConfig";
import { tableConfig } from "../config/PriceDetailsTableConfig";
import { PriceDetailTableRow } from "../config/PriceDetailTableRow";
import {
  parseTimeString,
  transformAncillaryCosts,
  transformPriceDetailTableRows,
  transformSurchargeCosts,
} from "../transformers";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  bookingResultData: BookingResultData;
}
const transformQueryToPriceDetailTableRow = (
  priceCalculatorData: FetchPriceCalculatorFragment
): Array<PriceDetailTableRow> => {
  return [
    ...priceCalculatorData.ancillaryRecords.map<PriceDetailTableRow>(
      (record, index) =>
        transformAncillaryCosts(record, priceCalculatorData, index)
    ),
    ...priceCalculatorData.surchargeRecords.map<PriceDetailTableRow>(
      (record, index) =>
        transformSurchargeCosts(
          record,
          priceCalculatorData,
          index + priceCalculatorData.ancillaryRecords.length
        )
    ),
  ];
};

const createTripInformation = (
  sailingArchive: FetchBookingFragment["sailingBookingConsignment"]
) => {
  let tripInformation = `${sailingArchive.route.name}, ${
    sailingArchive.departureDate
  },
  ${parseTimeString(sailingArchive.departureTime)}`;
  if (sailingArchive.sailingType) {
    tripInformation += `, ${transformSailingTypeCodeToSailingTypeName(
      sailingArchive.sailingType
    )}`;
  }

  return tripInformation;
};

export const PriceCalculatorBookingDetailSectionalTab: React.FC<Props> = ({
  bookingResultData,
}) => {
  const headingH5HolderForCopy = useRef<HTMLDivElement>(null);

  const bookingData = bookingResultData.bookingData[0];
  const priceCalculatorData = bookingResultData.priceCalculatorData[0];
  const tripInformation = createTripInformation(
    bookingData.sailingBookingConsignment
  );
  const vehicleInformation = `${bookingData.vehicleType.shortDescription}, ${bookingData.length}m`;
  const renderCopy = (html: string): string => {
    if (bookingData.vehicleType) {
      return `<b>${tripInformation}</b>
      <br>
      <br>
      <b>${vehicleInformation}</b>
      ${headingH5HolderForCopy.current?.innerHTML}
      ${html}`;
    }

    return `<b>${tripInformation}</b>
    <br>
    <br>
    <b>${vehicleInformation}</b>
    ${headingH5HolderForCopy.current?.innerHTML}
    ${html}`;
  };

  const priceDetailTableRows: PriceDetailTableRow[] =
    transformPriceDetailTableRows(
      priceCalculatorData,
      transformQueryToPriceDetailTableRow(priceCalculatorData)
    );

  return (
    <>
      <Box indent={IndentValues.TWENTYFOUR}>
        <Row justifyContent={"space-between"}>
          <Box
            alignSelf={"flex-end"}
            data-testid={
              testIdConstants.priceCalculatorResultPriceTripInformation
            }
          >
            <Heading variant={"h4"}>{tripInformation}</Heading>
          </Box>

          <StandardTableHtmlCopyToClipboardButton
            config={tableConfigHtmlExport}
            items={priceDetailTableRows.filter((tr) => tr.priceExclVat > 0)}
            renderContent={renderCopy}
            size={"medium"}
          />
        </Row>
      </Box>
      <Space num={SpaceValues.SIXTEEN} />
      <Box
        indent={IndentValues.TWENTYFOUR}
        data-testid={
          testIdConstants.priceCalculatorResultPriceVehicleInformation
        }
      >
        <Heading variant={"h4"}>{vehicleInformation}</Heading>
      </Box>
      <Space num={SpaceValues.SIXTEEN} />
      <Row indent={IndentValues.TWENTYFOUR}>
        <Box ref={headingH5HolderForCopy}>
          <Heading variant={"h5"}>Articles specification</Heading>
        </Box>
      </Row>
      <Spacing>
        <div data-testid={testIdConstants.priceCalculatorResultPriceTable}>
          <StandardTable config={tableConfig} items={priceDetailTableRows} />
        </div>
      </Spacing>
    </>
  );
};
