import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { GapValues } from "@/common/utils/GapValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import {
  PriceCalculatorContext,
  PriceCalculatorContextType,
} from "@/features/price-calculator-v2/PriceCalculatorContext";
import {
  MovementType,
  MovementTypeLabels,
} from "@/features/price-calculator-v2/types";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { Fragment, useContext } from "react";
import { PriceCalculatorSelectMultiLegRoute } from "../multi-leg/PriceCalculatorSelectMultiLegRoute";

export const PriceCalculatorMovementTypeSelect: React.FC = () => {
  const { formData, onChangeFormData, setState, defaultFormState } = useContext(
    PriceCalculatorContext
  ) as PriceCalculatorContextType;
  return (
    <Box spacing={SpacingValues.TWENTYFOUR}>
      <Heading variant={"h4"}>Select movement type</Heading>
      <Space num={SpaceValues.SIXTEEN} />
      <Row gap={GapValues.TWENTYFOUR} alignItems={"center"} height={"36px"}>
        {Object.keys(MovementType).map((type) => (
          <Fragment key={type}>
            <RadioButtonWithLabel
              label={MovementTypeLabels.get(type) ?? ""}
              checked={formData.movementType === (type as MovementType)}
              value={type}
              data-testid={testIdConstants.priceCalculatorMovementTypeSelection}
              onValueChange={(selectedMovementType) => {
                setState({
                  ...defaultFormState,
                  selectedCustomerNum: formData.selectedCustomerNum,
                  moreOptionsPanelCollapsed: formData.moreOptionsPanelCollapsed,
                });
                onChangeFormData(
                  "movementType",
                  selectedMovementType as MovementType
                );
              }}
            />
          </Fragment>
        ))}
        {formData.movementType === MovementType.MULTILEG && (
          <PriceCalculatorSelectMultiLegRoute
            onChangeFormData={onChangeFormData}
            formData={formData}
          />
        )}
      </Row>
    </Box>
  );
};
