import {
  FetchBookingFragment,
  FetchPriceCalculatorFragment,
} from "@/gql/graphql";
import { Spacing, Text } from "@stenajs-webui/core";
import { AuditData, PriceCalculatorFormData } from "../../types";

export const transformAuditLogsToText = (
  auditLogs: Array<string>,
  usePricingV2: boolean
) => {
  const asterisks = "***************";
  const auditLogsWithoutAsteriks = auditLogs.filter(
    (auditLog) => auditLog !== asterisks
  );

  // If usePricingV2 is true we fetch and use auditLogs else auditTrail
  // Hence the function to resolve them differently
  if (usePricingV2) {
    return auditLogsWithoutAsteriks.map((auditLog, index) =>
      auditLog.includes("Total Price") ? (
        <Spacing key={index}>
          <Text variant={"bold"}>{auditLog}</Text>
        </Spacing>
      ) : (
        <Text key={index}>{auditLog}</Text>
      )
    );
  }

  return auditLogs[0].split("\r\n").map((text, index) => {
    if (text.substr(0, asterisks.length) === asterisks) {
      const searchRegExp = new RegExp("\\*", "g");
      const textWithoutAsteriks = text.replace(searchRegExp, "");
      return (
        <Spacing key={index}>
          <Text variant={"bold"}>{textWithoutAsteriks}</Text>
        </Spacing>
      );
    }
    return <Text key={index}>{text}</Text>;
  });
};

export const transformBookingResultToAuditData = (
  data: FetchPriceCalculatorFragment,
  booking: FetchBookingFragment
): AuditData => {
  return {
    errorMessage: data.errorMessage ?? "",
    auditLogs: (data.auditLogs && data.auditLogs) ?? [],
    departureDate: booking.sailingBookingConsignment.departureDate,
    routeCode: booking.sailingBookingConsignment.route.code,
    sailingCategory: booking.sailingBookingConsignment.sailingType
      ? String(booking.sailingBookingConsignment.sailingType)
      : "",
  };
};

export const transformCustomerResultToAuditData = (
  data: FetchPriceCalculatorFragment,
  customerCalculatedState: PriceCalculatorFormData
): AuditData => {
  return {
    errorMessage: data.errorMessage ?? "",
    auditLogs: (data.auditLogs && data.auditLogs) ?? [],
    departureDate: customerCalculatedState.sailingDate
      ? customerCalculatedState.sailingDate
      : "",
    routeCode: customerCalculatedState.routeCode,
    sailingCategory: customerCalculatedState.sailingType,
  };
};
