import { TextInputProps } from "@stenajs-webui/forms";
import React from "react";
import { NumericTextInput } from "../numeric-text-input/NumericTextInput";

interface Props extends TextInputProps {
  dataTestid?: string;
}

export const NumericAgrVehicleWeightTextInput: React.FC<Props> = ({
  value,
  variant,
  onValueChange,
  dataTestid,
  disabled,
}) => {
  return (
    <NumericTextInput
      value={value}
      maxNumbers={6}
      maxDecimals={0}
      onValueChange={onValueChange}
      variant={variant}
      dataTestid={dataTestid}
      disabled={disabled}
    />
  );
};
