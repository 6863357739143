import { FormModelChangeHandler } from "@/common/forms/types";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { Box, Row, Space } from "@stenajs-webui/core";
import { FormLabelWithChildren } from "../PriceCalculatorCustomerForm";
import { GoodsSelect } from "@/common/components/data-driven-inputs/selects/goods-select/GoodsSelect";
import { SpaceValues } from "@/common/utils/SpaceValues";
import {
  FlatButton,
  SecondaryButton,
  stenaMinus,
  stenaPlusCircle,
} from "@stenajs-webui/elements";
import { NumericAgrVehicleWeightTextInput } from "@/common/components/numeric-agr-vehicle-weight-text-input/NumericAgrVehicleWeightTextInput";
import { Tooltip } from "@stenajs-webui/tooltip";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { GapValues } from "@/common/utils/GapValues";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorAddGoodsRow: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  const isGoodsWeightEmpty = (index: number) =>
    formData?.conditionsGoods?.[index]?.weight === "";

  return (
    <Row gap={GapValues.TWENTYFOUR}>
      <Box width={200}>
        <FormLabelWithChildren label={"Goods code"}>
          {formData?.conditionsGoods?.map((goods, index) => (
            <Box key={`goods-select-${index}`}>
              <GoodsSelect
                value={goods.goodsCode}
                onValueChange={(goodsCode) => {
                  const mergedConditionsGoods = formData.conditionsGoods;
                  if (mergedConditionsGoods) {
                    mergedConditionsGoods[index] = {
                      ...mergedConditionsGoods[index],
                      goodsCode,
                    };
                    onChangeFormData("conditionsGoods", mergedConditionsGoods);
                  }
                }}
                height={"38px"}
                menuPortalTarget={document.body}
                menuPlacement={"auto"}
                minMenuHeight={300}
              />
              <Space num={SpaceValues.SIXTEEN} />
            </Box>
          ))}
          <SecondaryButton
            label={"Add goods"}
            leftIcon={stenaPlusCircle}
            onClick={() => {
              onChangeFormData("conditionsGoods", [
                ...(formData?.conditionsGoods || []),
                { goodsCode: "", weight: "0" },
              ]);
            }}
            size={"medium"}
          />
        </FormLabelWithChildren>
      </Box>
      <FormLabelWithChildren label={"Weight (kg)"}>
        {formData.conditionsGoods?.length === 0 ? (
          <Row gap={GapValues.TWENTYFOUR}>
            <NumericAgrVehicleWeightTextInput disabled />
            <FlatButton leftIcon={stenaMinus} />
          </Row>
        ) : (
          formData?.conditionsGoods?.map((goods, index) => (
            <Row gap={GapValues.TWENTYFOUR} key={`weight-row-${index}`}>
              <Box width={187}>
                <Tooltip
                  label={
                    isGoodsWeightEmpty(index) ? "Please provide a weight" : ""
                  }
                  zIndex={ZIndex.lowest}
                  variant="warning"
                  appendTo={document.body}
                  disabled={!isGoodsWeightEmpty(index)}
                >
                  <NumericAgrVehicleWeightTextInput
                    value={goods.weight}
                    onValueChange={(weight) => {
                      const mergedConditionsGoods = formData.conditionsGoods;
                      if (mergedConditionsGoods) {
                        mergedConditionsGoods[index] = {
                          ...mergedConditionsGoods[index],
                          weight,
                        };
                        onChangeFormData(
                          "conditionsGoods",
                          mergedConditionsGoods
                        );
                      }
                    }}
                    variant={isGoodsWeightEmpty(index) ? "warning" : "standard"}
                  />
                </Tooltip>
                <Space num={SpaceValues.SIXTEEN} />
              </Box>
              <FlatButton
                leftIcon={stenaMinus}
                onClick={() =>
                  onChangeFormData("conditionsGoods", [
                    ...(formData?.conditionsGoods || []).filter(
                      (item, formIndex) => index !== formIndex
                    ),
                  ])
                }
              />
            </Row>
          ))
        )}
      </FormLabelWithChildren>
    </Row>
  );
};
