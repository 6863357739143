import { CountrySelect } from "@/common/components/data-driven-inputs/selects/country-select/CountrySelect";
import { FormModelChangeHandler } from "@/common/forms/types";
import { GapValues } from "@/common/utils/GapValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import { CheckboxWithLabel } from "@stenajs-webui/forms";
import * as React from "react";
import { FormLabelWithChildren } from "../PriceCalculatorCustomerForm";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorConditions: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  return (
    <Box>
      <Heading variant={"h4"}>Conditions</Heading>
      <Space num={SpaceValues.SIXTEEN} />
      <Row gap={GapValues.TWENTYFOUR}>
        <CheckboxWithLabel
          label={"Standby"}
          value={formData.conditionsStandby}
          onValueChange={(value) =>
            onChangeFormData("conditionsStandby", value)
          }
        />

        <CheckboxWithLabel
          label={"Transit"}
          value={formData.conditionsTransit}
          onValueChange={(value) =>
            onChangeFormData("conditionsTransit", value)
          }
        />
      </Row>
      <Space num={SpaceValues.TWENTYFOUR} />
      <Row gap={GapValues.TWENTYFOUR}>
        <FormLabelWithChildren label={"Country sender"} width={200}>
          <CountrySelect
            value={formData.conditionsSenderCountryCode ?? ""}
            onValueChange={(value) =>
              onChangeFormData("conditionsSenderCountryCode", value)
            }
            menuPortalTarget={document.body}
            menuPlacement={"auto"}
            isClearable
          />
        </FormLabelWithChildren>
        <FormLabelWithChildren label={"Country receiver"} width={200}>
          <CountrySelect
            value={formData.conditionsReceiverCountryCode ?? ""}
            onValueChange={(value) =>
              onChangeFormData("conditionsReceiverCountryCode", value)
            }
            menuPortalTarget={document.body}
            menuPlacement={"auto"}
            isClearable
          />
        </FormLabelWithChildren>
      </Row>
    </Box>
  );
};
