import { RouteSelect } from "@/common/components/data-driven-inputs/selects/route-select/RouteSelect";
import { FormModelChangeHandler } from "@/common/forms/types";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { GapValues } from "@/common/utils/GapValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { DateTextInput } from "@stenajs-webui/calendar";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import { FlatButton } from "@stenajs-webui/elements";
import * as React from "react";
import { useEffect } from "react";
import { useSailingsByRouteCodeDepartureDate } from "../common/hooks/UseSailingsByRouteCodeDepartureDate";
import { PriceCalculatorDepartureCategorySelect } from "../common/PriceCalculatorDepartureCategorySelect";
import { PriceCalculatorDepartureTimeInput } from "../common/PriceCalculatorDepartureTimeInput";
import {
  enterManuallyDefaultOption,
  SailingCategoryTimeTableSelect,
} from "../common/PriceCalculatorTimeTableSelect";
import { FormLabelWithChildren } from "../PriceCalculatorCustomerForm";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  setState: React.Dispatch<React.SetStateAction<PriceCalculatorFormData>>;
  defaultFormState: PriceCalculatorFormData;
}

export const PriceCalculatorSailingDetails: React.FC<Props> = ({
  formData,
  onChangeFormData,
  setState,
  defaultFormState,
}) => {
  const { sailingDeparturesLoading, sailings, fetchSailings } =
    useSailingsByRouteCodeDepartureDate();

  useEffect(
    () => {
      if (
        formData.routeCode &&
        formData.sailingDate &&
        formData.sailingDate.length > 9 &&
        isNaN(new Date(formData.sailingDate).getUTCFullYear()) !== true
      ) {
        setState({
          ...formData,
          sailingType: defaultFormState.sailingType ?? "",
          departureTime: defaultFormState.departureTime ?? "",
          sectionalDepartureTimeManual: true,
        });
        fetchSailings({
          variables: {
            routeCode: formData.routeCode ?? "",
            departureDate: formData.sailingDate ?? "",
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      formData.routeCode,
      formData.sailingDate,
      fetchSailings,
      defaultFormState.departureTime,
      defaultFormState.sailingType,
      setState,
    ]
  );
  return (
    <Box>
      <Row justifyContent={"space-between"} alignItems={"center"}>
        <Heading variant={"h4"}>Sailing details</Heading>
        <FlatButton
          label={"Clear"}
          onClick={() =>
            setState({
              ...defaultFormState,
              selectedCustomerNum: formData.selectedCustomerNum,
              movementType: formData.movementType,
              moreOptionsPanelCollapsed: formData.moreOptionsPanelCollapsed,
            })
          }
        />
      </Row>
      <Space num={SpaceValues.SIXTEEN} />
      <Row gap={GapValues.TWENTYFOUR}>
        <FormLabelWithChildren
          label={"Route"}
          data-testid={testIdConstants.priceCalculatorSectionalRouteSelect}
          minWidth={137}
        >
          <RouteSelect
            labelFormat={(route) => route.code}
            value={formData.routeCode}
            onValueChange={(value) => onChangeFormData("routeCode", value)}
            menuPortalTarget={document.body}
          />
        </FormLabelWithChildren>
        <FormLabelWithChildren label={"Date"} minWidth={140}>
          <DateTextInput
            width={"inherit"}
            value={formData.sailingDate}
            onValueChange={(value) => {
              onChangeFormData("sailingDate", value);
            }}
            portalTarget={document.body}
            calendarProps={{
              highlightToday: true,
            }}
          />
        </FormLabelWithChildren>
        <FormLabelWithChildren
          label={"Timetable"}
          data-testid={testIdConstants.priceCalculatorSectionalTimetableSelect}
          width={180}
        >
          <SailingCategoryTimeTableSelect
            value={
              formData.sectionalDepartureTimeManual
                ? enterManuallyDefaultOption.value
                : formData.sailingType
            }
            onValueChange={(selectedCategory) => {
              const enterTimeManually =
                selectedCategory.value === enterManuallyDefaultOption.value;
              onChangeFormData("sailingType", defaultFormState.sailingType);
              onChangeFormData("departureTime", defaultFormState.departureTime);
              onChangeFormData(
                "sectionalDepartureTimeManual",
                enterTimeManually
              );
              if (!enterTimeManually) {
                onChangeFormData(
                  "sailingType",
                  selectedCategory.value?.charAt(0)
                );
                onChangeFormData(
                  "departureTime",
                  selectedCategory.departureTime
                );
              }
            }}
            loading={sailingDeparturesLoading}
            sailings={sailings}
          />
        </FormLabelWithChildren>
        <FormLabelWithChildren
          label={"Sailing category"}
          data-testid={
            testIdConstants.priceCalculatorSectionalSailingCategorySelect
          }
          width={140}
        >
          <PriceCalculatorDepartureCategorySelect
            value={formData.sailingType}
            manualDeparture={formData.sectionalDepartureTimeManual!}
            onValueChange={(sailingCategory) => {
              onChangeFormData("sailingType", sailingCategory.value);
            }}
            disabled={!formData.sectionalDepartureTimeManual}
          />
        </FormLabelWithChildren>
        <FormLabelWithChildren
          label={"Departure time"}
          data-testid={
            testIdConstants.priceCalculatorSectionalDepartureTimeInput
          }
          width={65}
        >
          <PriceCalculatorDepartureTimeInput
            value={
              !formData.sectionalDepartureTimeManual
                ? ""
                : formData.departureTime ?? ""
            }
            onChange={(value) => onChangeFormData("departureTime", value)}
            disabled={!formData.sectionalDepartureTimeManual}
          />
        </FormLabelWithChildren>
      </Row>
    </Box>
  );
};
