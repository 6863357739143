import { FetchPriceCalculatorFragment } from "@/gql/graphql";

export const calculateAdditionalRecordsVatAmount = (
  priceCalculator: FetchPriceCalculatorFragment
) => {
  let totalVat = 0;
  if (
    priceCalculator.ancillaryRecords &&
    priceCalculator.ancillaryRecords.length > 0
  ) {
    for (let i = 0; i < priceCalculator.ancillaryRecords.length; i++) {
      totalVat += priceCalculator.ancillaryRecords[i].ancillaryVatAmount ?? 0;
    }
  }
  if (
    priceCalculator.surchargeRecords &&
    priceCalculator.surchargeRecords.length > 0
  ) {
    for (let i = 0; i < priceCalculator.surchargeRecords.length; i++) {
      totalVat += priceCalculator.surchargeRecords[i].surchargeVatAmount ?? 0;
    }
  }
  return totalVat;
};

export const calculateSeaFreightVatAmount = (
  priceCalculator: FetchPriceCalculatorFragment
) => {
  return (
    priceCalculator.totalVat -
    calculateAdditionalRecordsVatAmount(priceCalculator)
  );
};

export const calculateSeaFreightIncludingVatAmount = (
  priceCalculator: FetchPriceCalculatorFragment
) => {
  return (
    priceCalculator.seaFreightPrice +
    calculateSeaFreightVatAmount(priceCalculator)
  );
};

export const calculateSeaFreightVatRate = (
  priceCalculator: FetchPriceCalculatorFragment
) => {
  // 1. Calculate VAT for SEA freight: Total VAT - SUM(all additional vat) = Sea freight VAT
  // 2. seaFreightPrice / Sea freight VAT = SeaFreight VAT rate
  const seaFreightVatAmount =
    priceCalculator.totalVat -
    calculateAdditionalRecordsVatAmount(priceCalculator);
  return (seaFreightVatAmount / priceCalculator.seaFreightPrice) * 100;
};
