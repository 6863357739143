import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { IPublicClientApplication } from "@azure/msal-browser";
import { createClient } from "graphql-ws";
import { apiRequest, loginSilentRequest } from "../config/auth/AuthConfig";
import { inMemoryCacheConfig } from "./InMemoryCacheConfig";

const acquireAccessToken = async (instance: IPublicClientApplication) => {
  const activeAccount = instance.getActiveAccount();
  const accounts = instance.getAllAccounts();

  const request = {
    scopes: loginSilentRequest.scopes,
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await instance.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch (error) {
    console.error("Could not acquireTokenSilent", error);
    try {
      const popupResult = await instance.loginPopup(apiRequest);
      // Attempt to get token again after successful popup login
      const authResult = await instance.acquireTokenSilent({
        ...request,
        account: popupResult.account,
      });
      return authResult.accessToken;
    } catch (popupError) {
      console.error("Failed to acquire token via popup", popupError);
      throw popupError;
    }
  }
};

const cache = new InMemoryCache(inMemoryCacheConfig);

export const createApolloClient = (
  instance: IPublicClientApplication
): ApolloClient<NormalizedCacheObject> => {
  const withToken = setContext(async (_, { headers }) => {
    try {
      await instance.initialize();
      const token = await acquireAccessToken(instance);
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    } catch (error) {
      console.error("Failed to set auth context", error);
      throw error; // Let Apollo handle the error
    }
  });

  const httpLink = createHttpLink({
    uri: (operation) =>
      import.meta.env.REACT_APP_GQL_ENDPOINT +
      "?operationName=" +
      operation.operationName,
  });

  const GQL_SUBSCRIPTION_ENDPOINT =
    (import.meta.env.PROD ? "wss://" + window.location.host : "ws://") +
    import.meta.env.REACT_APP_GQL_SUBSCRIPTION_ENDPOINT;

  const wsLink = new GraphQLWsLink(
    createClient({
      url: GQL_SUBSCRIPTION_ENDPOINT,
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    link: from([withToken, splitLink]),
    cache,
  });
};

export const postHttp = async (
  url: string,
  body: object,
  instance: IPublicClientApplication
) => {
  const token = await acquireAccessToken(instance);
  if (token === null) {
    console.error("Could not acquire token");
    return;
  }
  return await fetch(import.meta.env.REACT_APP_API_ENDPOINT + url, {
    headers: {
      authorization: `Bearer ${token}`,
      "content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const postHttpWithFormData = async (
  url: string,
  body: BodyInit,
  instance: IPublicClientApplication
) => {
  const token = await acquireAccessToken(instance);
  if (token === null) {
    console.error("Could not acquire token");
    return;
  }
  return await fetch(import.meta.env.REACT_APP_API_ENDPOINT + url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
    method: "POST",
    body,
  });
};

export const getHttp = async (
  url: string,
  instance: IPublicClientApplication
) => {
  const token = await acquireAccessToken(instance);
  if (token === null) {
    console.error("Could not acquire token");
    return;
  }
  return await fetch(import.meta.env.REACT_APP_API_ENDPOINT + url, {
    headers: {
      authorization: `Bearer ${token}`,
      "content-type": "application/json",
    },
  });
};
