import { GridContainer } from "@/common/components/grid-container/GridContainer";
import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { useLocalError } from "@/common/error/UseLocalError";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { Box, Column } from "@stenajs-webui/core";
import { Banner, Card, CardBody, FlatButton } from "@stenajs-webui/elements";
import { useContext, useEffect, useState } from "react";
import { bookingDataExist, customerDataExist } from "./helpers";
import { useBookingPriceCalculation } from "./hooks/UseBookingPriceCalulation";
import { useCustomerPriceCalculation } from "./hooks/UseCustomerPriceCalculation";
import { PriceCalculatorActionBar } from "./price-calculator-forms/customer/common/PriceCalculatorActionBar";
import { PriceCalculatorCustomerSearchResultPane } from "./price-calculator-forms/customer/common/PriceCalculatorCustomerSearchResultPane";
import { PriceCalculatorCustomerForm } from "./price-calculator-forms/customer/PriceCalculatorCustomerForm";
import { PriceCalculatorBookingForm } from "./price-calculator-result-panel/booking/PriceCalculatorBookingForm";
import { PriceCalculatorBookingResultPane } from "./price-calculator-result-panel/booking/PriceCalculatorBookingResultPane";
import { PriceCalculatorSearch } from "./price-calculator-search/PriceCalculatorSearch";
import {
  PriceCalculatorContext,
  PriceCalculatorContextType,
} from "./PriceCalculatorContext";
import { SearchEntityType } from "./types";

export const renderError = (
  localError: Error | undefined,
  clearLocalError: () => void
) => {
  return (
    <Column>
      <Banner
        variant={"error"}
        headerText={"Could not calculate price"}
        text={localError?.message}
        contentRight={
          <FlatButton label={"Clear error"} onClick={() => clearLocalError()} />
        }
      />
    </Column>
  );
};

export const PriceCalculatorFormFetcher: React.FC = () => {
  const [priceUpdatePending, setPriceUpdatePending] = useState(false);

  const { localError, setLocalError, clearLocalError } = useLocalError();
  const { formData, onChangeFormData, customerCalculatedState } = useContext(
    PriceCalculatorContext
  ) as PriceCalculatorContextType;

  const {
    fetchPriceCalculatorBooking,
    loading,
    error: calculateBookingError,
  } = useBookingPriceCalculation(onChangeFormData);

  const onSelectBookingVersion = async (
    bookingNum: number,
    consignmentVersion: number
  ) => {
    clearLocalError();
    await fetchPriceCalculatorBooking({
      variables: {
        bookingNum: String(bookingNum),
        consignmentVersion: consignmentVersion,
      },
    });
  };
  const {
    calculateCustomerPrice,
    loading: customerPriceCalculationLoading,
    error: customerSearchError,
  } = useCustomerPriceCalculation();

  useEffect(() => {
    (customerSearchError || calculateBookingError) &&
      setLocalError(customerSearchError || calculateBookingError);
  }, [customerSearchError, calculateBookingError, setLocalError]);

  useEffect(() => {
    const calculationStateHasChanged =
      JSON.stringify({ ...formData }) !==
      JSON.stringify({ ...customerCalculatedState });

    setPriceUpdatePending(calculationStateHasChanged);
  }, [formData, customerCalculatedState, setPriceUpdatePending]);

  const doesHaveCustomerData = customerDataExist({
    customerSearchResult: customerCalculatedState.customerResultData,
    searchEntity: customerCalculatedState.searchEntityType,
  });

  const doesHaveBookingData = bookingDataExist({
    bookingSearchResult: formData.bookingResultData,
    searchEntity: formData.searchEntityType,
  });

  return (
    <Box>
      <PageLayout>
        <GridContainer boxMinWidth={800} boxMaxWidth={"0.5fr"}>
          <Column height={"100%"}>
            <Card zIndex={ZIndex.none}>
              <CardBody>
                <PriceCalculatorSearch
                  onSelectBookingVersion={onSelectBookingVersion}
                />
                {formData.searchEntityType === SearchEntityType.CUSTOMER ? (
                  <PriceCalculatorCustomerForm />
                ) : (
                  <PriceCalculatorBookingForm
                    formData={formData}
                    loading={loading}
                    clearLocalError={clearLocalError}
                    localError={localError}
                  />
                )}
              </CardBody>
            </Card>
          </Column>
          <Box gap={3}>
            {formData.searchEntityType === SearchEntityType.CUSTOMER && (
              <PriceCalculatorActionBar
                formData={formData}
                loading={customerPriceCalculationLoading}
                onCalculatePrice={() => {
                  setPriceUpdatePending(false);
                  calculateCustomerPrice(formData);
                }}
                onChangeFormData={onChangeFormData}
              />
            )}

            {priceUpdatePending &&
              customerCalculatedState.customerResultData && (
                <Box>
                  <Banner
                    headerText={
                      "Customer and/or sailing details have been changed"
                    }
                    text={"You need to recalculate the price"}
                    variant={"info"}
                  />
                </Box>
              )}

            {localError &&
              formData.searchEntityType === SearchEntityType.CUSTOMER &&
              renderError(localError, clearLocalError)}

            {doesHaveBookingData && (
              <PriceCalculatorBookingResultPane
                bookingResult={formData.bookingResultData}
              />
            )}
            {doesHaveCustomerData && (
              <PriceCalculatorCustomerSearchResultPane />
            )}
          </Box>
        </GridContainer>
      </PageLayout>
    </Box>
  );
};
