import { FormModelChangeHandler } from "@/common/forms/types";
import {
  FeatureFlagsContext,
  FeatureFlagsContextType,
} from "@/config/featureFlags/FeatureFlagsContext";
import {
  PriceCalculatorBookingQuery,
  PriceCalculatorBookingQueryVariables,
} from "@/gql/graphql";
import { useLazyQuery } from "@apollo/client";
import { ApolloError, gql } from "apollo-boost";
import { useContext, useState } from "react";
import { MovementType, PriceCalculatorFormData } from "../types";
import { useMultiLegPriceCalculation } from "./UseMultiLegPriceCalculation";
import { usePriceCalculation } from "./UsePriceCalculation";
import { fetchBookingFragment } from "./fragments";
import {
  transformMultiLegBookingToPriceCalculatorInput,
  transformSectionalBookingToPriceCalculatorInput,
  transformQueryToBookingSearchResultData,
  transformQueryToMultiLegBookingSearchResultData,
} from "./transformers";

export const useBookingPriceCalculation = (
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>
) => {
  const [calculatePriceError, setCalculatePriceError] = useState<
    ApolloError | undefined
  >();
  const [calculateMultiLegPriceError, setCalculateMultiLegPriceError] =
    useState<ApolloError | undefined>();

  const { featureFlags } = useContext(
    FeatureFlagsContext
  ) as FeatureFlagsContextType;

  const usePricingV2 = featureFlags.pricing.usePricingV2;

  const { calculatePrice, loading: priceCalculationLoading } =
    usePriceCalculation();

  const { calculateMultiLegPrice, loading: multiLegCalculationLoading } =
    useMultiLegPriceCalculation();

  const queryPriceCalculatorBooking = gql`
    ${fetchBookingFragment}
    query PriceCalculatorBooking($bookingNum: ID!, $consignmentVersion: Int!) {
      priceCalculator {
        bookings {
          byId(id: $bookingNum, version: $consignmentVersion) {
            ...FetchBooking
          }
        }
      }
    }
  `;

  const [
    fetchPriceCalculatorBooking,
    { loading: bookingloading, error: fetchBookingError },
  ] = useLazyQuery<
    PriceCalculatorBookingQuery,
    PriceCalculatorBookingQueryVariables
  >(queryPriceCalculatorBooking, {
    onCompleted: async (data) => {
      const bookingList = data.priceCalculator.bookings.byId;
      const bookingData = data?.priceCalculator?.bookings?.byId[0];
      const routeLegs = data?.priceCalculator.bookings.byId.map(
        (routeLeg) => routeLeg.sailingBookingConsignment
      );

      if (bookingData) {
        const sectionalBookingTransformedData =
          transformSectionalBookingToPriceCalculatorInput(bookingData);
        const multiLegBookingTransformedData =
          transformMultiLegBookingToPriceCalculatorInput(
            bookingData,
            routeLegs
          );
        if (
          bookingData.movementType.code === MovementType.SECTIONAL.charAt(0)
        ) {
          const { data: priceCalculationData, error } = await calculatePrice({
            variables: {
              input: sectionalBookingTransformedData,
              usePricingV2,
            },
          });

          if (priceCalculationData) {
            return onChangeFormData(
              "bookingResultData",
              transformQueryToBookingSearchResultData(
                priceCalculationData,
                bookingList
              )
            );
          } else if (error) {
            setCalculatePriceError(error);
          }
        } else if (
          bookingData.movementType.code === MovementType.MULTILEG.charAt(0)
        ) {
          const { data: priceCalculationMultiLegData, error } =
            await calculateMultiLegPrice({
              variables: {
                input: multiLegBookingTransformedData,
                usePricingV2,
              },
            });
          if (priceCalculationMultiLegData) {
            return onChangeFormData(
              "bookingResultData",
              transformQueryToMultiLegBookingSearchResultData(
                priceCalculationMultiLegData,
                bookingList
              )
            );
          } else if (error) {
            setCalculateMultiLegPriceError(error);
          }
        }
      }
    },
  });

  return {
    fetchPriceCalculatorBooking,
    loading:
      bookingloading || priceCalculationLoading || multiLegCalculationLoading,
    error:
      calculateMultiLegPriceError || calculatePriceError || fetchBookingError,
  };
};
