import { profileActions } from "@/common/redux/profile/actions";
import { profileSelectors } from "@/common/redux/profile/selectors";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { environment } from "@/config/environment";
import { PublicClientApplication } from "@azure/msal-browser";
import { Column, Indent, Row, useBoolean } from "@stenajs-webui/core";
import {
  ActionMenuItem,
  stenaSignOut,
  stenaInfoCircle,
} from "@stenajs-webui/elements";
import {
  NavBarHeading,
  NavBarUserButton,
  NavBar as StenaNavBar,
} from "@stenajs-webui/panels";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SidebarMenu } from "../sidebar-menu/SidebarMenu";
import { ApplicationSearch } from "./ApplicationSearch";
import { EnvironmentWarning } from "./EnvironmentWarning";
import { useAllowedGlobalSearchRoutes } from "./custom-hooks/UseAllowedGlobalSearchRoutes";
import { AboutFreightPricingPortalModal } from "@/common/about-fpp/components/AboutFreightPricingPortalModal";
import {
  BuildVersionsContext,
  BuildVersionsContextType,
} from "@/config/buildVersions/BuildVersionsContext";
import { useContext, useState } from "react";

interface Props {
  msalInstance: PublicClientApplication;
}

export const NavBar: React.FC<Props> = ({ msalInstance }) => {
  const dispatch = useDispatch();
  const { globalSearchShouldBeVisible } = useAllowedGlobalSearchRoutes();
  const { fullName, userName, claims } = useSelector(
    profileSelectors.getGlobal
  );
  const [isOpen, open, close] = useBoolean(false);

  const buildVersionContext = useContext(
    BuildVersionsContext
  ) as BuildVersionsContextType;
  const [showAboutModal, setShowAboutModal] = useState(false);

  const handleOnCloseModal = () => {
    setShowAboutModal(false);
  };

  return (
    //FIXME: when we have a working table solution
    <>
      <Column
        left={0}
        position={"sticky"}
        width={"100vw"}
        zIndex={ZIndex.medium}
        data-testid={testIdConstants.navBar}
      >
        <SidebarMenu isOpen={isOpen} close={close} claims={claims} />
        <StenaNavBar
          showMenuButton
          onClickMenuButton={open}
          center={globalSearchShouldBeVisible && <ApplicationSearch />}
          left={
            <Row alignItems={"center"}>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <NavBarHeading>Freight Pricing Portal</NavBarHeading>
              </Link>
              <Indent />
              {environment.isEnvironmentDev() && <EnvironmentWarning />}
            </Row>
          }
          right={
            userName && (
              <NavBarUserButton
                data-testid={testIdConstants.userMenuButton}
                username={fullName}
                renderItems={() => (
                  <>
                    <ActionMenuItem
                      leftIcon={stenaInfoCircle}
                      label="About Freight Pricing Portal"
                      onClick={() => setShowAboutModal(true)}
                    />
                    <ActionMenuItem
                      leftIcon={stenaSignOut}
                      label={"Logout"}
                      data-testid={testIdConstants.logOutButton}
                      onClick={async () => {
                        localStorage.removeItem("AgreementTabSelected");
                        dispatch(profileActions.resetGlobalState());
                        await msalInstance.logoutRedirect({
                          onRedirectNavigate: (url) => {
                            // Return false if you would like to stop navigation after local logout
                            return false;
                          },
                        });
                      }}
                    />
                  </>
                )}
              />
            )
          }
        />
      </Column>
      <AboutFreightPricingPortalModal
        frontendVersion={buildVersionContext.buildVersions.frontend.version}
        backendVersion={buildVersionContext.buildVersions.backend.version}
        user={userName}
        visible={showAboutModal}
        onClose={handleOnCloseModal}
      />
    </>
  );
};
