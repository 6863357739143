import { SpaceValues } from "@/common/utils/SpaceValues";
import { Indent, Space } from "@stenajs-webui/core";
import { Banner, ValueTable, ValueTableItem } from "@stenajs-webui/elements";
import { LoadingPanel } from "@stenajs-webui/panels";
import * as React from "react";
import { renderError } from "../../PriceCalculatorFormFetcher";
import { PriceCalculatorFormData } from "../../types";
import { parseTimeString } from "../transformers";
import styles from "./PriceCalculatorBookingForm.module.css";
import { IndentValues } from "@/common/utils/IndentValues";

interface Props {
  formData: PriceCalculatorFormData;
  loading: boolean;
  localError: Error | undefined;
  clearLocalError: () => void;
}
export const PriceCalculatorBookingForm: React.FC<Props> = ({
  formData,
  loading,
  clearLocalError,
  localError,
}) => {
  const firstLeg = 1;
  const bookingData = formData?.bookingResultData?.bookingData[0];
  const bookingListData = formData?.bookingResultData?.bookingData;
  const bookingDepartureDate = bookingListData?.find(
    (booking) => booking.bookingLegNumber === firstLeg
  )?.sailingBookingConsignment.departureDate;
  const bookingDepartureTime = bookingListData?.find(
    (booking) => booking.bookingLegNumber === firstLeg
  )?.sailingBookingConsignment.departureTime;

  if (loading) {
    return (
      <>
        <Space num={SpaceValues.TWENTYFOUR} />
        <LoadingPanel />
      </>
    );
  }

  if (localError) {
    return (
      <>
        <Space num={SpaceValues.TWENTYFOUR} />
        {renderError(localError, clearLocalError)}
      </>
    );
  }

  if (!bookingData || !bookingListData) {
    return (
      <>
        <Space num={SpaceValues.TWENTYFOUR} />
        <Banner
          variant={"info"}
          headerText={"Price not calculated yet"}
          text={
            "Please enter a booking number and select a version to calculate a price for it"
          }
        />
      </>
    );
  }

  const ancillariesOrGoodsExist = () => {
    return (
      bookingData.goods.length > 0 ||
      bookingData.articles.some((ca) => {
        return ca.articleType.type === "ANC";
      })
    );
  };

  const goods = bookingData.goods;
  const ancillaries = bookingData.articles.filter(
    (ca) => ca.articleType.type === "ANC"
  );
  return (
    <Indent num={IndentValues.TWENTYFOUR} className={styles.bookingForm}>
      <Space />
      <ValueTable header={"Booking details"}>
        <ValueTableItem
          label={"Booking number"}
          value={
            bookingListData.length > 1
              ? bookingData.multiLegBookingNumber
              : bookingData?.bookingNumber
          }
        />
        <ValueTableItem
          label={"Booking status"}
          value={bookingData?.priceCalculatorStatus}
        />
        <ValueTableItem
          label={"Invoice number"}
          value={
            bookingData.bookingLegInvoice
              ? bookingData.bookingLegInvoice?.invoiceNo
              : "-"
          }
        />
        <ValueTableItem
          label={"Customer reference"}
          value={bookingData?.customerReference}
        />
        <ValueTableItem
          label={"Movement type"}
          value={bookingData?.movementType.description}
        />
      </ValueTable>

      <Space />
      <ValueTable header={"Sailing"}>
        <ValueTableItem
          label={"Route"}
          value={
            bookingListData.length > 1
              ? bookingData.multiLegRoute?.name
              : bookingData?.sailingBookingConsignment.route.name
          }
        />
        <ValueTableItem label={"Date"} value={bookingDepartureDate} />
        <ValueTableItem
          label={"Departure time"}
          value={parseTimeString(bookingDepartureTime ?? "")}
        />
      </ValueTable>

      <Space />
      <ValueTable header={"Vehicle"}>
        <ValueTableItem
          label={"Vehicle Type"}
          value={bookingData?.vehicleType.shortDescription}
        />
        <ValueTableItem
          label={"Vehicle reg"}
          value={bookingData?.vehicleRegistrationNumber}
        />
        <ValueTableItem
          label={"Trailer reg"}
          value={bookingData?.trailerRegistrationNumber}
        />
        <ValueTableItem label={"Vehicle length"} value={bookingData?.length} />
        <ValueTableItem label={"Vehicle width"} value={bookingData?.width} />
        <ValueTableItem label={"Vehicle weight"} value={bookingData?.weight} />
        <ValueTableItem
          label={"Loading preference"}
          value={bookingData.loadingPreferenceCode}
        />
      </ValueTable>

      <Space />
      <ValueTable header={"Drivers and passengers"}>
        <ValueTableItem label={"Adults"} value={bookingData?.numberOfAdults} />
        <ValueTableItem
          label={"Children"}
          value={bookingData?.numberOfChildren}
        />
        <ValueTableItem
          label={"Infants"}
          value={bookingData?.numberOfInfants}
        />
        <ValueTableItem
          label={"Number of sleepers"}
          value={bookingData?.numberOfSleepers}
        />
      </ValueTable>

      <Space />
      <ValueTable header={"Cargo Details"}>
        <ValueTableItem
          label={"Hazardous goods"}
          value={bookingData?.numberOfHazardousGoodsRows > 0 ? "YES" : "NO"}
        />
        <ValueTableItem
          label={"HG weight/line"}
          value={bookingData.hazardousGoodsRowWeight}
        />
        <ValueTableItem
          label={"Number of rows"}
          value={bookingData?.numberOfHazardousGoodsRows}
        />
        <ValueTableItem
          label={"Qualified goods"}
          value={bookingData?.qualifiedGoods ? "YES" : "NO"}
        />
        <ValueTableItem
          label={"Animals"}
          value={bookingData?.hasLivestock ? "YES" : "NO"}
        />
        <ValueTableItem
          label={"Animal"}
          value={bookingData?.livestockType?.description ?? ""}
        />
        <ValueTableItem
          label={"Customs classification code"}
          value={bookingData?.customsClassificationCode ?? ""}
        />
      </ValueTable>

      <Space />
      <ValueTable header={"Conditions"}>
        <ValueTableItem
          label={"Standby"}
          value={bookingData?.standby ? "YES" : "NO"}
        />
        <ValueTableItem
          label={"Transit"}
          value={bookingData?.transit ? "YES" : "NO"}
        />
        <ValueTableItem
          label={"Sender country"}
          value={bookingData.senderCountryCode ?? "-"}
        />
        <ValueTableItem
          label={"Receiver country"}
          value={bookingData.receiverCountryCode ?? "-"}
        />
      </ValueTable>
      <Space />
      {ancillariesOrGoodsExist() && (
        <ValueTable header={"Goods & Ancillaries"}>
          {goods.map((gd) => (
            <ValueTableItem
              key={gd.id}
              label={`${gd.description} ${gd.goodsCode}`}
              value={gd.weight}
            />
          ))}
          {ancillaries.map((anc) => (
            <ValueTableItem
              key={anc.id}
              label={`${anc.articleType.name} ${anc.articleCode}`}
              value={anc.quantity}
            />
          ))}
        </ValueTable>
      )}
    </Indent>
  );
};
