import { FetchPriceCalculatorFragment } from "@/gql/graphql";
import { PriceDetailTableRow } from "./config/PriceDetailTableRow";
import {
  calculateSeaFreightIncludingVatAmount,
  calculateSeaFreightVatAmount,
  calculateSeaFreightVatRate,
} from "./helpers";

export const transformSurchargeCosts = (
  query: FetchPriceCalculatorFragment["surchargeRecords"]["0"],
  priceCalc: FetchPriceCalculatorFragment,
  index: number
): PriceDetailTableRow => {
  return {
    id: index.toString(),
    name: query.articleType.name,
    code: query.articleType.code,
    vatRate: query.surchargeVatRate ?? 0,
    vatAmount: query.surchargeVatAmount ?? 0,
    priceExclVat: query.surchargePrice,
    currencyCode: priceCalc.baseCurrencyCode,
    priceInclVat: query.surchargePrice + (query.surchargeVatAmount ?? 0),
  };
};

export const transformAncillaryCosts = (
  query: FetchPriceCalculatorFragment["ancillaryRecords"]["0"],
  priceCalc: FetchPriceCalculatorFragment,
  index: number
): PriceDetailTableRow => {
  return {
    id: index.toString(),
    name: query.ancillaryCode,
    code: query.ancillaryCode,
    vatRate: query.ancillaryVatRate ?? 0,
    vatAmount: query.ancillaryVatAmount ?? 0,
    priceExclVat: query.ancillaryPrice,
    currencyCode: priceCalc.baseCurrencyCode,
    priceInclVat: query.ancillaryPrice + (query.ancillaryVatAmount ?? 0),
  };
};

export const transformPriceDetailTableRows = (
  priceCalculatorData: FetchPriceCalculatorFragment,
  priceDetailTableRows: PriceDetailTableRow[]
): PriceDetailTableRow[] => {
  priceDetailTableRows.sort((rowA, rowB) => {
    if (rowA.name < rowB.name) {
      return -1;
    }
    if (rowA.name > rowB.name) {
      return 1;
    }
    return 0;
  });
  return [
    {
      id: "SEAFREIGHT",
      name: "Sea freight",
      code: "SEA",
      priceInclVat: calculateSeaFreightIncludingVatAmount(priceCalculatorData),
      currencyCode: priceCalculatorData.baseCurrencyCode,
      vatRate: calculateSeaFreightVatRate(priceCalculatorData),
      vatAmount: calculateSeaFreightVatAmount(priceCalculatorData),
      priceExclVat: priceCalculatorData.seaFreightPrice,
    },
    ...priceDetailTableRows,
    {
      currencyCode: priceCalculatorData.baseCurrencyCode,
      vatAmount: priceCalculatorData.totalVat,
      priceInclVat:
        priceCalculatorData.totalPrice + priceCalculatorData.totalVat,
      priceExclVat: priceCalculatorData.totalPrice,
      code: "",
      vatRate: 0,
      id: "FOOTER",
      name: "Total",
    },
  ];
};

export const transformQueryToPriceDetailTableRow = (
  priceCalcData: FetchPriceCalculatorFragment
) => {
  return [
    ...priceCalcData.ancillaryRecords.map<PriceDetailTableRow>(
      (record, index) => transformAncillaryCosts(record, priceCalcData, index)
    ),
    ...priceCalcData.surchargeRecords.map<PriceDetailTableRow>(
      (record, index) =>
        transformSurchargeCosts(
          record,
          priceCalcData,
          index + priceCalcData.ancillaryRecords.length
        )
    ),
  ];
};

export const parseTimeString = (value: string) => {
  return value.slice(0, 2) + ":" + value.slice(2);
};
