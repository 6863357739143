import * as React from "react";
import { Helmet } from "react-helmet";
import { PriceCalculatorHeader } from "./PriceCalculatorHeader";
import { PriceCalculatorFormFetcher } from "./PriceCalculatorFormFetcher";
import { PriceCalculatorProvider } from "./PriceCalculatorContext";

interface Props {}

export const PriceCalculatorScreenV2: React.FC<Props> = () => {
  return (
    <>
      <Helmet title="Price Calculator - FPP" />
      <PriceCalculatorHeader />
      <PriceCalculatorProvider>
        <PriceCalculatorFormFetcher />
      </PriceCalculatorProvider>
    </>
  );
};
