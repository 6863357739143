import { TextInput } from "@stenajs-webui/forms";
import * as React from "react";
import { ChangeEvent, useEffect, useRef, useState } from "react";

interface Props {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const MASK = "--:--";
type Field = "hours" | "minutes";

const isValidHourInput = (value: string): boolean => {
  if (value.length === 1) {
    return ["0", "1", "2"].includes(value);
  }
  const hours = parseInt(value);
  return hours >= 0 && hours <= 23;
};

const isValidMinuteInput = (value: string): boolean => {
  if (value.length === 1) {
    return parseInt(value) >= 0 && parseInt(value) <= 5;
  }
  const minutes = parseInt(value);
  return minutes >= 0 && minutes <= 59;
};

const extractDigits = (value: string): string => {
  return value.replace(/[^0-9]/g, "");
};

export const PriceCalculatorDepartureTimeInput: React.FC<Props> = ({
  value,
  onChange,
  disabled,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentField, setCurrentField] = useState<Field | null>(null);
  const [internalValue, setInternalValue] = useState(value || MASK);

  useEffect(() => {
    setInternalValue(value || MASK);
  }, [value]);

  let newTime = internalValue;

  const updateSelection = (field: Field) => {
    const start = field === "hours" ? 0 : 3;
    const end = field === "hours" ? 2 : 5;
    requestAnimationFrame(() => {
      inputRef.current?.setSelectionRange(start, end);
      inputRef.current?.focus();
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!currentField) return;

    const relevantPart =
      currentField === "hours"
        ? e.target.value.substring(0, 2) // Only look at hours portion
        : e.target.value.substring(3, 5); // Only look at minutes portion

    const newDigit = extractDigits(relevantPart).slice(-1);

    if (!newDigit) {
      updateSelection(currentField);
      return;
    }

    const currentValue =
      currentField === "hours"
        ? extractDigits(internalValue.slice(0, 2))
        : extractDigits(internalValue.slice(3, 5));

    if (currentField === "hours") {
      const newValue =
        currentValue.length === 2 ? newDigit : currentValue + newDigit;

      if (!isValidHourInput(newValue)) {
        updateSelection("hours");
        return;
      }
      newTime = newValue.padEnd(2, "-") + ":" + internalValue.slice(3);

      if (newValue.length === 2 && currentValue.length !== 2) {
        setCurrentField("minutes");
        updateSelection("minutes");
      } else {
        updateSelection("hours");
      }
    } else {
      const newValue =
        currentValue.length === 2 ? newDigit : currentValue + newDigit;

      if (!isValidMinuteInput(newValue)) {
        updateSelection("minutes");
        return;
      }
      newTime = internalValue.slice(0, 3) + newValue.padEnd(2, "-");
      updateSelection("minutes");
    }

    setInternalValue(newTime);
    onChange(newTime);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!currentField) return;

    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();
      let newTime = internalValue;

      if (currentField === "hours") {
        // Only clear hours field
        newTime = "--" + internalValue.slice(2);
        updateSelection("hours");
      } else {
        // Only clear minutes field
        newTime = internalValue.slice(0, 3) + "--";
        updateSelection("minutes");
      }

      setInternalValue(newTime);
      onChange(newTime);
    } else if (e.key === "ArrowLeft" && currentField === "minutes") {
      e.preventDefault();
      setCurrentField("hours");
      updateSelection("hours");
    } else if (e.key === "ArrowRight" && currentField === "hours") {
      e.preventDefault();
      setCurrentField("minutes");
      updateSelection("minutes");
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    const position = e.currentTarget.selectionStart || 0;
    const targetField: Field = position < 3 ? "hours" : "minutes";
    setCurrentField(targetField);
    updateSelection(targetField);
  };

  return (
    <TextInput
      inputRef={inputRef}
      value={internalValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      disabled={disabled}
      maxLength={5}
      style={{ userSelect: "none", caretColor: "transparent" }}
    />
  );
};
