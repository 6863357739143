import { FormModelChangeHandler } from "@/common/forms/types";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { Box, Row, Space } from "@stenajs-webui/core";
import { Card, CardBody, PrimaryButton } from "@stenajs-webui/elements";
import { CheckboxWithLabel } from "@stenajs-webui/forms";
import * as React from "react";
import { isCalculationDisabled } from "../utils/PriceCalculatorActionBarHelper";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  onCalculatePrice: () => void;
  loading: boolean;
}

export const PriceCalculatorActionBar: React.FC<Props> = ({
  formData,
  onChangeFormData,
  onCalculatePrice,
  loading,
}) => {
  return (
    <Box>
      <Card>
        <CardBody>
          <Row alignItems={"center"}>
            <CheckboxWithLabel
              label={"Include pending/offered agreements"}
              value={formData.includePendingOffered}
              onValueChange={(value) =>
                onChangeFormData("includePendingOffered", value)
              }
            />
            <Space num={SpaceValues.SIXTEEN} />
            <PrimaryButton
              label={"Calculate price"}
              loading={loading}
              loadingLabel={"Calculating price"}
              onClick={() => onCalculatePrice()}
              disabled={isCalculationDisabled(formData)}
              data-testid={testIdConstants.priceCalculatorCalculatePriceButton}
            />
          </Row>
        </CardBody>
      </Card>
    </Box>
  );
};
