import {
  PriceCalculatorResultV2Query,
  PriceCalculatorResultV2QueryVariables,
} from "@/gql/graphql";
import { gql, useLazyQuery } from "@apollo/client";
import { fetchPriceCalculatorFragment } from "./fragments";
import { FormModelChangeHandler } from "@/common/forms/types";
import { PriceCalculatorFormData } from "../types";
import { transformToCustomerSectionalResultData } from "./transformers";

export const usePriceCalculation = (
  formData?: PriceCalculatorFormData,
  onChangeFormData?: FormModelChangeHandler<PriceCalculatorFormData>,
  setCustomerCalculatedState?: React.Dispatch<
    React.SetStateAction<PriceCalculatorFormData>
  >
) => {
  //FIXME: RENAME LATEER
  const query = gql`
    ${fetchPriceCalculatorFragment}
    query PriceCalculatorResultV2(
      $input: PriceCalculatorInput!
      $usePricingV2: Boolean!
    ) {
      priceCalculator {
        byInput(variables: $input, usePricingV2: $usePricingV2) {
          __typename
          ... on PriceCalculatorError {
            error
            errorType
          }
          ...FetchPriceCalculator
        }
      }
    }
  `;
  const [calculatePrice, { loading, data, error }] = useLazyQuery<
    PriceCalculatorResultV2Query,
    PriceCalculatorResultV2QueryVariables
  >(query, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (onChangeFormData && formData && setCustomerCalculatedState) {
        setCustomerCalculatedState({
          ...formData,
          customerResultData: transformToCustomerSectionalResultData(
            data,
            formData
          ),
        });
        return onChangeFormData(
          "customerResultData",
          transformToCustomerSectionalResultData(data, formData)
        );
      }
    },
  });

  return {
    calculatePrice,
    loading,
    data,
    error,
  };
};
