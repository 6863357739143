import { GapValues } from "@/common/utils/GapValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Space } from "@stenajs-webui/core";
import * as React from "react";
import { PriceCalculatorGoodsAndAncillaries } from "../common/PriceCalculatorGoodsAndAncillaries";
import { PriceCalculatorMoreOptionsCollapsible } from "../common/PriceCalculatorMoreOptionsCollapsible";
import { PriceCalculatorVehicleDetails } from "../common/PriceCalculatorVehicleDetails";
import { PriceCalculatorDriversDetails } from "./PriceCalculatorDriversDetails";
import { PriceCalculatorSailingDetails } from "./PriceCalculatorSailingDetails";
import { useContext } from "react";
import {
  PriceCalculatorContext,
  PriceCalculatorContextType,
} from "@/features/price-calculator-v2/PriceCalculatorContext";

export const PriceCalculatorSectionalOptions: React.FC = () => {
  const {
    formData,
    onChangeFormData,
    setState,
    setDefaultFormState,
    defaultFormState,
  } = useContext(PriceCalculatorContext) as PriceCalculatorContextType;
  return (
    <>
      <Space num={SpaceValues.TWENTYFOUR} />
      <Box gap={GapValues.TWENTYFOUR}>
        <PriceCalculatorSailingDetails
          formData={formData}
          onChangeFormData={onChangeFormData}
          setState={setState}
          defaultFormState={defaultFormState}
        />
        <PriceCalculatorVehicleDetails
          formData={formData}
          onChangeFormData={onChangeFormData}
          setDefaultFormState={setDefaultFormState}
        />
        <PriceCalculatorDriversDetails
          formData={formData}
          onChangeFormData={onChangeFormData}
        />
        <PriceCalculatorGoodsAndAncillaries
          formData={formData}
          onChangeFormData={onChangeFormData}
        />
        <PriceCalculatorMoreOptionsCollapsible
          formData={formData}
          onChangeFormData={onChangeFormData}
          defaultFormState={defaultFormState}
        />
      </Box>
    </>
  );
};
