import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { usePriceDetailsRoute } from "@/features/price-calculator-v2/hooks/UseRoute";
import { PriceCalculatorAuditTab } from "@/features/price-calculator-v2/price-calculator-result-panel/audit-tab/PriceCalculatorAuditTab";
import { transformCustomerResultToAuditData } from "@/features/price-calculator-v2/price-calculator-result-panel/audit-tab/transformers";
import { PriceCalculatorMultiLegResultTab } from "@/features/price-calculator-v2/price-calculator-result-panel/customer/PriceCalculatorMultiLegResultTab";
import { PriceCalculatorSectionalResultTab } from "@/features/price-calculator-v2/price-calculator-result-panel/customer/PriceCalculatorSectionalResultTab";
import {
  PriceCalculatorContext,
  PriceCalculatorContextType,
} from "@/features/price-calculator-v2/PriceCalculatorContext";
import {
  MovementType,
  SelectedTab,
} from "@/features/price-calculator-v2/types";
import { Box } from "@stenajs-webui/core";
import { Banner, Card, CardBody, Tab, TabMenu } from "@stenajs-webui/elements";
import React, { useContext, useState } from "react";

export const PriceCalculatorCustomerSearchResultPane: React.FC = () => {
  const { customerCalculatedState } = useContext(
    PriceCalculatorContext
  ) as PriceCalculatorContextType;

  const { error: routeError, data: routeData } = usePriceDetailsRoute(
    customerCalculatedState.routeCode
  );
  const routeName = routeData?.productPrice.route.byId?.name;

  const priceTabDisabled =
    customerCalculatedState.customerResultData?.priceCalculatorData.some(
      (data) => data.totalPrice === 0
    );

  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    priceTabDisabled ? SelectedTab.AUDIT : SelectedTab.PRICE
  );

  const displaySectionalDetailsTab =
    selectedTab === SelectedTab.PRICE &&
    customerCalculatedState.movementType === MovementType.SECTIONAL;

  const displayMultiLegDetailsTab =
    selectedTab === SelectedTab.PRICE &&
    customerCalculatedState.movementType === MovementType.MULTILEG;

  return (
    <Card>
      <CardBody>
        <TabMenu>
          <Tab
            disabled={priceTabDisabled}
            label={"Price details"}
            selected={selectedTab === SelectedTab.PRICE}
            onClick={() => setSelectedTab(SelectedTab.PRICE)}
            data-testid={testIdConstants.PriceCalculatorPriceDetailsButton}
          />
          <Tab
            label={"Audit track"}
            selected={selectedTab === SelectedTab.AUDIT}
            onClick={() => setSelectedTab(SelectedTab.AUDIT)}
            data-testid={testIdConstants.PriceCalculatorAuditTrackButton}
          />
        </TabMenu>
        {routeError && (
          <Box spacing={SpacingValues.TWENTY}>
            <Banner
              headerText={"Could not load route"}
              text={"Tried to load error, but there was a problem"}
              variant={"error"}
            />
          </Box>
        )}
      </CardBody>
      {customerCalculatedState.customerResultData?.priceCalculatorData &&
        routeName && (
          <>
            {displaySectionalDetailsTab && (
              <PriceCalculatorSectionalResultTab
                customerCalculatedState={customerCalculatedState}
                routeName={routeName}
              />
            )}
            {displayMultiLegDetailsTab && (
              <PriceCalculatorMultiLegResultTab
                customerCalculatedState={customerCalculatedState}
              />
            )}
            {selectedTab === SelectedTab.AUDIT &&
              customerCalculatedState.customerResultData && (
                <PriceCalculatorAuditTab
                  auditData={customerCalculatedState.customerResultData.priceCalculatorData.map(
                    (priceCalc) =>
                      transformCustomerResultToAuditData(
                        priceCalc,
                        customerCalculatedState
                      )
                  )}
                />
              )}
          </>
        )}
    </Card>
  );
};
