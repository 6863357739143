import {
  HeadAgreementForMultilegFragment,
  RouteAgreementsForMultilegRouteFragment,
} from "@/gql/graphql";
import { Column } from "@stenajs-webui/core";
import * as React from "react";
import { PageLayout } from "../../../../common/components/page-layout/PageLayout";
import { useCustomer } from "../../customers/common/customer-head-agreement-header/UseCustomer";
import { MultiLegViewInfoPairBoxes } from "../../info-boxes/MultiLegViewInfoPairBoxes";
import { BulkUpdateManyBox } from "../../rate-sheet/bulk-update-rates/components/BulkUpdateManyBox";
import { CustomerMultilegRouteHeader } from "./CustomerMultilegRouteHeader";
import { CustomerMultilegRouteLegList } from "./CustomerMultilegRouteLegList";
import { useMemo, useState } from "react";
import { routeAgreementCanBeBulkUpdated } from "../../rate-sheet/bulk-update-rates/validators/RouteAgreementBulkUpdateValidator";

interface Props {
  routePairId: string;
  customerId: string;
  headAgreement: HeadAgreementForMultilegFragment;
  multiLegAgreement: RouteAgreementsForMultilegRouteFragment;
  routeAgreementYear: number;
}

export const CustomerMultilegRoute: React.FC<Props> = ({
  routePairId,
  headAgreement,
  customerId,
  multiLegAgreement,
  routeAgreementYear,
}) => {
  const { loading: loadingCustomer, customer } = useCustomer(
    customerId,
    routeAgreementYear
  );

  const [bulkUpdateModeEnabled, setBulkUpdateModeEnabled] = useState(false);

  const findMultiLegAgreement = headAgreement?.multiLegAgrRoutes.find(
    (mlar) => mlar.id === multiLegAgreement.id
  );
  const routeAgreements = multiLegAgreement.routeAgreements;

  const numRouteAgreementsCanBeBulkUpdated = useMemo(
    () => routeAgreements.filter(routeAgreementCanBeBulkUpdated).length,
    [routeAgreements]
  );

  return (
    <Column>
      {customer && (
        <CustomerMultilegRouteHeader
          customer={customer}
          routePairId={routePairId}
          headAgreement={headAgreement}
          loadingCustomer={loadingCustomer}
          multilegAgreementForRoutePair={multiLegAgreement}
        />
      )}
      <PageLayout>
        {customer && (
          <MultiLegViewInfoPairBoxes
            customer={customer}
            headAgreementId={headAgreement.id}
            multiLegAgreement={findMultiLegAgreement}
            switchButtonDisabled={true}
          />
        )}

        <BulkUpdateManyBox
          totalNumRouteAgreements={routeAgreements.length}
          updateStatBoxes={false}
          setBulkUpdateModeEnabled={setBulkUpdateModeEnabled}
          bulkUpdateModeEnabled={bulkUpdateModeEnabled}
          numRouteAgreementsCanBeBulkUpdated={
            numRouteAgreementsCanBeBulkUpdated
          }
        />

        <CustomerMultilegRouteLegList
          multiLegAgreement={multiLegAgreement}
          headAgreement={headAgreement}
          bulkUpdateModeEnabled={bulkUpdateModeEnabled}
        />
      </PageLayout>
    </Column>
  );
};
