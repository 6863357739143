import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Row, Space, Text, useBoolean } from "@stenajs-webui/core";
import {
  Banner,
  Card,
  CardHeader,
  FlatButton,
  PrimaryButton,
  stenaTrash,
} from "@stenajs-webui/elements";
import { SelectedItemsActionsPanel } from "@stenajs-webui/panels";
import * as React from "react";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { rateSheetTableActions } from "../../actions";
import {
  rateSheetStandardTableSelectors,
  rateSheetStandardTableWrapperActions,
} from "../../redux";
import { useAfterBulkUpdateBySelectionLogic } from "../hooks/UseAfterBulkUpdateBySelectionLogic";
import {
  BulkUpdateRatesFormModel,
  RaiseType,
  emptyBulkUpdateRatesFormModel,
} from "../models/BulkUpdateRatesFormModel";
import { raisePricesUsingSelectionThunk } from "../thunks/RaisePricesUsingSelectionThunk";
import { BulkUpdateManyForm } from "./BulkUpdateManyForm";
import { QuickSelectionMenu } from "./QuickSelectionMenu";

interface Props {
  bulkUpdateModeEnabled: boolean;
  setBulkUpdateModeEnabled: (bulkUpdateModeEnabled: boolean) => void;
  totalNumRouteAgreements: number;
  numRouteAgreementsCanBeBulkUpdated: number;
  currencyLabel?: string;
  updateStatBoxes?: boolean;
}

export const BulkUpdateManyBox: React.FC<Props> = ({
  bulkUpdateModeEnabled,
  setBulkUpdateModeEnabled,
  numRouteAgreementsCanBeBulkUpdated,
  currencyLabel,
  updateStatBoxes = true,
}) => {
  const dispatch = useDispatch();
  const [visible, show, hide] = useBoolean(false);
  const { afterBulkUpdateBySelection } =
    useAfterBulkUpdateBySelectionLogic(updateStatBoxes);
  const [loading, setLoading, setNotLoading] = useBoolean(false);

  const [formModel, setFormModel] = useState<BulkUpdateRatesFormModel>(
    emptyBulkUpdateRatesFormModel
  );
  const someSelected = useSelector(
    rateSheetStandardTableSelectors.somePriceRowsAreSelected
  );

  const numSelected = useSelector(
    rateSheetStandardTableSelectors.numPriceRowsSelected
  );

  const selectedRows = useSelector(rateSheetStandardTableSelectors.getState);
  const selectedRowKeys = Object.keys(selectedRows);
  const selectedRouteAgreementPriceIds = selectedRowKeys.flatMap(
    (srk) => selectedRows[srk].selectedIds.selectedIds
  );

  const routeAgreementPricesSelector = useCallback(
    (state: StoreState) => {
      return Object.values(state.rateSheet.routeAgreements).flatMap((ra) => {
        return ra?.prices.filter((p) =>
          selectedRouteAgreementPriceIds.includes(p.id)
        );
      });
    },
    [selectedRouteAgreementPriceIds]
  );

  const selectedRouteAgreementPriceRowsSelector = useCallback(
    (state: StoreState) => {
      return selectedRouteAgreementPriceIds.flatMap((selectedPrice) => {
        return Object.values(state.rateSheet.routeAgreements).filter((ra) =>
          ra?.prices.find((p) => p.id === selectedPrice)
        );
      });
    },
    [selectedRouteAgreementPriceIds]
  );

  const selectedRouteAgreementPriceRows = useSelector(
    selectedRouteAgreementPriceRowsSelector
  );

  const selectedRouteAgreementPrices = useSelector(
    routeAgreementPricesSelector
  );

  const onClickClose = () => {
    setBulkUpdateModeEnabled(false);
    dispatch(rateSheetStandardTableWrapperActions.clearTableState());
  };

  const onClickOpen = () => {
    dispatch(rateSheetStandardTableWrapperActions.clearTableState());
    setBulkUpdateModeEnabled(true);
  };

  const onClickDelete = useCallback(() => {
    return selectedRouteAgreementPriceRows.map((routeAgreement) =>
      selectedRouteAgreementPriceIds.map((selectedPrice) => {
        return dispatch(
          rateSheetStandardTableWrapperActions.tableRows.recordAction(
            routeAgreement?.id ?? "",
            rateSheetTableActions.markPriceAsDeleted(selectedPrice)
          )
        );
      })
    );
  }, [
    dispatch,
    selectedRouteAgreementPriceRows,
    selectedRouteAgreementPriceIds,
  ]);

  const onClickRaise = async () => {
    if (
      selectedRouteAgreementPrices.some(
        (price) => Number(price?.meterPrice.amount ?? 0) < 1
      ) &&
      formModel.raiseType === RaiseType.BY_METER_PRICE
    ) {
      show();
      return;
    }

    setLoading();
    await dispatch(raisePricesUsingSelectionThunk(formModel));
    await afterBulkUpdateBySelection();
    setNotLoading();
  };

  const onYesPromptSelectedRaise = async () => {
    setLoading();
    await dispatch(raisePricesUsingSelectionThunk(formModel));
    await afterBulkUpdateBySelection();
    setNotLoading();
    hide();
  };

  const onClickClear = () => {
    dispatch(rateSheetStandardTableWrapperActions.clearAllCheckboxes());
  };

  const canRaise = !!formModel.raiseBy && someSelected;

  return (
    <Card>
      <CardHeader
        text={"Bulk update"}
        contentRight={
          <Row alignItems={"center"}>
            {bulkUpdateModeEnabled ? (
              <FlatButton
                label={"Close"}
                onClick={onClickClose}
                disabled={loading}
              />
            ) : (
              <FlatButton
                label={"Select vehicles"}
                onClick={onClickOpen}
                disabled={
                  !getStartButtonEnabled(numRouteAgreementsCanBeBulkUpdated)
                }
              />
            )}
          </Row>
        }
      />
      {bulkUpdateModeEnabled && (
        <>
          {" "}
          <SelectedItemsActionsPanel
            label={
              <Row alignItems={"center"}>
                <QuickSelectionMenu />
                <Space num={SpaceValues.SIXTEEN} />
                <Text>
                  <Text variant={"bold"}>{numSelected + " "}</Text>
                  items selected
                </Text>
              </Row>
            }
            afterLabelContent={
              <Row>
                <FlatButton
                  label={"Clear all"}
                  onClick={onClickClear}
                  disabled={loading}
                />
                <FlatButton
                  label={"Delete selected items"}
                  onClick={onClickDelete}
                  leftIcon={stenaTrash}
                  disabled={loading}
                />
              </Row>
            }
            rightContent={
              <Row alignItems={"center"}>
                <Text variant={"bold"}>Raise rate by</Text>
                <Space />
                <BulkUpdateManyForm
                  value={formModel}
                  onValueChange={setFormModel}
                  currencyLabel={currencyLabel}
                />
                <Space />
                <PrimaryButton
                  label={"Apply"}
                  loading={loading}
                  onClick={onClickRaise}
                  disabled={!canRaise}
                />
              </Row>
            }
          />
          {visible && (
            <Box width={"100%"}>
              <Banner
                headerText="You have selected a row where the meter price is 0."
                text={"Are you sure you want to continue?"}
                variant="warning"
                contentRight={
                  <Box display="flex" flexDirection="row">
                    <FlatButton label="No" onClick={hide} />
                    <Space num={2} />
                    <PrimaryButton
                      label="Yes"
                      onClick={onYesPromptSelectedRaise}
                    />
                  </Box>
                }
              />
            </Box>
          )}
        </>
      )}
    </Card>
  );
};
const getStartButtonEnabled = (
  numRouteAgreementsCanBeBulkUpdated: number
): boolean => numRouteAgreementsCanBeBulkUpdated !== 0;
