import { CustomsClassificationSelect } from "@/common/components/data-driven-inputs/selects/customs-classification/CustomsClassificationSelect";
import { NumericAgrVehicleWeightTextInput } from "@/common/components/numeric-agr-vehicle-weight-text-input/NumericAgrVehicleWeightTextInput";
import { FormModelChangeHandler } from "@/common/forms/types";
import { GapValues } from "@/common/utils/GapValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import { CheckboxWithLabel, Switch } from "@stenajs-webui/forms";
import * as React from "react";
import { LiveStockSelect } from "../../../../../common/components/data-driven-inputs/selects/live-stock-select/LiveStockSelect";
import { NumericTextInputNoScroll } from "../../../../../common/components/forms/NumericTextInputNoScroll";
import { FormLabelWithChildren } from "../PriceCalculatorCustomerForm";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  defaultFormState: PriceCalculatorFormData;
}

export const PriceCalculatorCargoGoodsDetails: React.FC<Props> = ({
  formData,
  onChangeFormData,
  defaultFormState,
}) => {
  return (
    <Box>
      <Heading variant={"h4"}>Cargo details</Heading>
      <Space num={SpaceValues.SIXTEEN} />
      <Row gap={GapValues.TWENTYFOUR}>
        <FormLabelWithChildren label={"HG = Class 1 or 7"} width={100}>
          <Box height={38} justifyContent={"center"}>
            <Switch
              value={formData.cargoHazardousGoods}
              onValueChange={(cargoHazGoods) => {
                onChangeFormData("cargoHazardousGoods", cargoHazGoods);
                if (!cargoHazGoods) {
                  onChangeFormData(
                    "cargoHazardousGoodsRowWeight",
                    defaultFormState.cargoHazardousGoodsRowWeight
                  );
                  onChangeFormData(
                    "cargoHazardousGoodsAmountRows",
                    defaultFormState.cargoHazardousGoodsAmountRows
                  );
                  onChangeFormData(
                    "cargoHazardousGoodsQualified",
                    defaultFormState.cargoHazardousGoodsQualified
                  );
                }
              }}
            />
          </Box>
        </FormLabelWithChildren>
        <FormLabelWithChildren label={"Weight/Line"} width={80}>
          <NumericAgrVehicleWeightTextInput
            value={formData.cargoHazardousGoodsRowWeight}
            onValueChange={(cargoHazWeight) =>
              onChangeFormData("cargoHazardousGoodsRowWeight", cargoHazWeight)
            }
            disabled={!formData.cargoHazardousGoods}
          />
        </FormLabelWithChildren>
        <FormLabelWithChildren label={"No of rows"} width={80}>
          <NumericTextInputNoScroll
            value={formData.cargoHazardousGoodsAmountRows?.toString()}
            onValueChange={(cargoHazRows) =>
              onChangeFormData(
                "cargoHazardousGoodsAmountRows",
                parseInt(cargoHazRows)
              )
            }
            min={0}
          />
        </FormLabelWithChildren>
        <Box justifyContent={"flex-end"} spacing={SpacingValues.EIGHT}>
          <CheckboxWithLabel
            label={"Qualified goods"}
            size={"standard"}
            value={formData.cargoHazardousGoodsQualified}
            onValueChange={(cargoHazQualified) =>
              onChangeFormData(
                "cargoHazardousGoodsQualified",
                cargoHazQualified
              )
            }
            disabled={!formData.cargoHazardousGoods}
          />
        </Box>
      </Row>
      <Space num={SpaceValues.TWENTYFOUR} />
      <Row gap={GapValues.TWENTYFOUR}>
        <FormLabelWithChildren label={"Animals"} width={80}>
          <Box height={38} justifyContent={"center"}>
            <Switch
              value={formData.livestock}
              onValueChange={(livestock) => {
                onChangeFormData("livestock", livestock);
                if (!livestock) {
                  onChangeFormData(
                    "livestockType",
                    defaultFormState.livestockType
                  );
                }
              }}
            />
          </Box>
        </FormLabelWithChildren>
        <FormLabelWithChildren label={"Animal type"} width={145}>
          <LiveStockSelect
            value={formData.livestockType}
            onValueChange={(value) => onChangeFormData("livestockType", value)}
            menuPortalTarget={document.body}
            isDisabled={!formData.livestock}
          />
        </FormLabelWithChildren>
      </Row>
      <Space num={SpaceValues.TWENTYFOUR} />
      <FormLabelWithChildren label={"Customs classification"} width={200}>
        <CustomsClassificationSelect
          value={formData.customsClassificationCode}
          onValueChange={(value) => {
            onChangeFormData("customsClassificationCode", value);
          }}
        />
      </FormLabelWithChildren>
    </Box>
  );
};
