import { InMemoryCacheConfig } from "@apollo/client";

export const inMemoryCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    ProductPriceSearchRoot: {
      merge: true,
    },
    ActualPriceChangeSearchRoot: {
      merge: true,
    },
    ArticleTypeSearchRoot: {
      merge: true,
    },
    CountrySearchRoot: {
      merge: true,
    },
    CurrencySearchRoot: {
      merge: true,
    },
    CustomerSearchRoot: {
      merge: true,
    },
    CustomerCategorySearchRoot: {
      merge: true,
    },
    CustomerRouteStatisticsSearchRoot: {
      merge: true,
    },
    FormulaSearchRoot: {
      merge: true,
    },
    GoodsSearchRoot: {
      merge: true,
    },
    HeadAgreementSearchRoot: {
      merge: true,
    },
    LiveStockSearchRoot: {
      merge: true,
    },
    LoadingPreferenceSearchRoot: {
      merge: true,
    },
    MatrixSearchRoot: {
      merge: true,
    },
    MovementTypeSearchRoot: {
      merge: true,
    },
    MultiLegRouteAgreementSearchRoot: {
      merge: true,
    },
    NegotiationStatusSearchRoot: {
      merge: true,
    },
    NegotiationYearSearchRoot: {
      merge: true,
    },
    RouteSearchRoot: {
      merge: true,
    },
    RouteAgreementSearchRoot: {
      merge: true,
    },
    RouteAgreementPriceSearchRoot: {
      merge: true,
    },
    RoutePairSearchRoot: {
      merge: true,
    },
    RoutePairNegotiationYearSearchRoot: {
      merge: true,
    },
    SailingArchivesSearchRoot: {
      merge: true,
    },
    SailingCategorySearchRoot: {
      merge: true,
    },
    SailingsSearchRoot: {
      merge: true,
    },
    SalesRegionSearchRoot: {
      merge: true,
    },
    SeaAreaSearchRoot: {
      merge: true,
    },
    TargetAdminSearchRoot: {
      merge: true,
    },
    TargetNegotiationStatusSearchRoot: {
      merge: true,
    },
    TargetOverviewSearchRoot: {
      merge: true,
    },
    TargetRoutePairSearchRoot: {
      merge: true,
    },
    TargetSummarySearchRoot: {
      merge: true,
    },
    UnitTypeSearchRoot: {
      merge: true,
    },
    UserSearchRoot: {
      merge: true,
    },
    UserProfileSearchRoot: {
      merge: true,
    },
    VehicleTypeSearchRoot: {
      merge: true,
    },
    // Other roots
    StatisticsSearchRoot: {
      merge: true,
    },
    StatisticsRoutePairListSearchRoot: {
      merge: true,
    },
    StatisticsRoutePairDetailsSearchRoot: {
      merge: true,
    },
    StatisticsSalesRegionDetailsSearchRoot: {
      merge: true,
    },
    StatisticsSalesRegionListSearchRoot: {
      merge: true,
    },
    StatisticsSellerDetailsSearchRoot: {
      merge: true,
    },
    StatisticsSellerListSearchRoot: {
      merge: true,
    },
    StatisticsTotalDetailsSearchRoot: {
      merge: true,
    },
    // ProductPriceMutationRoot
    ProductPriceMutationRoot: {
      merge: true,
    },
    AdminTargetMutationRoot: {
      merge: true,
    },
    CustomerMutationRoot: {
      merge: true,
    },
    ExpectedVolumeMutationRoot: {
      merge: true,
    },
    HeadAgreementMutationRoot: {
      merge: true,
    },
    RouteAgreementMutationRoot: {
      merge: true,
    },
    RoutePairCustomerTargetMutationRoot: {
      merge: true,
    },
    RouteAgreementPriceMutationRoot: {
      merge: true,
    },
    TargetOverviewMutationRoot: {
      merge: true,
    },
    //FIXME: PriceCalcSearchRoot
    PriceCalcSearchRoot: {
      merge: true,
    },
    PriceCalculatorSearchRoot: {
      merge: true,
    },
  },
};
