import { VehiclePluginsSelect } from "@/common/components/data-driven-inputs/selects/vehicle-plugins-select/VehiclePluginsSelect";
import { NumericAgrVehicleWeightTextInput } from "@/common/components/numeric-agr-vehicle-weight-text-input/NumericAgrVehicleWeightTextInput";
import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { dotToCommaTransform } from "@/common/formatters/NumericTextFieldInputCleaner";
import { FormModelChangeHandler } from "@/common/forms/types";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import * as React from "react";
import { FormLabelWithChildren } from "../PriceCalculatorCustomerForm";
import { PriceCalculatorVehicleSelect } from "./PriceCalculatorVehicleSelect";
import { GapValues } from "@/common/utils/GapValues";
import { useMemo } from "react";
import { NumericTextInputNoScroll } from "@/common/components/forms/NumericTextInputNoScroll";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  setDefaultFormState: React.Dispatch<
    React.SetStateAction<PriceCalculatorFormData>
  >;
}

export const PriceCalculatorVehicleDetails: React.FC<Props> = ({
  formData,
  onChangeFormData,
  setDefaultFormState,
}) => {
  const tradeVehicleSelected = useMemo(() => {
    return /([T][1-9])|([U][1-9])/.test(formData.vehicleType ?? "");
  }, [formData.vehicleType]);

  const palletUnitsVehicleSelected = useMemo(() => {
    return /[G][1-9]/.test(formData.vehicleType ?? "");
  }, [formData.vehicleType]);

  return (
    <Box>
      <Heading variant={"h4"}>Vehicle details</Heading>
      <Space num={SpaceValues.SIXTEEN} />
      <Box gap={GapValues.TWENTYFOUR}>
        <Row gap={GapValues.TWENTYFOUR}>
          <FormLabelWithChildren label={"Vehicle type"} width={190}>
            <PriceCalculatorVehicleSelect
              formData={formData}
              onChangeFormData={onChangeFormData}
              setDefaultFormState={setDefaultFormState}
            />
          </FormLabelWithChildren>
          <FormLabelWithChildren label={"Length (m)"} width={60}>
            <NumericTextInput
              value={dotToCommaTransform(formData.vehicleLength ?? "")}
              onValueChange={(value) =>
                onChangeFormData("vehicleLength", value)
              }
              maxNumbers={2}
              maxDecimals={1}
            />
          </FormLabelWithChildren>
          <FormLabelWithChildren label={"Width (m)"} width={60}>
            <NumericTextInput
              value={dotToCommaTransform(formData.vehicleWidth ?? "")}
              onValueChange={(width) => onChangeFormData("vehicleWidth", width)}
              maxNumbers={2}
              maxDecimals={1}
            />
          </FormLabelWithChildren>
          <FormLabelWithChildren label={"Weight (kg)"} width={75}>
            <NumericAgrVehicleWeightTextInput
              value={formData.vehicleWeight}
              onValueChange={(weight) =>
                onChangeFormData("vehicleWeight", weight)
              }
            />
          </FormLabelWithChildren>
          <FormLabelWithChildren label={"Plugins"} width={85}>
            <VehiclePluginsSelect
              value={formData.vehiclePlugins}
              onValueChange={(plugins) =>
                onChangeFormData("vehiclePlugins", plugins)
              }
            />
          </FormLabelWithChildren>
        </Row>
        {tradeVehicleSelected && (
          <FormLabelWithChildren
            label={"Trade vehicle weight (kg)"}
            width={100}
          >
            <NumericAgrVehicleWeightTextInput
              value={String(formData.vehicleTradeWeight)}
              onValueChange={(tradeWeight) =>
                onChangeFormData("vehicleTradeWeight", tradeWeight)
              }
            />
          </FormLabelWithChildren>
        )}
        {palletUnitsVehicleSelected && (
          <FormLabelWithChildren label={"No. of units"} width={75}>
            <NumericTextInputNoScroll
              value={formData.vehicleTradeUnits?.toString()}
              onValueChange={(tradeUnits) =>
                onChangeFormData("vehicleTradeUnits", parseFloat(tradeUnits))
              }
              min={0}
            />
          </FormLabelWithChildren>
        )}
      </Box>
    </Box>
  );
};
