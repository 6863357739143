import { KeyboardKeyConstant } from "@/common/utils/KeyboardConstants";
import { SpaceValues } from "@/common/utils/SpaceValues";
import {
  GetConsignmentVersionsV2Query,
  GetConsignmentVersionsV2QueryVariables,
} from "@/gql/graphql";
import { useLazyQuery } from "@apollo/client";
import { CSSObject } from "@emotion/react";
import { Box, Row, Space, Text } from "@stenajs-webui/core";
import { FlatButton, stenaSearch } from "@stenajs-webui/elements";
import { TextInput } from "@stenajs-webui/forms";
import { Select } from "@stenajs-webui/select";
import { cssColor } from "@stenajs-webui/theme";
import { gql } from "apollo-boost";
import * as React from "react";
import { KeyboardEvent, useMemo, useState } from "react";
import { PriceCalculatorFormData } from "../../types";
import { FormModelChangeHandler } from "@/common/forms/types";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  onSelectBookingVersion: (
    bookingNum: number,
    consignmentVersion: number
  ) => void;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

const query = gql`
  query GetConsignmentVersionsV2($bookingNum: ID!) {
    priceCalculator {
      bookingVersions {
        byId(id: $bookingNum) {
          versions
        }
      }
    }
  }
`;

interface VersionOption {
  label: string;
  value: string;
}
export const PriceCalculatorBookingSearch: React.FC<Props> = ({
  onSelectBookingVersion,
  onChangeFormData,
}) => {
  const [fetchVersions, { data, loading }] = useLazyQuery<
    GetConsignmentVersionsV2Query,
    GetConsignmentVersionsV2QueryVariables
  >(query, {
    onCompleted: () => setHasSearched(true),
    onError: () => setHasSearched(true),
  });

  const [hasSearched, setHasSearched] = useState(false);
  const [bookingNum, setBookingNum] = useState<string>("");
  const [versionNum, setVersionNum] = useState<string>("");

  const versions = data?.priceCalculator?.bookingVersions?.byId?.versions;
  const versionOptions = useMemo(
    () =>
      versions?.map((version) => ({
        label: `Version: ${version}`,
        value: version.toString(),
      })),
    [versions]
  );

  const selected = useMemo(
    () => versionOptions?.find((o) => o.value === versionNum) ?? "",
    [versionOptions, versionNum]
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardKeyConstant.ENTER) {
      submitBookingNumber();
    }
  };

  const submitBookingNumber = () => {
    setHasSearched(false);
    fetchVersions({
      variables: {
        bookingNum: bookingNum ?? "",
      },
    });
  };

  return (
    <Row alignItems={"center"}>
      <Box width={"150px"}>
        <TextInput
          value={bookingNum}
          onValueChange={(value) => setBookingNum(value)}
          onKeyDown={handleKeyDown}
          buttonRight={
            <FlatButton
              onClick={() => submitBookingNumber()}
              rightIcon={loading ? undefined : stenaSearch}
              loading={loading}
            />
          }
        />
      </Box>
      {versionOptions && versionOptions?.length > 0 ? (
        <>
          <Space num={SpaceValues.SIXTEEN} />
          <Box
            width={120}
            data-testid={testIdConstants.priceCalculatorConsignmentVersionBox}
          >
            <Select
              value={selected}
              options={versionOptions}
              onChange={(version) => {
                const consignmentVersion = (version as VersionOption).value;
                onChangeFormData("bookingResultData", undefined);
                setVersionNum(consignmentVersion);
                onSelectBookingVersion(
                  parseInt(bookingNum),
                  parseInt(consignmentVersion)
                );
              }}
              styles={{
                control: (base: CSSObject) => ({
                  ...base,
                  height: "38px",
                }),
              }}
            />
          </Box>
        </>
      ) : (
        <>
          {hasSearched && (
            <>
              <Space num={SpaceValues.SIXTEEN} />
              <Text variant={"bold"} color={cssColor("--lhds-color-red-500")}>
                No booking with that number exist
              </Text>
            </>
          )}
        </>
      )}
    </Row>
  );
};
