import { isDefined } from "@/common/types/IsDefined";
import {
  FetchBookingFragment,
  PriceCalculationMultiLegResultQuery,
  PriceCalculatorBookingQuery,
  PriceCalculatorInput,
  PriceCalculatorResultV2Query,
  PriceCalculatorRouteLeg,
} from "@/gql/graphql";
import {
  Ancillary,
  BookingResultData,
  CustomerResultData,
  MovementType,
  PriceCalculatorFormData,
  RouteLeg,
} from "../types";

export const transformToCustomerSectionalResultData = (
  queryData: PriceCalculatorResultV2Query,
  formData: PriceCalculatorFormData
): CustomerResultData => {
  if (queryData.priceCalculator.byInput.__typename === "PriceCalculator") {
    return {
      priceCalculatorData: [queryData.priceCalculator.byInput],
      handledError: undefined,
    };
  }
  return {
    priceCalculatorData: [],
    handledError: queryData.priceCalculator?.byInput,
  };
};

export const transformToCustomerMultiLegResultData = (
  queryData: PriceCalculationMultiLegResultQuery,
  formData: PriceCalculatorFormData
): CustomerResultData => {
  if (
    queryData.priceCalculator.byMultiLegInput.__typename ===
    "PriceCalculatorMultiLeg"
  ) {
    return {
      priceCalculatorData: queryData.priceCalculator.byMultiLegInput.list,
      handledError: undefined,
    };
  }
  return {
    priceCalculatorData: [],
    handledError: queryData.priceCalculator.byMultiLegInput,
  };
};

const transformRouteLegToCalculationInputRouteLeg = (
  routeLeg: RouteLeg
): RouteLeg => {
  return {
    departureDate: routeLeg.departureDate,
    departureTime: routeLeg.departureTime?.replace(":", ""),
    routeCode: routeLeg.routeCode,
    sailingTypeCode: routeLeg.sailingTypeCode,
  };
};

const transformToAncillaries = (
  value: PriceCalculatorBookingQuery["priceCalculator"]["bookings"]["byId"][0]["articles"]
): Ancillary[] => {
  return value
    .map((article) => {
      if (article.articleType.type === "ANC") {
        return {
          ancillaryCode: article.articleCode,
          quantity: article.quantity,
        };
      }
      return undefined;
    })
    .filter(isDefined);
};

export const transformCustomerToPriceCalculatorInput = (
  data: PriceCalculatorFormData
): PriceCalculatorInput => {
  return {
    ancillaries: data.conditionsAncillary,
    goodsRecords: data.conditionsGoods
      ? data.conditionsGoods?.map((cg) => {
          return {
            goodsCode: cg.goodsCode,
            weight: Number(cg.weight),
          };
        })
      : null,
    customerNumber: data.selectedCustomerNum,
    departureDate: data.sailingDate,
    departureTime: data.departureTime?.replace(":", ""),
    hazardousGoodsRowWeight: Number(data.cargoHazardousGoodsRowWeight),
    includeImoClass1Row: data.cargoHazardousGoods,
    length: parseFloat(data.vehicleLength ?? ""),
    livestock: data.livestock,
    livestockType: data.livestockType,
    loadingPreferenceCode: data.loadingPreference,
    movementType: data.movementType === MovementType.SECTIONAL ? "S" : "M",
    multiLegRouteCode: data.multiLegRouteData?.code,
    routeLegs:
      data.routeLegs?.map(transformRouteLegToCalculationInputRouteLeg) ?? [],
    noOfAdults: data.passengersAdults,
    noOfChildren: data.passengersChildren,
    noOfDrivers: data.drivers,
    noOfFoc: 0,
    noOfHazardousGoodsRows: data.cargoHazardousGoodsAmountRows,
    noOfInfants: data.passengersInfants,
    noOfPlugins: data.vehiclePlugins ?? 0,
    noOfSleepers: data.passengersSleepers,
    noOfTradeVehicles:
      data.vehicleTradeWeight && Number(data.vehicleTradeWeight) > 0 ? 1 : 0,
    noOfVehTypeUnits: data.vehicleTradeUnits,
    offeredAgreementsAllowed: data.includePendingOffered,
    pendingAgreementsAllowed: data.includePendingOffered,
    qualifiedGoods: data.cargoHazardousGoodsQualified ?? false,
    receiverCountryCode: data.conditionsReceiverCountryCode,
    routeCode: data.routeCode ?? "",
    sailingTypeCode: data.sailingType,
    senderCountryCode: data.conditionsSenderCountryCode,
    standBy: data.conditionsStandby ?? false,
    tradeVehicleWeight: Number(data.vehicleTradeWeight),
    transit: data.conditionsTransit ?? false,
    vehicleTypeCode: data.vehicleType,
    weight: Number(data.vehicleWeight),
    width: parseFloat(data.vehicleWidth ?? ""),
    customsClassificationCode: data.customsClassificationCode,
    // ImportId shall always be sent as a string named as its property value, "importId"
    importId: "importId",
  };
};
export const transformSectionalBookingToPriceCalculatorInput = (
  data: FetchBookingFragment
): PriceCalculatorInput => {
  return {
    routeLegs: null,
    movementType: data.movementType.code,
    length: data.length,
    width: data.width,
    weight: data.weight,
    noOfAdults: data.numberOfAdults,
    noOfChildren: data.numberOfChildren,
    noOfDrivers: data.numberOfDrivers,
    noOfFoc: data.numberOfFoc,
    noOfInfants: data.numberOfInfants,
    noOfSleepers: data.numberOfSleepers,
    vehicleTypeCode: data.vehicleType.code,
    customerNumber: data.customerNumber,
    routeCode: data.sailingBookingConsignment.route.code,
    departureDate: data.sailingBookingConsignment.departureDate,
    departureTime: data.sailingBookingConsignment.departureTime?.replace(
      ":",
      ""
    ),
    sailingTypeCode: data.sailingBookingConsignment.sailingType,
    ancillaries: transformToAncillaries(data.articles),
    goodsRecords: data.goods
      ? data.goods?.map((cg) => {
          return {
            goodsCode: cg.goodsCode,
            weight: cg.weight,
          };
        })
      : null,
    hazardousGoodsRowWeight: data.hazardousGoodsRowWeight,
    includeImoClass1Row: data.includeImoClass1Row,
    livestock: data.hasLivestock,
    livestockType: data.livestockType?.code ?? "",
    loadingPreferenceCode: data.loadingPreferenceCode,
    multiLegRouteCode: data.multiLegRoute?.code ?? "",
    noOfHazardousGoodsRows: data.numberOfHazardousGoodsRows,
    noOfPlugins: data.numberOfPlugins ?? 0,
    noOfTradeVehicles: data.numberOfTradeVehicles,
    noOfVehTypeUnits: data.numberOfVehTypeUnits,
    offeredAgreementsAllowed: false,
    pendingAgreementsAllowed: false,
    qualifiedGoods: data.qualifiedGoods,
    receiverCountryCode: data.receiverCountryCode,
    senderCountryCode: data.senderCountryCode,
    standBy: data.standby,
    tradeVehicleWeight: data.tradeVehicleWeight,
    transit: data.transit,
    customsClassificationCode: data.customsClassificationCode,
    importId: data.importId,
  };
};

export const transformMultiLegBookingToPriceCalculatorInput = (
  data: FetchBookingFragment,
  routeLegs: Array<FetchBookingFragment["sailingBookingConsignment"]>
): PriceCalculatorInput => {
  const transformSailingBookingConsignmentToRouteLegs =
    (): PriceCalculatorRouteLeg[] => {
      return routeLegs.map((leg) => {
        return {
          departureDate: leg.departureDate,
          departureTime: leg.departureTime.replace(":", ""),
          routeCode: leg.route.code,
          sailingTypeCode: leg.sailingType,
        };
      });
    };

  return {
    routeLegs: transformSailingBookingConsignmentToRouteLegs(),
    movementType: data.movementType.code,
    length: data.length,
    width: data.width,
    weight: data.weight,
    noOfAdults: data.numberOfAdults,
    noOfChildren: data.numberOfChildren,
    noOfDrivers: data.numberOfDrivers,
    noOfFoc: data.numberOfFoc,
    noOfInfants: data.numberOfInfants,
    noOfSleepers: data.numberOfSleepers,
    vehicleTypeCode: data.vehicleType.code,
    customerNumber: data.customerNumber,
    routeCode: data.sailingBookingConsignment.route.code,
    ancillaries: transformToAncillaries(data.articles),
    goodsRecords: data.goods
      ? data.goods?.map((cg) => {
          return {
            goodsCode: cg.goodsCode,
            weight: cg.weight,
          };
        })
      : null,
    hazardousGoodsRowWeight: data.hazardousGoodsRowWeight,
    includeImoClass1Row: data.includeImoClass1Row,
    livestock: data.hasLivestock,
    livestockType: data.livestockType?.code ?? "",
    loadingPreferenceCode: data.loadingPreferenceCode,
    multiLegRouteCode: data.multiLegRoute?.code ?? "",
    noOfHazardousGoodsRows: data.numberOfHazardousGoodsRows,
    noOfPlugins: data.numberOfPlugins ?? 0,
    noOfTradeVehicles: data.numberOfTradeVehicles,
    noOfVehTypeUnits: data.numberOfVehTypeUnits,
    offeredAgreementsAllowed: false,
    pendingAgreementsAllowed: false,
    qualifiedGoods: data.qualifiedGoods,
    receiverCountryCode: data.receiverCountryCode,
    senderCountryCode: data.senderCountryCode,
    standBy: data.standby,
    tradeVehicleWeight: data.tradeVehicleWeight,
    transit: data.transit,
    customsClassificationCode: data.customsClassificationCode,
    importId: data.importId,
  };
};

const getPriceCalculationError = (
  value: PriceCalculatorResultV2Query["priceCalculator"]["byInput"]
) => {
  if (value.__typename === "PriceCalculatorError") {
    return value;
  }
  return null;
};

export const transformQueryToBookingSearchResultData = (
  queryData: PriceCalculatorResultV2Query | undefined,
  bookings: FetchBookingFragment[]
): BookingResultData => {
  const priceCalculationError =
    queryData?.priceCalculator.byInput &&
    getPriceCalculationError(queryData?.priceCalculator.byInput);

  if (queryData?.priceCalculator.byInput.__typename === "PriceCalculator") {
    return {
      bookingData: bookings,
      priceCalculatorData: [queryData?.priceCalculator.byInput],
      handledError: undefined,
    };
  }

  if (
    priceCalculationError &&
    queryData?.priceCalculator.byInput.__typename === "PriceCalculatorError"
  ) {
    return {
      bookingData: bookings,
      priceCalculatorData: [],
      handledError: priceCalculationError,
    };
  }

  return {
    bookingData: bookings,
    priceCalculatorData: [],
    handledError: queryData?.priceCalculator.byInput,
  };
};

const getPriceCalculationMultiLegError = (
  value: PriceCalculationMultiLegResultQuery["priceCalculator"]["byMultiLegInput"]
) => {
  if (value.__typename === "PriceCalculatorError") {
    return value;
  }
  return null;
};

export const transformQueryToMultiLegBookingSearchResultData = (
  queryData: PriceCalculationMultiLegResultQuery | undefined,
  bookings: FetchBookingFragment[]
): BookingResultData => {
  const priceCalculationError =
    queryData?.priceCalculator.byMultiLegInput &&
    getPriceCalculationMultiLegError(
      queryData?.priceCalculator.byMultiLegInput
    );

  if (
    queryData?.priceCalculator.byMultiLegInput.__typename ===
    "PriceCalculatorMultiLeg"
  ) {
    return {
      bookingData: bookings,
      priceCalculatorData: queryData?.priceCalculator.byMultiLegInput.list,
      handledError: undefined,
    };
  }

  if (
    priceCalculationError &&
    queryData?.priceCalculator.byMultiLegInput.__typename ===
      "PriceCalculatorError"
  ) {
    return {
      bookingData: bookings,
      priceCalculatorData: [],
      handledError: priceCalculationError,
    };
  }

  return {
    bookingData: bookings,
    priceCalculatorData: [],
    handledError: queryData?.priceCalculator.byMultiLegInput,
  };
};
