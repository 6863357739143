import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import {
  FeatureFlagsContext,
  FeatureFlagsContextType,
} from "@/config/featureFlags/FeatureFlagsContext";
import { Box, Space, Text } from "@stenajs-webui/core";
import React, { Fragment, useContext } from "react";
import { AuditData } from "../../types";
import { transformAuditLogsToText } from "./transformers";

interface Props {
  auditData: AuditData[] | null;
}

export const PriceCalculatorAuditTab: React.FC<Props> = ({ auditData }) => {
  const { featureFlags } = useContext(
    FeatureFlagsContext
  ) as FeatureFlagsContextType;

  const usePricingV2 = featureFlags.pricing.usePricingV2;

  const auditDataError = () =>
    usePricingV2 && auditData && auditData.filter((ad) => ad.errorMessage)
      ? true
      : false;

  const auditErrorWithLogs = () => {
    if (auditDataError() && usePricingV2 && auditData) {
      return (
        <Box indent={IndentValues.TWENTYFOUR}>
          {auditData.map(
            (ad, index) =>
              ad.errorMessage && (
                <Fragment key={index}>
                  <Text variant={"bold"}>{`Error: ${ad.errorMessage}`}</Text>
                  <Space num={SpaceValues.FOUR} />
                </Fragment>
              )
          )}
          {auditData.some((ad) => ad.errorMessage) ? (
            <Space num={SpaceValues.SIXTEEN} />
          ) : null}
          {auditData?.map((leg, index) => (
            <Fragment key={index}>
              {transformAuditLogsToText(leg.auditLogs, usePricingV2)}
              <Space num={SpaceValues.SIXTEEN} />
            </Fragment>
          ))}
        </Box>
      );
    }
  };

  return (
    <>
      {auditDataError()
        ? auditErrorWithLogs()
        : auditData?.map((leg, index) => (
            <Box indent={IndentValues.TWENTYFOUR} key={index}>
              {transformAuditLogsToText(leg.auditLogs, usePricingV2)}
              <Space num={SpaceValues.SIXTEEN} />
            </Box>
          ))}
    </>
  );
};
