import { Box, BoxProps, SeparatorLine, Space, Txt } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { useContext } from "react";
import {
  PriceCalculatorContext,
  PriceCalculatorContextType,
} from "../../PriceCalculatorContext";
import { MovementType } from "../../types";
import { PriceCalculatorMovementTypeSelect } from "./common/PriceCalculatorMovementTypeSelect";
import { PriceCalculatorMultiLegOptions } from "./multi-leg/PriceCalculatorMultiLegOptions";
import { PriceCalculatorSectionalOptions } from "./sectional/PriceCalculatorSectionalOptions";

export const FormLabelWithChildren: React.FC<
  BoxProps & {
    label: string;
  }
> = ({ label, children, ...props }) => {
  return (
    <Box {...props}>
      <Txt
        variant={"bold"}
        size={"small"}
        color={cssColor("--tjara")}
        whiteSpace="nowrap"
      >
        {label}
      </Txt>
      <Space />
      {children}
    </Box>
  );
};

export const PriceCalculatorCustomerForm: React.FC = () => {
  const { formData } = useContext(
    PriceCalculatorContext
  ) as PriceCalculatorContextType;
  return (
    <>
      <PriceCalculatorMovementTypeSelect />
      <SeparatorLine />
      {formData.movementType === MovementType.SECTIONAL ? (
        <PriceCalculatorSectionalOptions />
      ) : (
        <PriceCalculatorMultiLegOptions />
      )}
    </>
  );
};
