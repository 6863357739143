import { GapValues } from "@/common/utils/GapValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import {
  PriceCalculatorContext,
  PriceCalculatorContextType,
} from "@/features/price-calculator-v2/PriceCalculatorContext";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import * as React from "react";
import { useContext } from "react";
import { PriceCalculatorGoodsAndAncillaries } from "../common/PriceCalculatorGoodsAndAncillaries";
import { PriceCalculatorMoreOptionsCollapsible } from "../common/PriceCalculatorMoreOptionsCollapsible";
import { PriceCalculatorVehicleDetails } from "../common/PriceCalculatorVehicleDetails";
import { PriceCalculatorLegsTable } from "./PriceCalculatorLegsTable";
import { FlatButton } from "@stenajs-webui/elements";

export const PriceCalculatorMultiLegOptions: React.FC = () => {
  const {
    formData,
    onChangeFormData,
    setState,
    setDefaultFormState,
    defaultFormState,
  } = useContext(PriceCalculatorContext) as PriceCalculatorContextType;

  return (
    <>
      <Space num={SpaceValues.TWENTYFOUR} />
      <Box gap={GapValues.TWENTYFOUR}>
        <Row justifyContent={"space-between"} alignItems={"center"}>
          <Heading variant={"h4"}>Legs</Heading>
          <FlatButton
            label={"Clear"}
            onClick={() =>
              setState({
                ...defaultFormState,
                selectedCustomerNum: formData.selectedCustomerNum,
                movementType: formData.movementType,
                moreOptionsPanelCollapsed: formData.moreOptionsPanelCollapsed,
              })
            }
          />
        </Row>
        <PriceCalculatorLegsTable
          formData={formData}
          onChangeFormData={onChangeFormData}
          setState={setState}
          defaultFormState={defaultFormState}
        />
        <PriceCalculatorVehicleDetails
          formData={formData}
          onChangeFormData={onChangeFormData}
          setDefaultFormState={setDefaultFormState}
        />
        <PriceCalculatorGoodsAndAncillaries
          formData={formData}
          onChangeFormData={onChangeFormData}
        />
        <PriceCalculatorMoreOptionsCollapsible
          formData={formData}
          onChangeFormData={onChangeFormData}
          defaultFormState={defaultFormState}
        />
      </Box>
    </>
  );
};
