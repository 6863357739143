import { stenaBusinessNslh } from "@stenajs-webui/elements";
import * as React from "react";
import { PageHeader } from "../../../common/components/page-header/PageHeader";

export const NoShowLateHandlingHandleBookingsHeader: React.FC = () => {
  return (
    <PageHeader
      title={"Handle bookings"}
      sticky
      icon={stenaBusinessNslh}
      width={"100vw"}
      offsetLeft={0}
    />
  );
};
