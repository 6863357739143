import { stenaCalculate } from "@stenajs-webui/elements";
import * as React from "react";
import { PageHeader } from "../../common/components/page-header/PageHeader";

export const PriceCalculatorHeader: React.FC = () => {
  return (
    <PageHeader
      title={"Price Calculator"}
      icon={stenaCalculate}
      bottomSpacing={false}
    />
  );
};
