import { gql } from "apollo-boost";

export const fetchPriceCalculatorFragment = gql`
  fragment FetchPriceCalculator on PriceCalculator {
    auditLogs
    errorMessage
    baseCurrencyCode
    seaFreightPrice
    surchargeRecords {
      articleType {
        id
        code
        name
      }
      quantity
      surchargePrice
      surchargeVatAmount
      surchargeVatRate
    }
    ancillaryRecords {
      ancillaryCode
      quantity
      ancillaryPrice
      ancillaryVatAmount
      ancillaryVatRate
    }
    totalPrice
    totalVat
    route {
      id
      code
      name
    }
  }
`;

export const fetchBookingFragment = gql`
  fragment FetchBooking on PriceCalculatorBooking {
    id
    version
    bookingNumber
    priceCalculatorStatus
    transit
    vehicleType {
      id
      code
      length
      shortDescription
    }
    vehicleRegistrationNumber
    trailerRegistrationNumber
    sailingBookingConsignment {
      id
      sailingType
      departureDate
      departureTime
      route {
        id
        code
        name
      }
    }
    articles {
      id
      articleCode
      quantity
      articleType {
        id
        type
        name
      }
    }
    hasLivestock
    livestockType {
      id
      code
      description
    }
    loadingPreferenceCode
    movementType {
      id
      code
      description
    }
    multiLegRoute {
      id
      name
      code
    }
    multiLegBookingNumber
    version
    bookingLegNumber
    numberOfDrivers
    numberOfAdults
    numberOfChildren
    numberOfInfants
    numberOfFoc
    numberOfPlugins
    numberOfSleepers
    numberOfTradeVehicles
    numberOfVehTypeUnits
    bookingNumber
    numberOfHazardousGoodsRows
    standby
    transit
    qualifiedGoods
    receiverCountryCode
    senderCountryCode
    tradeVehicleWeight
    hazardousGoodsRowWeight
    includeImoClass1Row
    customsClassificationCode
    importId
    goods {
      id
      goodsCode
      weight
      description
    }
    priceCalculatorStatus
    customerNumber
    customerReference
    length
    weight
    width
    routeCode {
      id
      code
      name
    }
    bookingLegInvoice {
      id
      invoiceNo
    }
  }
`;
