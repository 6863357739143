import { Card, CardBody, Tab, TabMenu } from "@stenajs-webui/elements";
import React, { useState } from "react";
import {
  BookingSearchResultData,
  SelectedTab,
} from "../../PriceCalculatorFormFetcher";
import { PriceCalculatorAuditTab } from "../components/PriceCalculatorAuditTab";
import { transformBookingResultToAuditData } from "../transformers/TransformToAuditData";
import { PriceCalculatorBookingDetailMultiLegTab } from "./PriceCalculatorBookingDetailMultiLegTab";
import { PriceCalculatorBookingDetailSectionalTab } from "./PriceCalculatorBookingDetailSectionalTab";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props {
  bookingPriceCalcResult?: BookingSearchResultData;
}

export const PriceCalculatorBookingSearchResultPane: React.FC<Props> = ({
  bookingPriceCalcResult,
}) => {
  const priceTabDisabled =
    bookingPriceCalcResult &&
    bookingPriceCalcResult.priceCalcData.some((data) => data.totalPrice === 0);
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.AUDIT
  );

  const displaySectionalDetailsTab =
    selectedTab === SelectedTab.PRICE &&
    bookingPriceCalcResult &&
    bookingPriceCalcResult.consignmentData.length === 1 &&
    bookingPriceCalcResult.consignmentData[0].movementType.code === "S";

  const displayMultiLegDetailsTab =
    selectedTab === SelectedTab.PRICE &&
    bookingPriceCalcResult &&
    bookingPriceCalcResult.consignmentData.length > 0 &&
    bookingPriceCalcResult.consignmentData[0].movementType.code === "M";

  return (
    <Card>
      <CardBody>
        <TabMenu>
          <Tab
            disabled={priceTabDisabled}
            label={"Price details"}
            selected={selectedTab === SelectedTab.PRICE}
            onClick={() => setSelectedTab(SelectedTab.PRICE)}
            data-testid={testIdConstants.PriceCalculatorPriceDetailsButton}
          />
          <Tab
            label={"Audit track"}
            selected={selectedTab === SelectedTab.AUDIT}
            onClick={() => setSelectedTab(SelectedTab.AUDIT)}
            data-testid={testIdConstants.PriceCalculatorAuditTrackButton}
          />
        </TabMenu>
      </CardBody>
      {bookingPriceCalcResult && (
        <>
          {displaySectionalDetailsTab && (
            <PriceCalculatorBookingDetailSectionalTab
              bookingSearchResultData={bookingPriceCalcResult}
            />
          )}
          {displayMultiLegDetailsTab && (
            <PriceCalculatorBookingDetailMultiLegTab
              bookingSearchResultData={bookingPriceCalcResult}
            />
          )}
          {selectedTab === SelectedTab.AUDIT && bookingPriceCalcResult && (
            <PriceCalculatorAuditTab
              auditData={bookingPriceCalcResult.priceCalcData.map((priceCalc) =>
                transformBookingResultToAuditData(
                  priceCalc,
                  bookingPriceCalcResult?.consignmentData[0]
                )
              )}
            />
          )}
        </>
      )}
    </Card>
  );
};
