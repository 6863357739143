import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/client";
import {
  PriceCalculatorSailingsV2Query,
  PriceCalculatorSailingsV2QueryVariables,
} from "@/gql/graphql";

// FIXME: Change from V2
const query = gql`
  fragment PriceCalcSailings on Sailing {
    id
    departureTime
    sailingTypeCode
    statusCode
  }
  query PriceCalculatorSailingsV2(
    $routeCode: String!
    $departureDate: String!
  ) {
    productPrice {
      sailings {
        byRouteCodeDeparture(
          routeCode: $routeCode
          departureDate: $departureDate
        ) {
          ...PriceCalcSailings
        }
      }
    }
  }
`;

export const useSailingsByRouteCodeDepartureDate = () => {
  const [fetchSailings, { loading: sailingDeparturesLoading, data }] =
    useLazyQuery<
      PriceCalculatorSailingsV2Query,
      PriceCalculatorSailingsV2QueryVariables
    >(query);

  const sailings = data?.productPrice.sailings?.byRouteCodeDeparture?.filter(
    (sailing) =>
      sailing.statusCode.toUpperCase() === "A" ||
      sailing.statusCode.toUpperCase() === "F"
  );

  return { fetchSailings, sailingDeparturesLoading, sailings };
};
