import { gql } from "@apollo/client";

export const childSelectorQuery = gql`
  fragment childCustomersByStatus on Customer {
    id
    countryCode
    custNo
    name
    subscriberContactsForReport {
      id
      email
      subscriberContactId
    }
    activeHeadAgreementByYear(year: $year) {
      id
    }
    custIndex
    hasDocumentByInput(
      agreementId: $agreementId
      agreementDocumentType: $agreementDocumentType
    )
  }

  query ChildSelector(
    $customerId: ID!
    $year: Int!
    $agreementId: String!
    $agreementDocumentType: String!
  ) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          childCustomersByStatus(customerStatusCodes: ["O"]) {
            ...childCustomersByStatus
          }
        }
      }
    }
  }
`;
