import {
  FeatureFlagsContext,
  FeatureFlagsContextType,
} from "@/config/featureFlags/FeatureFlagsContext";
import { ApolloError } from "apollo-boost";
import { useContext, useState } from "react";
import {
  PriceCalculatorContext,
  PriceCalculatorContextType,
} from "../PriceCalculatorContext";
import { MovementType, PriceCalculatorFormData } from "../types";
import { useMultiLegPriceCalculation } from "./UseMultiLegPriceCalculation";
import { usePriceCalculation } from "./UsePriceCalculation";
import { transformCustomerToPriceCalculatorInput } from "./transformers";

export const useCustomerPriceCalculation = () => {
  const { formData, onChangeFormData, setCustomerCalculatedState } = useContext(
    PriceCalculatorContext
  ) as PriceCalculatorContextType;

  const { calculateMultiLegPrice, loading: multiLegCalculationLoading } =
    useMultiLegPriceCalculation(
      formData,
      onChangeFormData,
      setCustomerCalculatedState
    );

  const { calculatePrice, loading: sectionalCalculationLoading } =
    usePriceCalculation(formData, onChangeFormData, setCustomerCalculatedState);

  const { featureFlags } = useContext(
    FeatureFlagsContext
  ) as FeatureFlagsContextType;

  const usePricingV2 = featureFlags.pricing.usePricingV2;
  const [responseError, setResponseError] = useState<ApolloError | undefined>();

  const calculateCustomerPrice = async (formData: PriceCalculatorFormData) => {
    const input = transformCustomerToPriceCalculatorInput(formData);
    if (formData.movementType === MovementType.MULTILEG) {
      const { error } = await calculateMultiLegPrice({
        variables: {
          input,
          usePricingV2,
        },
      });
      setResponseError(error);
    } else {
      const { error } = await calculatePrice({
        variables: {
          input,
          usePricingV2,
        },
      });
      setResponseError(error);
    }
  };

  return {
    calculateCustomerPrice,
    loading: sectionalCalculationLoading || multiLegCalculationLoading,
    error: responseError,
  };
};
