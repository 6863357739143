import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { routeFactory } from "./RouteFactory";
import { ReportScreen } from "./features/create-agreement/features/report-screen/ReportScreen";
import { MissingPageScreen } from "./features/missing-page/MissingPageScreen";
import { NslhFollowUpWrapper } from "./features/no-show-late-handling/NslhFollowUpWrapper";
import { NoShowLateHandlingHandleBookingsPanel } from "./features/no-show-late-handling/handle-bookings/NoShowLateHandlingHandleBookingsPanel";
import { ViewSpecificationScreen } from "./features/no-show-late-handling/invoice-specification-view/ViewSpecificationScreen";
import { OneMonthBookingsScreen } from "./features/no-show-late-handling/one-month-bookings/OneMonthBookingsScreen";
import { NoShowLateHandlingRoutePricesScreen } from "./features/no-show-late-handling/route-prices/NoShowLateHandlingRoutePricesScreen";
import { PriceCalculatorScreen } from "./features/price-calculator/PriceCalculatorScreen";
import { CustomerMultilegRoutePairScreen } from "./features/product-and-price/customer-multileg-route/components/CustomerMultilegRoutePairScreen";
import { CustomerRoutePairScreen } from "./features/product-and-price/customer-route/components/CustomerRoutePairScreen";
import { ChildCustomersScreen } from "./features/product-and-price/customers/child-customers/components/ChildCustomersScreen";
import { CustomerScreen } from "./features/product-and-price/customers/customer-details/components/CustomerScreen";
import { CustomerListScreen } from "./features/product-and-price/customers/customer-list/components/CustomerListScreen";
import { CustomerExpectedVolumeScreen } from "./features/product-and-price/expected-volume/components/CustomerExpectedVolumeScreen";
import { ActivateHeadAgreementScreen } from "./features/product-and-price/head-agreement/activate/components/ActivateHeadAgreementScreen";
import { AgreementArticlesScreen } from "./features/product-and-price/head-agreement/agreement-articles/components/AgreementArticlesScreen";
import { AgreementDocumentsScreen } from "./features/product-and-price/head-agreement/agreement-documents/components/AgreementDocumentsScreen";
import { CopyHeadAgreementScreen } from "./features/product-and-price/head-agreement/copy/components/CopyHeadAgreementScreen";
import { CopyHaToOtherCustomerScreen } from "./features/product-and-price/head-agreement/copy/components/copy-or-create-new-ha-to-other-customer/CopyHaToOtherCustomerScreen";
import { CreateHeadAgreementScreen } from "./features/product-and-price/head-agreement/create/components/CreateHeadAgreementScreen";
import { CustomerHeadAgreementScreen } from "./features/product-and-price/head-agreement/details/components/CustomerHeadAgreementScreen";
import { EditHeadAgreementScreen } from "./features/product-and-price/head-agreement/edit/components/EditHeadAgreementScreen";
import { RebateAgreementDocumentsScreen } from "./features/product-and-price/head-agreement/rebate-agreement-documents/components/RebateAgreementDocumentsScreen";
import { CreateMultiLegAgreementRoute } from "./features/product-and-price/multileg-agreement/components/create-multi-leg-agreement/CreateMultiLegAgreementRoute";
import { MultiLegRouteAgreementDetailsScreen } from "./features/product-and-price/multileg-agreement/details/MultiLegRouteAgreementDetailsScreen";
import { BulkEditRatesScreen } from "./features/product-and-price/rate-sheet/bulk-edit-rate/components/BulkEditRatesScreen";
import { CreateRouteAgreementRoute } from "./features/product-and-price/route-agreement/create/components/CreateRouteAgreementRoute";
import { RouteAgreementDetailsScreen } from "./features/product-and-price/route-agreement/details/components/RouteAgreementDetailsScreen";
import { StatisticsRouter } from "./features/statistics/common/StatisticsRouter";
import { AdminTargetScreen } from "./features/targeting-tool/admin-target/AdminTargetScreen";
import { RouteTargetScreen } from "./features/targeting-tool/route-target/components/RouteTargetScreen";
import { RouteTargetsOverviewScreen } from "./features/targeting-tool/route-targets-overview/components/RouteTargetsOverviewScreen";
import { SurchargeDocumentsScreen } from "./features/admin/upload-surcharge-documents/SurchargeDocumentsScreen";
import { RebateScreen } from "./features/rebate/RebateScreen";
import { PriceCalculatorScreenV2 } from "./features/price-calculator-v2/PriceCalculatorScreenV2";
import { CustomerNoExistingHa } from "./features/product-and-price/customers/customer-details/components/CustomerNoExistingHa";

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={routeFactory.appRoot()} element={<CustomerListScreen />} />
      <Route
        path={routeFactory.targetingTool.routeTargets.allRoutes()}
        element={<RouteTargetsOverviewScreen />}
      />
      <Route
        path={routeFactory.targetingTool.routeTargets.routeTarget({
          routePairCode: ":routePairCode",
        })}
        element={<RouteTargetScreen />}
      />
      <Route
        path={routeFactory.targetingTool.adminTarget.allRoutes()}
        element={<AdminTargetScreen />}
      />
      <Route
        path={routeFactory.admin.surchargeDocuments()}
        element={<SurchargeDocumentsScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.customer.customerList()}
        element={<CustomerListScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.customer.customer({
          customerId: ":customerId",
        })}
        element={<CustomerScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.customer.customerNoHeadAgreement({
          customerId: ":customerId",
        })}
        element={<CustomerNoExistingHa />}
      />
      <Route
        path={routeFactory.productAndPrice.customer.customerExpectedVolume({
          customerId: ":customerId",
        })}
        element={<CustomerExpectedVolumeScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.customer.childCustomers({
          customerId: ":customerId",
        })}
        element={<ChildCustomersScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.customer.customerHeadAgreement({
          customerId: ":customerId",
          headAgreementId: ":headAgreementId",
        })}
        element={<CustomerHeadAgreementScreen />}
      >
        <Route
          path={routeFactory.productAndPrice.routeAgreement.createRouteAgreementSubPath()}
          element={<CreateRouteAgreementRoute />}
        />
        <Route
          path={
            routeFactory.productAndPrice.multiLegAgreement
              .createMultilegRouteAgreementSubPath
          }
          element={<CreateMultiLegAgreementRoute />}
        />
      </Route>
      <Route
        path={routeFactory.productAndPrice.headAgreement.activateHeadAgreement({
          customerId: ":customerId",
          headAgreementId: ":headAgreementId",
        })}
        element={<ActivateHeadAgreementScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.headAgreement.copyHeadAgreement({
          customerId: ":customerId",
          headAgreementId: ":headAgreementId",
        })}
        element={<CopyHeadAgreementScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.headAgreement.copyHeadAgreementToOtherCustomer(
          {
            customerId: ":customerId",
            headAgreementId: ":headAgreementId",
          }
        )}
        element={<CopyHaToOtherCustomerScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.headAgreement.agreementArticles({
          customerId: ":customerId",
          headAgreementId: ":headAgreementId",
        })}
        element={<AgreementArticlesScreen />}
      />
      <Route
        path={
          routeFactory.productAndPrice.headAgreement.editHeadAgreement({
            customerId: ":customerId",
            headAgreementId: ":headAgreementId",
          }) + "/*"
        }
        element={<EditHeadAgreementScreen />}
      />

      <Route
        path={
          routeFactory.productAndPrice.headAgreement.bulkEditRateHeadAgreement({
            customerId: ":customerId",
            headAgreementId: ":headAgreementId",
          }) + "/*"
        }
        element={<BulkEditRatesScreen />}
      />

      <Route
        path={routeFactory.productAndPrice.headAgreement.createReport({
          customerId: ":customerId",
          headAgreementId: ":headAgreementId",
          reportType: ":reportType",
          parent: ":parent",
        })}
        element={<ReportScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.headAgreement.createReport({
          customerId: ":customerId",
          headAgreementId: ":headAgreementId",
          reportType: ":reportType",
        })}
        element={<ReportScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.headAgreement.createHeadAgreement({
          customerId: ":customerId",
        })}
        element={<CreateHeadAgreementScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.headAgreement.agreementDocuments({
          customerNo: ":customerId",
        })}
        element={<AgreementDocumentsScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.headAgreement.rebateAgreementDocuments(
          {
            customerNo: ":customerId",
          }
        )}
        element={<RebateAgreementDocumentsScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.customer.customerRoutePair({
          customerId: ":customerId",
          headAgreementId: ":headAgreementId",
          routePairId: ":routePairId",
        })}
        element={<CustomerRoutePairScreen />}
      />
      <Route
        path={
          routeFactory.productAndPrice.routeAgreement.routeAgreementDetails({
            customerId: ":customerId",
            headAgreementId: ":headAgreementId",
            routePairId: ":routePairId",
            routeAgreementId: ":routeAgreementId",
          }) + "/*"
        }
        element={<RouteAgreementDetailsScreen />}
      />
      <Route
        path={routeFactory.productAndPrice.multiLegAgreement.multiLegAgreementDetails(
          {
            customerId: ":customerId",
            headAgreementId: ":headAgreementId",
            multiLegAgreementId: ":multiLegAgreementId",
          }
        )}
        element={<CustomerMultilegRoutePairScreen />}
      />
      <Route
        path={
          routeFactory.productAndPrice.multiLegAgreement.multiLegRouteAgreementDetails(
            {
              customerId: ":customerId",
              headAgreementId: ":headAgreementId",
              multiLegAgreementId: ":multiLegAgreementId",
              routeAgreementId: ":routeAgreementId",
            }
          ) + "/*"
        }
        element={<MultiLegRouteAgreementDetailsScreen />}
      />
      <Route
        path={routeFactory.rebates.rebatesRoot() + "/*"}
        element={<RebateScreen />}
      />
      <Route
        path={routeFactory.statistics.statisticsRoot() + "/*"}
        element={<StatisticsRouter />}
      />
      <Route
        path={routeFactory.priceCalculator.priceCalculatorRoot() + "/*"}
        element={<PriceCalculatorScreen />}
      />
      <Route
        path={routeFactory.priceCalculator.priceCalculatorRootV2() + "/*"}
        element={<PriceCalculatorScreenV2 />}
      />
      <Route
        path={routeFactory.noShowLateHandling.routePrices()}
        element={<NoShowLateHandlingRoutePricesScreen />}
      />
      <Route
        path={routeFactory.noShowLateHandling.handleBookings()}
        element={<NoShowLateHandlingHandleBookingsPanel />}
      />
      <Route
        path={routeFactory.noShowLateHandling.followUpTabView(":tab")}
        element={<NslhFollowUpWrapper />}
      />
      <Route
        path={routeFactory.noShowLateHandling.invoiceSpecification(
          ":invoiceNumber"
        )}
        element={<ViewSpecificationScreen />}
      />
      <Route
        path={routeFactory.noShowLateHandling.oneMonthBookings(
          ":accountNo",
          ":year",
          ":month"
        )}
        element={<OneMonthBookingsScreen />}
      />
      <Route path={"*"} element={<MissingPageScreen />} />
    </Routes>
  );
};
