import React, { Fragment } from "react";
import { CustomerRateSheetListItem } from "./CustomerRateSheetListItem";
import {
  CustomerRouteFetcherHeadAgreementFragment,
  CustomerRouteFetcherRouteAgreementFragment,
  SailingTypeCode,
} from "@/gql/graphql";

const sortRaBySailingTypeCodes = (
  routeAgreements: Array<CustomerRouteFetcherRouteAgreementFragment>
) => {
  const sailingTypePRouteAgreements = routeAgreements.filter(
    (ra) =>
      ra.sailingTypeCodes.length === 1 &&
      ra.sailingTypeCodes[0] === SailingTypeCode.P
  );
  const sailingTypePAndMore = routeAgreements.filter(
    (ra) =>
      ra.sailingTypeCodes.length > 1 &&
      ra.sailingTypeCodes.includes(SailingTypeCode.P)
  );
  const sailingTypeSRouteAgreements = routeAgreements.filter(
    (ra) =>
      ra.sailingTypeCodes.length === 1 &&
      ra.sailingTypeCodes[0] === SailingTypeCode.S
  );
  const sailingTypeSAndMore = routeAgreements.filter(
    (ra) =>
      ra.sailingTypeCodes.length > 1 &&
      ra.sailingTypeCodes.includes(SailingTypeCode.S) &&
      !ra.sailingTypeCodes.includes(SailingTypeCode.P)
  );
  const sailingTypeORouteAgreements = routeAgreements.filter(
    (ra) =>
      ra.sailingTypeCodes.length === 1 &&
      ra.sailingTypeCodes[0] === SailingTypeCode.O
  );
  const sailingTypeMRouteAgreements = routeAgreements.filter(
    (ra) =>
      ra.sailingTypeCodes.length === 1 &&
      ra.sailingTypeCodes[0] === SailingTypeCode.M
  );
  const sortedRouteAgreements = sailingTypePRouteAgreements.concat(
    sailingTypePAndMore,
    sailingTypeSRouteAgreements,
    sailingTypeSAndMore,
    sailingTypeORouteAgreements,
    sailingTypeMRouteAgreements
  );
  return sortedRouteAgreements;
};

interface Props {
  headAgreement: CustomerRouteFetcherHeadAgreementFragment;
  routeAgreements: Array<CustomerRouteFetcherRouteAgreementFragment>;
  routePairId: string;
  customerId: string;
  bulkUpdateModeEnabled: boolean;
}

export const CustomerRateSheetList: React.FC<Props> = React.memo(
  ({
    headAgreement,
    customerId,
    routeAgreements,
    routePairId,
    bulkUpdateModeEnabled,
  }) => {
    return (
      <>
        {sortRaBySailingTypeCodes(routeAgreements).map((routeAgreement) => (
          <Fragment key={routeAgreement.id}>
            <CustomerRateSheetListItem
              customerId={customerId}
              bulkUpdateModeEnabled={bulkUpdateModeEnabled}
              routeAgreement={routeAgreement}
              headAgreement={headAgreement}
              routePairId={routePairId}
            />
          </Fragment>
        ))}
      </>
    );
  }
);
