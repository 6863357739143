import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { BuildVersionsQuery } from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";
import { ErrorPage } from "@/LoginPage";

const buildVersionsQuery = gql`
  query BuildVersions {
    productPrice {
      buildVersions {
        frontend {
          version
        }
        backend {
          version
        }
      }
    }
  }
`;

export interface BuildVersions {
  frontend: {
    version: string;
  };
  backend: {
    version: string;
  };
}

export interface BuildVersionsContextType {
  buildVersions: BuildVersions;
  setBuildVersions: React.Dispatch<React.SetStateAction<BuildVersions>>;
}

const BuildVersionsContext = createContext<BuildVersionsContextType | null>(
  null
);

const BuildVersionsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { data, error } = useQuery<BuildVersionsQuery>(buildVersionsQuery);

  const [buildVersions, setBuildVersions] = useState<BuildVersions>({
    frontend: {
      version: "",
    },
    backend: {
      version: "",
    },
  });

  useEffect(() => {
    if (data) {
      setBuildVersions(data.productPrice.buildVersions);
    }
  }, [data, setBuildVersions]);

  if (error) {
    return <ErrorPage text={error.message} />;
  }

  return (
    <BuildVersionsContext.Provider
      value={{
        buildVersions,
        setBuildVersions,
      }}
    >
      {children}
    </BuildVersionsContext.Provider>
  );
};

export { BuildVersionsContext, BuildVersionsProvider };
