import { formatDateString } from "@/common/dates/formatters";
import { Row, Spacing, useBoolean } from "@stenajs-webui/core";
import {
  ActionMenu,
  ActionMenuItem,
  ActionMenuSeparator,
  FlatButton,
  ResultListBanner,
  Spinner,
  stenaBell,
  stenaCopy,
  stenaDots,
  stenaPen,
  stenaTrash,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import { uniq } from "lodash";
import * as React from "react";
import { useState } from "react";
import { CreateMultiLegRouteAgreementModal } from "../../../../../../multileg-agreement/components/create-multi-leg-route-agreement/CreateMultiLegRouteAgreementModal";
import { EditMultiLegAgreementModal } from "../../../../../../multileg-agreement/components/edit-multi-leg-agreement/EditMultiLegAgreementModal";
import { MultiLegAgreement } from "../../../../types";
import {
  canChangeRouteAgreementStatusToActive,
  canChangeStatusToPending,
  canChangeStatusToVoid,
} from "../../util/AgreementStatusUtil";
import { breakDateIsRequired } from "../../util/BreakDateRequirementChecker";
import { ensureValidBreakDate } from "../../util/BreakDateValidator";
import { ModalStates } from "../../util/ModalStatesEnum";
import { ConfirmMultiLegRouteAgreementDeleteModal } from "./ConfirmMultiLegRouteAgreementDeleteModal";
import { SelectCustomerAndHeadAgreementMultiLegModal } from "./copy-to-other-customer/SelectCustomerAndHeadAgreementModalForMultiLeg";
import { CopyMultiLegRouteAgreementModal } from "./CopyMultiLegRouteAgreementModal";
import { useActivateMultiLegAgreementMutation } from "./hooks/UseActivateMultiLegAgreement";
import { useChangeMultiLegAgreementStatusMutation } from "./hooks/UseChangeMultiLegAgreementStatusMutation";
import { useCopyMultiLegRouteAgreementMutation } from "./hooks/UseCopyMultiLegRouteAgreement";
import { HeadAgreementStatusCode } from "@/gql/graphql";

interface Props {
  multiLegAgreement: MultiLegAgreement;
  year: number;
}

export const MultiLegRouteAgreementActionButtons: React.FC<Props> = ({
  multiLegAgreement,
  year,
}) => {
  const {
    headAgreement: { id: headAgreementId },
    id,
    rowVersion,
  } = multiLegAgreement;

  const [popoverOpen, openPopover, closePopover] = useBoolean(false);
  const [modalState, setModalState] = useState<ModalStates>(ModalStates.Closed);
  const {
    bannerState,
    clearBannerResult,
    setBannerResultWithTexts,
    setBannerResultWithErrors,
    setBannerState,
  } = useResultListBannerState(undefined);

  const closeModal = () => {
    setModalState(ModalStates.Closed);
    clearBannerResult();
  };

  const [copyMultiLegRouteAgreement, { loading: copyLoading }] =
    useCopyMultiLegRouteAgreementMutation();

  const [changeMultiLegRootAgreementStatus, { loading: changeStatusLoading }] =
    useChangeMultiLegAgreementStatusMutation();

  const [activateMultiLegAgreement, { loading: activateLoading }] =
    useActivateMultiLegAgreementMutation();

  const onChangeStatus = async (status: HeadAgreementStatusCode) => {
    try {
      const { data } = await changeMultiLegRootAgreementStatus({
        variables: {
          changeMultiLegRouteAgreementStatusInput: {
            multiLegRouteAgreementId: id,
            rowVersion,
            status,
          },
        },
      });

      const mutationResult =
        data?.productPrice.multiLegRouteAgreement
          .changeMultiLegRouteAgreementStatus;
      if (mutationResult && "errors" in mutationResult) {
        setBannerState({ headerText: "Failed to change status" });
        const distinctErrorMessages = uniq(
          mutationResult.errors.map((error) => error.message)
        );
        setBannerResultWithTexts(distinctErrorMessages);
      } else {
        closePopover();
      }
    } catch (e) {
      setBannerState({ headerText: "Failed to change status" });
      setBannerResultWithErrors([e]);
    }
  };

  const onActivateAgreement = async () => {
    clearBannerResult();

    try {
      const { data } = await activateMultiLegAgreement({
        variables: {
          activateMultiLegAgreementInput: { rowVersion, id },
        },
      });

      const mutationResult =
        data?.productPrice.multiLegRouteAgreement.activateMultiLegAgreement;
      if (mutationResult && "errors" in mutationResult) {
        setBannerState({ headerText: "Failed to activate" });
        setBannerResultWithTexts(
          mutationResult.errors.map((error) => error.message)
        );
      } else {
        closePopover();
      }
    } catch (e) {
      setBannerState({ headerText: "Failed to activate" });
      setBannerResultWithErrors([e]);
    }
  };

  const onCopyMultiLegRouteAgreement = async (breakDate?: Date) => {
    clearBannerResult();

    if (!breakDate && breakDateIsRequired(multiLegAgreement)) {
      setModalState(ModalStates.BreakDateSelect);
      closePopover();
      return;
    }

    try {
      const { data } = await copyMultiLegRouteAgreement({
        variables: {
          copyMultiLegRouteAgreementInput: {
            headAgreementId,
            multiLegAgreementId: multiLegAgreement.id,
            breakDate: breakDateIsRequired(multiLegAgreement)
              ? {
                  isoString: formatDateString(ensureValidBreakDate(breakDate)),
                }
              : null,
            rowVersion: multiLegAgreement.rowVersion,
          },
        },
        refetchQueries: ["HeadAgreement"],
        awaitRefetchQueries: true,
      });

      const mutationResult =
        data?.productPrice.multiLegRouteAgreement.copyMultiLegRouteAgreement;
      if (mutationResult && "errors" in mutationResult) {
        setBannerState({ headerText: "Could not copy" });
        setBannerResultWithTexts(
          mutationResult.errors.map((error) => error.message)
        );
      } else {
        closePopover();
        closeModal();
      }
    } catch (e) {
      setBannerState({ headerText: "Could not copy" });
      setBannerResultWithErrors([e]);
    }
  };

  const loading = changeStatusLoading || activateLoading || copyLoading;

  const errorBanner = bannerState && (
    <ResultListBanner bannerState={bannerState} variant={"error"}>
      <Row justifyContent={"flex-end"}>
        <FlatButton
          label={"Close"}
          onClick={() => {
            clearBannerResult();
            closePopover();
          }}
        />
      </Row>
    </ResultListBanner>
  );

  return (
    <Row>
      {modalState === ModalStates.BreakDateSelect ? (
        <CopyMultiLegRouteAgreementModal
          onRequestClose={closeModal}
          onSubmit={onCopyMultiLegRouteAgreement}
          errorBanner={errorBanner}
          loading={copyLoading}
        />
      ) : modalState === ModalStates.ConfirmDelete ? (
        <ConfirmMultiLegRouteAgreementDeleteModal
          onRequestClose={closeModal}
          multiLegAgreement={multiLegAgreement}
          year={year}
          headAgreementId={headAgreementId}
        />
      ) : modalState === ModalStates.Update ? (
        <EditMultiLegAgreementModal
          onRequestClose={closeModal}
          multiLegAgreement={multiLegAgreement}
        />
      ) : modalState === ModalStates.NewRouteAgreement ? (
        <CreateMultiLegRouteAgreementModal
          onRequestClose={closeModal}
          multiLegAgreement={multiLegAgreement}
          isOpen
        />
      ) : modalState === ModalStates.CustomerAndHeadAgreementSelect ? (
        <SelectCustomerAndHeadAgreementMultiLegModal
          onRequestClose={closeModal}
          multiLegAgreement={multiLegAgreement}
        />
      ) : (
        ""
      )}
      <Popover
        animation={false}
        disablePadding
        placement={"left"}
        visible={popoverOpen}
        onClickOutside={() => {
          if (!errorBanner) {
            closePopover();
          }
        }}
        content={
          errorBanner ? (
            errorBanner
          ) : loading ? (
            <Spacing num={4} indent={4}>
              <Spinner />
            </Spacing>
          ) : (
            <ActionMenu>
              <ActionMenuItem
                leftIcon={stenaPen}
                label={"Edit ML agreement"}
                onClick={() => {
                  closePopover();
                  setModalState(ModalStates.Update);
                }}
              />
              <ActionMenuItem
                leftIcon={stenaCopy}
                label={"Copy ML agreement"}
                onClick={() => {
                  onCopyMultiLegRouteAgreement();
                }}
              />
              <ActionMenuItem
                leftIcon={stenaCopy}
                label={"Copy to other customer"}
                onClick={() => {
                  closePopover();
                  setModalState(ModalStates.CustomerAndHeadAgreementSelect);
                }}
              />
              <ActionMenuItem
                leftIcon={stenaPen}
                label={"New route agreement"}
                onClick={() => {
                  closePopover();
                  setModalState(ModalStates.NewRouteAgreement);
                }}
              />
              <ActionMenuSeparator />
              <ActionMenuItem
                leftIcon={stenaBell}
                disabled={
                  !canChangeRouteAgreementStatusToActive(
                    multiLegAgreement.headAgreement.statusCode,
                    multiLegAgreement.statusCode
                  )
                }
                label={"Set to active"}
                onClick={() => {
                  onActivateAgreement();
                }}
              />
              <ActionMenuItem
                leftIcon={stenaBell}
                disabled={
                  multiLegAgreement.valid.start?.isoString
                    ? !canChangeStatusToPending(
                        new Date(multiLegAgreement.valid.start.isoString),
                        multiLegAgreement.statusCode
                      )
                    : false
                }
                label={"Set to pending"}
                onClick={() => {
                  onChangeStatus(HeadAgreementStatusCode.Pending);
                }}
              />
              <ActionMenuItem
                leftIcon={stenaBell}
                disabled={
                  !canChangeStatusToVoid(
                    multiLegAgreement.statusCode,
                    multiLegAgreement.valid.start?.isoString ?? null
                  )
                }
                label={"Set to void"}
                onClick={() => {
                  onChangeStatus(HeadAgreementStatusCode.Void);
                }}
              />
              <ActionMenuItem
                leftIcon={stenaTrash}
                label={"Delete agreement"}
                onClick={() => {
                  closePopover();
                  setModalState(ModalStates.ConfirmDelete);
                }}
              />
            </ActionMenu>
          )
        }
      >
        <FlatButton leftIcon={stenaDots} onClick={openPopover} />
      </Popover>
    </Row>
  );
};
