import {
  BookingResultData,
  CustomerResultData,
  SearchEntityType,
} from "./types";

interface CustomerData {
  customerSearchResult: CustomerResultData | undefined;
  searchEntity: string | undefined;
}

interface BookingData {
  bookingSearchResult: BookingResultData | undefined;
  searchEntity: string | undefined;
}

export const customerDataExist = ({
  customerSearchResult,
  searchEntity,
}: CustomerData) => {
  return customerSearchResult && searchEntity === SearchEntityType.CUSTOMER;
};

export const bookingDataExist = ({
  bookingSearchResult,
  searchEntity,
}: BookingData) => {
  return (
    (bookingSearchResult &&
      bookingSearchResult.priceCalculatorData.length > 0 &&
      bookingSearchResult.bookingData &&
      searchEntity === SearchEntityType.BOOKING) ||
    (bookingSearchResult && bookingSearchResult.handledError)
  );
};
