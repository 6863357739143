import { Box, Row, Space, Txt } from "@stenajs-webui/core";
import { groupBy, sortBy } from "lodash";
import React, { useMemo, useRef, useState } from "react";

import { SpaceValues } from "@/common/utils/SpaceValues";
import { FetchPriceCalculatorFragment } from "@/gql/graphql";
import { StandardTable } from "@stenajs-webui/grid";
import { StandardTableHtmlCopyToClipboardButton } from "@stenajs-webui/grid-export";
import { Select, SelectProps } from "@stenajs-webui/select";
import { PaddedHeading } from "../../../../common/components/padded-heading/PaddedHeading";
import { transformSailingTypeCodeToSailingTypeName } from "../../../../common/transformers/SailingTypeTransformer";
import { PriceCalculatorFormData } from "../../types";
import { tableConfigHtmlExport } from "../config/PriceCalculatorHtmlRenderTableConfig";
import { PriceDetailTableRow } from "../config/PriceDetailTableRow";
import { tableConfig } from "../config/PriceDetailsTableConfig";
import {
  transformAncillaryCosts,
  transformPriceDetailTableRows,
  transformSurchargeCosts,
} from "../transformers";

interface Props extends SelectProps<any> {
  customerCalculatedState: PriceCalculatorFormData;
}
interface LegOption {
  label: string;
  value: string;
}
const transformQueryToPriceDetailTableRow = (
  priceCalcData: FetchPriceCalculatorFragment
) => {
  return [
    ...priceCalcData.ancillaryRecords.map<PriceDetailTableRow>(
      (record, index) => transformAncillaryCosts(record, priceCalcData, index)
    ),
    ...priceCalcData.surchargeRecords.map<PriceDetailTableRow>(
      (record, index) =>
        transformSurchargeCosts(
          record,
          priceCalcData,
          index + priceCalcData.ancillaryRecords.length
        )
    ),
  ];
};

export const PriceCalculatorMultiLegResultTab: React.FC<Props> = ({
  customerCalculatedState,
  ...selectProps
}) => {
  const headingH5HolderForCopy = useRef<HTMLDivElement>(null);
  const [selectedRouteCode, setSelectedRouteCode] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<LegOption>({
    label: "All legs",
    value: "",
  });

  const priceCalculatorData =
    customerCalculatedState.customerResultData?.priceCalculatorData;

  const options = useMemo(() => {
    const options = customerCalculatedState.multiLegRouteData?.routeLegs?.map(
      (leg) => ({
        label: leg.legRoute.code,
        value: leg.id,
      })
    );

    const result = [
      {
        label: "All legs",
        value: "",
      } as LegOption,
    ];
    if (options) {
      result.push(...options);
    }

    return result;
  }, [customerCalculatedState.multiLegRouteData]);

  const sailingTypeDepartureTime =
    customerCalculatedState.routeLegs &&
    customerCalculatedState.routeLegs[0].departureTime?.slice(0, 2) +
      ":" +
      customerCalculatedState.routeLegs[0].departureTime?.slice(2, 4);

  const manualDepartureTime =
    customerCalculatedState.routeLegs &&
    customerCalculatedState.routeLegs[0].departureTime;

  const departureTime =
    customerCalculatedState.routeLegs &&
    customerCalculatedState.routeLegs[0].departureTime.includes(":")
      ? manualDepartureTime
      : sailingTypeDepartureTime;

  const heading = () => {
    let headingString = customerCalculatedState.multiLegRouteData?.name;
    if (
      customerCalculatedState.routeLegs &&
      customerCalculatedState.routeLegs?.length > 0
    ) {
      headingString += `, ${
        customerCalculatedState.routeLegs[0].departureDate
      }, ${departureTime}, ${transformSailingTypeCodeToSailingTypeName(
        customerCalculatedState.routeLegs[0].sailingTypeCode
      )}`;
    }
    return headingString;
  };

  const renderCopy = (html: string): string | null => {
    if (customerCalculatedState.vehicleType) {
      return `<b>${heading()}</b>
      <br>
      <br>
       <b>${vehicleInformation}</b>
      ${headingH5HolderForCopy.current?.innerHTML}
      ${html}`;
    }

    return `<b>${heading()}</b>
    <br>
    <br>
    ${headingH5HolderForCopy.current?.innerHTML}
    ${html}`;
  };
  const vehicleInformation = `${customerCalculatedState.vehicleShortDescription}, ${customerCalculatedState.vehicleLength}m`;

  let tableRowItems: PriceDetailTableRow[] = [];
  const isSelectedRouteAll = selectedRouteCode === "";

  if (
    isSelectedRouteAll &&
    priceCalculatorData &&
    priceCalculatorData.length > 0
  ) {
    const priceDetailTableRows = priceCalculatorData.map((priceCalc) =>
      transformQueryToPriceDetailTableRow(priceCalc)
    );

    const priceDetailTableRowsFlattened = priceDetailTableRows.flat();

    const reducedPriceRow = priceCalculatorData.reduce((acc, curr) => ({
      ...acc,
      seaFreightPrice: acc.seaFreightPrice + curr.seaFreightPrice,
      totalPrice: acc.totalPrice + curr.totalPrice,
      totalVat: acc.totalVat + curr.totalVat,
    }));

    const grouped = groupBy(priceDetailTableRowsFlattened, "code");

    const reduceTableRows = sortBy(
      Object.keys(grouped).map((articleCode) => {
        return grouped[articleCode].reduce((acc, curr) => ({
          ...acc,
          priceExclVat: acc.priceExclVat + curr.priceExclVat,
          priceInclVat: acc.priceInclVat + curr.priceInclVat,
        }));
      }),
      ["code"]
    );

    tableRowItems = transformPriceDetailTableRows(
      reducedPriceRow,
      reduceTableRows
    );
  } else if (priceCalculatorData && priceCalculatorData[0]) {
    const selectedRouteLeg =
      customerCalculatedState.multiLegRouteData?.routeLegs?.find(
        (leg) => leg.id === selectedRouteCode
      );

    const selectedPriceCalculatorData = priceCalculatorData.find((data) => {
      return data.route.id === selectedRouteLeg?.legRoute.code;
    });

    const priceDetailTableRows = transformQueryToPriceDetailTableRow(
      selectedPriceCalculatorData ?? priceCalculatorData[0]
    );

    tableRowItems = selectedRouteLeg
      ? transformPriceDetailTableRows(
          selectedPriceCalculatorData ?? priceCalculatorData[0],
          priceDetailTableRows
        )
      : [];
  }

  return (
    <>
      <Box spacing={3} indent={3} style={{ paddingBottom: "8px" }}>
        <Row justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <PaddedHeading variant={"h4"} padding={0}>
              {heading()}
            </PaddedHeading>
          </Box>

          {isSelectedRouteAll && (
            <StandardTableHtmlCopyToClipboardButton
              config={tableConfigHtmlExport}
              items={tableRowItems.filter((tr) => tr.priceExclVat > 0)}
              renderContent={renderCopy}
              size={"medium"}
            />
          )}
        </Row>
      </Box>
      <Box indent={3} spacing={2} style={{ paddingTop: "0px" }}>
        <Txt size={"large"}>{vehicleInformation}</Txt>
      </Box>
      <Row
        indent={3}
        spacing={1}
        alignItems={"center"}
        style={{ paddingTop: "0px" }}
        justifyContent={"space-between"}
      >
        <Box ref={headingH5HolderForCopy}>
          <PaddedHeading variant={"h5"} padding={0}>
            Articles specification
          </PaddedHeading>
        </Box>
        <Box width={200}>
          <Select
            options={options}
            value={selectedOption}
            onChange={(value) => {
              const option = value as LegOption;
              setSelectedRouteCode(option.value);
              setSelectedOption(option);
            }}
            menuPortalTarget={document.body}
            {...selectProps}
          />
        </Box>
      </Row>
      <StandardTable config={tableConfig} items={tableRowItems} />
      <Space num={SpaceValues.SIXTEEN} />
    </>
  );
};
