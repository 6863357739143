import React from "react";
import { Window, WindowFooter } from "@stenajs-webui/modal";
import { Box, Indent, Row, Space, Text } from "@stenajs-webui/core";
import StenaLineLogo from "@/StenaLineLogo.svg";
import { IndentValues } from "@/common/utils/IndentValues";

interface Props {
  frontendVersion: string;
  backendVersion: string;
  user: string;
  visible: boolean;
  onClose: () => void;
}

export const AboutFreightPricingPortalModal: React.FC<Props> = ({
  frontendVersion,
  backendVersion,
  user,
  visible,
  onClose,
}) => {
  return (
    <Window
      style={{
        overlay: {
          justifyContent: "center",
        },
      }}
      headerText={"Freight Pricing Portal"}
      width={"400px"}
      isOpen={visible}
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={true}
      disableStickyFooter
      footer={
        <WindowFooter>
          <Row justifyContent={"end"} alignItems={"end"}>
            <Box
              height={"16px"}
              width={"24px"}
              backgroundImage={`url(${StenaLineLogo})`}
              backgroundRepeat={"no-repeat"}
            />
            <Space />
          </Row>
        </WindowFooter>
      }
    >
      <Indent num={IndentValues.SIXTEEN}>
        <Box spacing={1} gap={1}>
          <Text>Frontend version: {frontendVersion}</Text>
          <Text>Backend version: {backendVersion}</Text>
          <Text>User: {user}</Text>
        </Box>
      </Indent>
    </Window>
  );
};
