import { Box, Row, Spacing, useBoolean } from "@stenajs-webui/core";
import {
  ActionMenu,
  ActionMenuItem,
  ActionMenuSeparator,
  FlatButton,
  ResultListBanner,
  Spinner,
  stenaBell,
  stenaCopyDocument,
  stenaDots,
  stenaTrash,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import { LoadingModal } from "@stenajs-webui/modal";
import { Popover } from "@stenajs-webui/tooltip";
import * as React from "react";
import { useState } from "react";
import { RouteAgreement } from "../../../../../customers/customer-details/types";
import {
  canChangeRouteAgreementStatusToActive,
  canChangeStatusToPending,
  canChangeStatusToVoid,
} from "../util/AgreementStatusUtil";
import { breakDateIsRequired } from "../util/BreakDateRequirementChecker";
import { ensureValidBreakDate } from "../util/BreakDateValidator";
import { ModalStates } from "../util/ModalStatesEnum";
import { ConfirmRouteAgreementDeleteModal } from "./ConfirmRouteAgreementDeleteModal";
import { SelectCustomerAndHeadAgreementModal } from "./copy-to-other-customer/SelectCustomerAndHeadAgreementModal";
import { CopyRouteAgreementModal } from "./CopyRouteAgreementModal";
import { useActivateRouteAgreementMutation } from "./UseActivateRouteAgreement";
import { useChangeRouteAgreementStatusMutation } from "./UseChangeRouteAgreementStatusMutation";
import { useCopyRouteAgreementMutation } from "./UseCopyRouteAgreementMutation";
import { uniq } from "lodash";
import { formatDateString } from "@/common/dates/formatters";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { DateRange, HeadAgreementStatusCode } from "@/gql/graphql";

interface Props {
  routeAgreement: RouteAgreement;
  year: number;
  headAgreementValidDate?: DateRange;
}

export const RouteAgreementActionButtons: React.FC<Props> = ({
  routeAgreement,
  year,
  headAgreementValidDate,
}) => {
  const [popoverOpen, openPopover, closePopover] = useBoolean(false);
  const [breakDateChecked, setBreakeDateChecked] = useState<boolean>(true);

  const {
    headAgreement: { id: headAgreementId },
    id: routeAgreementId,
    rowVersion,
  } = routeAgreement;
  const [modalState, setModalState] = useState<ModalStates>(ModalStates.Closed);

  const {
    bannerState,
    clearBannerResult,
    setBannerResultWithTexts,
    setBannerResultWithErrors,
    setBannerState,
  } = useResultListBannerState(undefined);

  const closeModal = () => {
    setModalState(ModalStates.Closed);
    clearBannerResult();
  };

  const [copyRouteAgreement, { loading: copyLoading }] =
    useCopyRouteAgreementMutation();
  const [changeRouteAgreementStatus, { loading: changeStatusLoading }] =
    useChangeRouteAgreementStatusMutation();
  const [activateRouteAgreement, { loading: activateLoading }] =
    useActivateRouteAgreementMutation();

  const loading = copyLoading || changeStatusLoading || activateLoading;

  const onCopyRouteAgreement = async (breakDate?: Date) => {
    clearBannerResult();

    if (!breakDate && breakDateIsRequired(routeAgreement)) {
      setModalState(ModalStates.BreakDateSelect);
      closePopover();
      return;
    }

    try {
      const { data } = await copyRouteAgreement({
        variables: {
          input: {
            headAgreementId,
            routeAgreementId,
            breakDate:
              breakDateChecked && breakDateIsRequired(routeAgreement)
                ? {
                    isoString: formatDateString(
                      ensureValidBreakDate(breakDate)
                    ),
                  }
                : null,
            rowVersion: routeAgreement.rowVersion,
          },
        },
        refetchQueries: ["HeadAgreement"],
        awaitRefetchQueries: true,
      });

      const mutationResult =
        data?.productPrice.routeAgreement.copyRouteAgreement;
      if (mutationResult && "errors" in mutationResult) {
        setBannerState({ headerText: "Could not copy" });
        const distinctErrorMessages = uniq(
          mutationResult.errors.map((error) => error.message)
        );
        setBannerResultWithTexts(distinctErrorMessages);
      } else {
        closePopover();
        closeModal();
        setBreakeDateChecked(true);
      }
    } catch (e) {
      setBannerState({ headerText: "Could not copy" });
      setBannerResultWithErrors([e]);
    }
  };

  const onActivateAgreement = async () => {
    clearBannerResult();

    try {
      const { data } = await activateRouteAgreement({
        variables: {
          routeAgreement: { id: routeAgreementId, rowVersion },
        },
      });

      const mutationResult =
        data?.productPrice.routeAgreement.activateRouteAgreement;
      if (mutationResult && "errors" in mutationResult) {
        setBannerState({ headerText: "Failed to activate" });
        setBannerResultWithTexts(
          mutationResult.errors.map((error) => error.message)
        );
      } else {
        closePopover();
      }
    } catch (e) {
      setBannerState({ headerText: "Failed to activate" });
      setBannerResultWithErrors([e]);
    }
  };

  const onChangeStatus = async (status: HeadAgreementStatusCode) => {
    try {
      const { data } = await changeRouteAgreementStatus({
        variables: { routeAgreementId, rowVersion, status },
      });

      const mutationResult =
        data?.productPrice.routeAgreement.changeRouteAgreementStatus;
      if (mutationResult && "errors" in mutationResult) {
        setBannerState({ headerText: "Failed to change status" });
        setBannerResultWithTexts(
          mutationResult.errors.map((error) => error.message)
        );
      } else {
        closePopover();
      }
    } catch (e) {
      setBannerState({ headerText: "Failed to change status" });
      setBannerResultWithErrors([e]);
    }
  };

  const errorBanner = bannerState ? (
    <ResultListBanner bannerState={bannerState} variant={"error"}>
      <Row justifyContent={"flex-end"}>
        <FlatButton
          label={"Close"}
          onClick={() => {
            clearBannerResult();
            closePopover();
          }}
        />
      </Row>
    </ResultListBanner>
  ) : null;

  return (
    <Row>
      {modalState === ModalStates.BreakDateSelect ? (
        <CopyRouteAgreementModal
          onRequestClose={closeModal}
          onSubmit={onCopyRouteAgreement}
          setBreakeDateChecked={setBreakeDateChecked}
          errorBanner={errorBanner}
          loading={copyLoading}
          breakDateChecked={breakDateChecked}
          headAgreementValidDate={headAgreementValidDate}
        />
      ) : modalState !== ModalStates.Closed && loading ? (
        <LoadingModal
          style={{
            overlay: { zIndex: ZIndex.highest },
          }}
        />
      ) : modalState === ModalStates.ConfirmDelete ? (
        <ConfirmRouteAgreementDeleteModal
          onRequestClose={closeModal}
          routeAgreement={routeAgreement}
          year={year}
          headAgreementId={headAgreementId}
        />
      ) : modalState === ModalStates.CustomerAndHeadAgreementSelect ? (
        <SelectCustomerAndHeadAgreementModal
          onRequestClose={closeModal}
          routeAgreement={routeAgreement}
        />
      ) : null}
      <Popover
        animation={false}
        disablePadding
        placement={"left"}
        appendTo={document.body}
        visible={popoverOpen}
        onClickOutside={() => {
          if (!errorBanner) {
            closePopover();
          }
        }}
        content={
          errorBanner ? (
            errorBanner
          ) : loading ? (
            <Spacing num={4} indent={4}>
              <Spinner />
            </Spacing>
          ) : (
            <ActionMenu>
              <ActionMenuItem
                leftIcon={stenaCopyDocument}
                label={"Copy agreement"}
                onClick={() => {
                  onCopyRouteAgreement();
                }}
              />
              <ActionMenuItem
                leftIcon={stenaCopyDocument}
                label={"Copy to other customer"}
                onClick={() => {
                  closePopover();
                  setModalState(ModalStates.CustomerAndHeadAgreementSelect);
                }}
              />
              <ActionMenuSeparator />
              <ActionMenuItem
                leftIcon={stenaBell}
                disabled={
                  !canChangeRouteAgreementStatusToActive(
                    routeAgreement.headAgreement.statusCode,
                    routeAgreement.statusCode
                  )
                }
                label={"Set to active"}
                onClick={() => {
                  onActivateAgreement();
                }}
              />
              <ActionMenuItem
                leftIcon={stenaBell}
                disabled={
                  routeAgreement.valid.start?.isoString
                    ? !canChangeStatusToPending(
                        new Date(routeAgreement.valid.start.isoString),
                        routeAgreement.statusCode
                      )
                    : false
                }
                label={"Set to pending"}
                onClick={() => {
                  onChangeStatus(HeadAgreementStatusCode.Pending);
                }}
              />
              <ActionMenuItem
                leftIcon={stenaBell}
                disabled={
                  !canChangeStatusToVoid(
                    routeAgreement.statusCode,
                    routeAgreement.valid.start?.isoString ?? null
                  )
                }
                label={"Set to void"}
                onClick={() => {
                  onChangeStatus(HeadAgreementStatusCode.Void);
                }}
              />
              <Box>
                <ActionMenuItem
                  leftIcon={stenaTrash}
                  label={"Delete agreement"}
                  onClick={() => {
                    closePopover();
                    setModalState(ModalStates.ConfirmDelete);
                  }}
                />
              </Box>
            </ActionMenu>
          )
        }
      >
        <FlatButton leftIcon={stenaDots} onClick={openPopover} />
      </Popover>
    </Row>
  );
};
