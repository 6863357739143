import { FormModelChangeHandler } from "@/common/forms/types";
import {
  PriceCalculationMultiLegResultQuery,
  PriceCalculationMultiLegResultQueryVariables,
} from "@/gql/graphql";
import { gql, useLazyQuery } from "@apollo/client";
import { PriceCalculatorFormData } from "../types";
import { fetchPriceCalculatorFragment } from "./fragments";
import { transformToCustomerMultiLegResultData } from "./transformers";

export const useMultiLegPriceCalculation = (
  formData?: PriceCalculatorFormData,
  onChangeFormData?: FormModelChangeHandler<PriceCalculatorFormData>,
  setCustomerCalculatedState?: React.Dispatch<
    React.SetStateAction<PriceCalculatorFormData>
  >
) => {
  const multiLegPriceCalculationQuery = gql`
    ${fetchPriceCalculatorFragment}
    query PriceCalculationMultiLegResult(
      $input: PriceCalculatorInput!
      $usePricingV2: Boolean!
    ) {
      priceCalculator {
        byMultiLegInput(variables: $input, usePricingV2: $usePricingV2) {
          __typename
          ... on PriceCalculatorError {
            error
            errorType
          }
          ... on PriceCalculatorMultiLeg {
            list {
              ...FetchPriceCalculator
            }
          }
        }
      }
    }
  `;
  const [calculateMultiLegPrice, { loading, data, error }] = useLazyQuery<
    PriceCalculationMultiLegResultQuery,
    PriceCalculationMultiLegResultQueryVariables
  >(multiLegPriceCalculationQuery, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (onChangeFormData && formData && setCustomerCalculatedState) {
        setCustomerCalculatedState({
          ...formData,
          customerResultData: transformToCustomerMultiLegResultData(
            data,
            formData
          ),
        });
        return onChangeFormData(
          "customerResultData",
          transformToCustomerMultiLegResultData(data, formData)
        );
      }
    },
  });

  return {
    calculateMultiLegPrice,
    loading,
    data,
    error,
  };
};
