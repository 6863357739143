import {
  PriceCalculatorFormData,
  RouteLeg,
} from "@/features/price-calculator-v2/types";
import { DateTextInput } from "@stenajs-webui/calendar";
import { Box, Text } from "@stenajs-webui/core";
import { TableCell, TableRow } from "@stenajs-webui/grid";
import * as React from "react";
import { useEffect } from "react";
import { FormModelChangeHandler } from "../../../../../common/forms/types";
import { useSailingsByRouteCodeDepartureDate } from "../common/hooks/UseSailingsByRouteCodeDepartureDate";
import { PriceCalculatorDepartureCategorySelect } from "../common/PriceCalculatorDepartureCategorySelect";
import { PriceCalculatorDepartureTimeInput } from "../common/PriceCalculatorDepartureTimeInput";
import {
  enterManuallyDefaultOption,
  SailingCategoryTimeTableSelect,
} from "../common/PriceCalculatorTimeTableSelect";

interface Props {
  routeLeg: RouteLeg;
  index: number;
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  setState: React.Dispatch<React.SetStateAction<PriceCalculatorFormData>>;
  defaultFormState: PriceCalculatorFormData;
}

export const PriceCalculatorLegRow: React.FC<Props> = ({
  routeLeg,
  index,
  formData,
  onChangeFormData,
  setState,
  defaultFormState,
}) => {
  const { sailings, sailingDeparturesLoading, fetchSailings } =
    useSailingsByRouteCodeDepartureDate();

  const mergedRouteLeg = formData.routeLegs ?? [];

  useEffect(() => {
    if (
      formData.routeCode &&
      routeLeg.routeCode &&
      routeLeg.departureDate &&
      routeLeg.departureDate.length > 9 &&
      isNaN(new Date(routeLeg.departureDate).getUTCFullYear()) !== true
    ) {
      setState((formData) => ({
        ...formData,
        routeLegs: formData.routeLegs?.map((routeLeg) => {
          return routeLeg.routeCode === mergedRouteLeg[index].routeCode
            ? {
                ...routeLeg,
                sailingTypeCode: defaultFormState.sailingType ?? "",
                departureTime: defaultFormState.departureTime ?? "",
                departureTimeManual: true,
              }
            : routeLeg;
        }),
      }));
      fetchSailings({
        variables: {
          routeCode: routeLeg.routeCode,
          departureDate: routeLeg.departureDate,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.routeCode,
    routeLeg.routeCode,
    routeLeg.departureDate,
    fetchSailings,
  ]);

  return (
    <TableRow key={routeLeg.routeCode} alignItems={"center"}>
      <TableCell justifyContent={"flex-start"} width={56}>
        <Text size={"medium"}>{index + 1}</Text>
      </TableCell>
      <TableCell width={64}>
        <Text size={"medium"}>{routeLeg.routeCode}</Text>
      </TableCell>
      <TableCell width={150}>
        <DateTextInput
          value={routeLeg.departureDate}
          onValueChange={(value) => {
            const routeLegs = formData.routeLegs ?? [];
            routeLegs[index].departureDate = value;
            onChangeFormData("routeLegs", routeLegs);
          }}
          portalTarget={document.body}
          calendarProps={{
            highlightToday: true,
          }}
        />
      </TableCell>
      <TableCell width={190}>
        <Box width={"100%"}>
          <SailingCategoryTimeTableSelect
            value={
              mergedRouteLeg[index].departureTimeManual
                ? enterManuallyDefaultOption.value
                : routeLeg.sailingTypeCode
            }
            onValueChange={(selectedCategory) => {
              const enterTimeManually =
                selectedCategory.value === enterManuallyDefaultOption.value;
              mergedRouteLeg[index].departureTimeManual = enterTimeManually;
              mergedRouteLeg[index].sailingTypeCode =
                defaultFormState.sailingType!;
              mergedRouteLeg[index].departureTime =
                defaultFormState.departureTime!;
              onChangeFormData("routeLegs", mergedRouteLeg);
              if (!mergedRouteLeg[index].departureTimeManual) {
                mergedRouteLeg[index].sailingTypeCode =
                  selectedCategory.value?.charAt(0) ?? "";
                mergedRouteLeg[index].departureTime =
                  selectedCategory.departureTime ??
                  defaultFormState.departureTime!;
                onChangeFormData("routeLegs", mergedRouteLeg);
              }
            }}
            loading={sailingDeparturesLoading}
            sailings={sailings}
          />
        </Box>
      </TableCell>
      <TableCell width={170}>
        <Box width={170}>
          <PriceCalculatorDepartureCategorySelect
            value={routeLeg.sailingTypeCode}
            manualDeparture={mergedRouteLeg[index].departureTimeManual!}
            onValueChange={(sailingCategory) => {
              const routeLegs = formData.routeLegs!;
              routeLegs[index].sailingTypeCode = sailingCategory.value!;
              onChangeFormData("routeLegs", routeLegs);
            }}
            disabled={!mergedRouteLeg[index].departureTimeManual}
          />
        </Box>
      </TableCell>
      <TableCell width={140}>
        <PriceCalculatorDepartureTimeInput
          value={
            !mergedRouteLeg[index].departureTimeManual
              ? ""
              : routeLeg.departureTime
          }
          onChange={(value) => {
            const mergedRouteLeg = formData.routeLegs!;
            mergedRouteLeg[index].departureTime = value;
            onChangeFormData("routeLegs", mergedRouteLeg);
          }}
          disabled={!mergedRouteLeg[index].departureTimeManual}
        />
      </TableCell>
    </TableRow>
  );
};
