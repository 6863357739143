import {
  FetchBookingFragment,
  FetchPriceCalculatorFragment,
  PriceCalculatorErrorType,
} from "@/gql/graphql";
import { MultiLegOptionData } from "./price-calculator-forms/customer/multi-leg/PriceCalculatorSelectMultiLegRoute";

export interface PriceCalculatorFormData {
  moreOptionsPanelCollapsed?: boolean;
  searchEntityType?: SearchEntityType;
  selectedCustomerNum?: number;
  movementType: MovementType;
  sectionalDepartureTimeManual?: boolean;
  bookingNumber?: number;
  version?: number;
  multiLegRouteData?: MultiLegOptionData;
  routeLegs?: Array<RouteLeg>;
  routeCode?: string;
  sailingDate?: string;
  sailingType?: string;
  departureTime?: string;
  vehicleType?: string;
  vehicleLength?: string;
  vehicleWidth?: string;
  vehicleWeight?: string;
  vehiclePlugins?: number;
  vehicleTradeUnits?: number;
  passengersAdults?: number;
  passengersChildren?: number;
  passengersInfants?: number;
  passengersSleepers?: number;
  drivers?: number;
  loadingPreferenceEnabled?: boolean;
  loadingPreference?: string;
  cargoHazardousGoods?: boolean;
  cargoHazardousGoodsRowWeight?: string;
  cargoHazardousGoodsAmountRows?: number;
  cargoHazardousGoodsQualified?: boolean;
  livestock?: boolean;
  livestockType?: string;
  vehicleShortDescription?: string;
  conditionsStandby?: boolean;
  conditionsTransit?: boolean;
  conditionsReceiverCountryCode?: string;
  conditionsSenderCountryCode?: string;
  conditionsGoods?: Goods[];
  conditionsAncillary?: Ancillary[];
  vehicleTradeWeight?: string;
  customsClassificationCode?: string;
  includePendingOffered?: boolean;
  bookingResultData?: BookingResultData;
  customerResultData?: CustomerResultData;
}

export interface RouteLeg {
  routeCode: string;
  sailingTypeCode: string;
  departureDate: string;
  departureTime: string;
  departureTimeManual?: boolean;
}

export interface Goods {
  goodsCode: string;
  weight: string;
}

export interface Ancillary {
  ancillaryCode: string;
  quantity: number;
}

export enum SearchEntityType {
  CUSTOMER = "CUSTOMER",
  BOOKING = "BOOKING",
}

export enum MovementType {
  SECTIONAL = "SECTIONAL",
  MULTILEG = "MULTILEG",
}

export enum SelectedTab {
  PRICE,
  AUDIT,
}

export const MovementTypeLabels = new Map<string, string>([
  [MovementType.SECTIONAL, "Sectional"],
  [MovementType.MULTILEG, "Multi-leg"],
]);

export interface CustomerOrBookingSelect {
  label: string;
  value: SearchEntityType;
}

export interface PriceCalculatorHandledError {
  error: string;
  errorType: PriceCalculatorErrorType;
}
export interface CustomerResultData {
  priceCalculatorData: FetchPriceCalculatorFragment[];
  handledError?: PriceCalculatorHandledError;
}

export interface BookingResultData {
  bookingData: FetchBookingFragment[];
  priceCalculatorData: FetchPriceCalculatorFragment[];
  handledError?: PriceCalculatorHandledError;
}

export interface AuditData {
  errorMessage: string;
  auditLogs: Array<string>;
  routeCode?: string;
  departureDate: string;
  sailingCategory?: string;
}
