import { usePrompt } from "@/common/hooks/usePrompt";
import { Space } from "@stenajs-webui/core";
import {
  BreadCrumbs,
  PrimaryButton,
  ResultListBanner,
  ResultListBannerState,
  SecondaryButton,
  stenaCalculate,
} from "@stenajs-webui/elements";
import { isEqual, uniq } from "lodash";
import * as React from "react";
import { useMemo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { routeFactory } from "../../../../../RouteFactory";
import { Crumb } from "../../../../../common/components/bread-crumbs/Crumb";
import { LoadingModalWithLock } from "../../../../../common/components/loading-modals/LoadingModalWithLock";
import { PageHeader } from "../../../../../common/components/page-header/PageHeader";
import { groupRouteAgreementsById } from "../helpers/helpers";
import { agreementArticlesActions } from "../redux/reducer";
import { agreementArticlesSelectors } from "../redux/selectors";
import {
  agreementArticlesHasChanges,
  transformAgreementArticleChangesToInput,
} from "../transformers/ModifiedToInputTransformer";
import { transformUpdateResultToAgreementRouteArticlePriceTableItem } from "../transformers/transformers";
import { AgreementArticlesCustomer } from "../types";
import { isBeforeTomorrow } from "./bulk-update/validators/validators";
import { useUpdateAgreementRouteArticlePriceMutation } from "./hooks/UseUpdateAgreementRouteArticlePriceMutation";
import {
  CreateAgreementArticlePriceItemInput,
  UpdateAgreementArticlesItemInput,
} from "@/gql/graphql";

interface Props {
  headAgreementId: string;
  customer: AgreementArticlesCustomer;
}

export const AgreementArticlesHeader: React.FC<Props> = ({
  headAgreementId,
  customer,
}) => {
  const dispatch = useDispatch();

  const tableItems = useSelector(
    agreementArticlesSelectors.getAgreementArticlesTableItems
  );

  const saveChangesLoading = useSelector(
    agreementArticlesSelectors.getSaveChangesLoading
  );
  const filteredRouteAgreements = useSelector(
    agreementArticlesSelectors.getRouteAgreements
  );

  const persistedItems = tableItems.map((item) => item.persisted);
  const editableItems = tableItems.map((item) => item.editable);
  const hasNoChanges = isEqual(persistedItems, editableItems);
  const invalidInput = (created: CreateAgreementArticlePriceItemInput) => {
    return (
      created.changes.price === null ||
      created.changes.validFrom === null ||
      (created.changes.validFrom?.value &&
        created.changes.validFrom?.value.length < 10) ||
      (created.changes.validFrom?.value &&
        isBeforeTomorrow(created.changes.validFrom?.value))
    );
  };

  usePrompt({
    message: "You have unsaved changes, would you like to leave the page?",
    shouldBlock: !hasNoChanges,
  });

  const saveErrors = useSelector(agreementArticlesSelectors.getSaveErrors);
  const bannerState = useMemo<ResultListBannerState | undefined>(() => {
    if (saveErrors && saveErrors.length) {
      const distinctErrorMessages = uniq(
        saveErrors.map((error) => error.message)
      );

      return {
        headerText: "Unable to save",
        items: uniq(
          distinctErrorMessages.map((e) => ({
            text: e,
          }))
        ),
      };
    }
  }, [saveErrors]);

  const { updateAgreementRouteArticlePrice } =
    useUpdateAgreementRouteArticlePriceMutation();

  const submitHandler = async () => {
    batch(() => {
      dispatch(agreementArticlesActions.clearSaveErrors());
      dispatch(agreementArticlesActions.clearAllTableRowErrors());
      dispatch(agreementArticlesActions.setSaveChangesLoading(true));
    });
    try {
      const routeAgreementArticles = filteredRouteAgreements?.flatMap(
        (agreement) => {
          const agreementArticleTableRowState = tableItems;

          if (!agreementArticleTableRowState) {
            throw new Error("Missing information.");
          }

          const agreementArticles = agreement.articles.flatMap((article) => {
            const agreementArticlePrices = article.prices
              .map<UpdateAgreementArticlesItemInput>((price) => {
                const changes = transformAgreementArticleChangesToInput(
                  agreementArticleTableRowState,
                  article.id,
                  price.id,
                  agreement.id
                ).created;

                return {
                  agrRouteId: agreement.id,
                  rowVersion: agreement.rowVersion,
                  changes: {
                    created: changes,
                    deleted: [],
                    updated: [],
                  },
                };
              })
              .filter((articles) => agreementArticlesHasChanges(articles));
            return agreementArticlePrices;
          });
          return agreementArticles;
        }
      );

      const routeAgreements =
        groupRouteAgreementsById(routeAgreementArticles) ?? [];

      const tableRowErrors = routeAgreements.flatMap((ra) =>
        ra.changes.created.map((c) => c).filter((c) => invalidInput(c))
      );

      const inputIsInvalid = routeAgreements.flatMap((ra) =>
        ra.changes.created.map((c) => {
          if (invalidInput(c)) {
            return true;
          }
          return false;
        })
      );

      if (inputIsInvalid.includes(true)) {
        batch(() => {
          dispatch(
            agreementArticlesActions.setSaveError({
              name: "",
              message:
                "One or more rows are missing New price or New valid from date. Please try again.",
            })
          );
          dispatch(agreementArticlesActions.setTableRowErrors(tableRowErrors));
        });
        return;
      }

      const { data } = await updateAgreementRouteArticlePrice({
        routeAgreements,
      });

      const errors =
        data?.productPrice.routeAgreement.updateAgreementArticles.flatMap(
          (sheet) => {
            if (sheet.__typename === "ErrorResult") {
              return sheet.errors;
            } else {
              return [];
            }
          }
        );

      if (errors && errors.length > 0) {
        dispatch(agreementArticlesActions.setSaveChangesLoading(false));
        dispatch(
          agreementArticlesActions.setSaveErrors(
            errors.map((e) => new Error(e.message))
          )
        );
      }

      const updatedAgreementArticles =
        data?.productPrice.routeAgreement.updateAgreementArticles.flatMap(
          (sheet) => {
            if (sheet.__typename === "UpdateAgreementArticlesSuccessResult") {
              return sheet.routeAgreement;
            } else {
              return [];
            }
          }
        ) ?? [];

      if (updatedAgreementArticles.length > 0) {
        const bulkEditRatesPricesTableRow = updatedAgreementArticles?.flatMap(
          (routeAgreement) => {
            return routeAgreement.articles
              .filter((article) => article.articleType.updatable === true)
              .flatMap((article) => {
                return article.prices.map((articlePrice) =>
                  transformUpdateResultToAgreementRouteArticlePriceTableItem(
                    customer?.invoiceCurrency.code ?? "",
                    routeAgreement,
                    routeAgreement.routes,
                    article,
                    articlePrice,
                    article.validities
                  )
                );
              });
          }
        );
        dispatch(
          agreementArticlesActions.populateTableWithUpdatedData(
            bulkEditRatesPricesTableRow
          )
        );
      }
    } catch (e) {
      dispatch(agreementArticlesActions.setSaveError(e));
    } finally {
      dispatch(agreementArticlesActions.setSaveChangesLoading(false));
    }
  };

  return (
    <>
      <PageHeader
        title={"Agreement articles"}
        sticky
        offsetTop={0}
        contentUnder={
          bannerState ? (
            <ResultListBanner bannerState={bannerState} variant={"error"} />
          ) : undefined
        }
        icon={stenaCalculate}
        contentRight={
          <>
            <SecondaryButton
              label={"Discard"}
              disabled={hasNoChanges}
              onClick={() =>
                batch(() => {
                  dispatch(agreementArticlesActions.clearSaveErrors());
                  dispatch(agreementArticlesActions.clearAllTableRowErrors());
                  dispatch(agreementArticlesActions.revertAll());
                })
              }
            />
            <Space />
            <PrimaryButton
              label={"Save"}
              disabled={hasNoChanges}
              onClick={submitHandler}
            />
          </>
        }
        breadcrumbs={
          <BreadCrumbs>
            <Crumb
              label={"Negotiation plan"}
              path={routeFactory.productAndPrice.customer.customerList()}
            />
            <Crumb
              label={customer?.breadCrumbName ?? ""}
              path={routeFactory.productAndPrice.customer.customerHeadAgreement(
                {
                  customerId: customer?.id ?? "",
                  headAgreementId,
                }
              )}
            />
            <Crumb label={"Agreement articles"} isLast />
          </BreadCrumbs>
        }
      />
      {saveChangesLoading && (
        <LoadingModalWithLock label="Saving your changes. Thanks for your patience" />
      )}
    </>
  );
};
