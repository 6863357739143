import {
  CountryMultiChipSelectAllQuery,
  CountryMultiSelectAllActiveQuery,
} from "@/gql/graphql";
import { Select, SelectProps } from "@stenajs-webui/select";
import * as React from "react";
import { useMemo } from "react";

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string | null;
  onValueChange?: (value: string) => void;
  countries:
    | CountryMultiChipSelectAllQuery["productPrice"]["country"]["all"]
    | CountryMultiSelectAllActiveQuery["productPrice"]["country"]["allActive"];
}

export const CountrySelectUi: React.FC<Props> = ({
  onValueChange,
  value,
  countries,
  ...selectProps
}) => {
  const options = useMemo(
    () =>
      countries?.map((country) => ({
        label: country.name,
        value: country.id,
      })),
    [countries]
  );

  const selected = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  return (
    <Select
      options={options}
      value={selected || null}
      onChange={(v: any) => {
        if (!v) {
          onValueChange?.("");
        } else if (onValueChange && v) {
          onValueChange?.(v.value);
        }
      }}
      {...selectProps}
    />
  );
};
