import { SpaceValues } from "@/common/utils/SpaceValues";
import { StoreState } from "@/config/redux/RootReducer";
import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { createFppSearchFilterActions } from "@/features/search-filter/redux/reducer";
import { CustomerGetByFilterOptions } from "@/gql/graphql";
import { Space } from "@stenajs-webui/core";
import { Card, stenaBusinessAgreement } from "@stenajs-webui/elements";
import { SearchFilterContext } from "@stenajs-webui/filter";
import {
  StandardTable,
  StandardTableAction,
  TableContext,
} from "@stenajs-webui/grid";
import * as React from "react";
import { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "../../../../../common/components/page-header/PageHeader";
import { getGQLErrors } from "../../../../../common/string/GraphQlErrorParser";
import {
  CustomerListTableColumnKeys,
  customerListTableConfig,
} from "../config/CustomerListTableConfig";
import { useCustomerListQuery } from "../hooks/UseCustomerListQuery";
import { customerListStandardTableActions } from "../redux";
import { customerListSelectors } from "../selectors";
import { transformCustomerToTableCustomer } from "../transformers";
import { customerListFilterIsReadyForQuery } from "../util/FilterOptionsValidator";
import { CustomerListHeader } from "./CustomerListHeader";

interface Props {}

export const CustomerList: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const { workspaceState, committedState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const filter = committedState;

  const standardTableSelector = useCallback(
    (state: StoreState) => customerListSelectors.getTableState(state),
    []
  );
  const standardTableState = useSelector(standardTableSelector);

  const tableContext = useMemo<
    TableContext<CustomerListTableColumnKeys>
  >(() => {
    return {
      state: standardTableState,
      actions: customerListStandardTableActions,
      dispatch: (action: StandardTableAction<CustomerListTableColumnKeys>) => {
        dispatch(action);
      },
    };
  }, [dispatch, standardTableState]);

  const [sortOrder, setSortOrder] = useState(
    tableContext.state.sortOrder.sortBy
  );

  const selectedIds = tableContext.state.selectedIds.selectedIds;

  const config = useMemo(() => {
    return customerListTableConfig(committedState.negotiationYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    if (sortOrder !== tableContext.state.sortOrder.sortBy) {
      setSortOrder(tableContext.state.sortOrder.sortBy);
    }
  }, [tableContext.state.sortOrder.sortBy, sortOrder]);

  const filterOptions = useMemo<CustomerGetByFilterOptions>(() => {
    return {
      negotiationYear: filter.negotiationYear,
      sellerIds: filter.sellers?.map((s) => s.id) ?? [],
      negotiationStatusIds: filter.negotiationStatus.map((s) => s.id),
      salesRegionIds: filter.salesRegions?.map((s) => s.id) ?? [],
      types: filter.customerTypes.map((s) => s.id),
      includeCustomersWith: filter.includeCustomersWith?.map((s) => s.id),
      routePairIds: filter.routePairs?.map((s) => s.id) ?? [],
    } as CustomerGetByFilterOptions;
  }, [filter]);

  const canQuery = customerListFilterIsReadyForQuery(filterOptions);

  const { loading, error, result, refetch } = useCustomerListQuery(
    filterOptions,
    canQuery
  );

  const customersInQuery = useMemo(() => {
    return result?.items ?? [];
  }, [result]);

  const customers = useMemo(
    () => customersInQuery.map(transformCustomerToTableCustomer),
    [customersInQuery]
  );

  const noItemsLabel = canQuery
    ? "Found no matching customers. Please use filter to change the search."
    : "Please select negotiation year, and at least seller or region to search for customers.";

  return (
    <SearchFilterContext
      state={workspaceState}
      actions={createFppSearchFilterActions("customerList")}
      dispatch={dispatch}
    >
      <PageHeader icon={stenaBusinessAgreement} title={"Negotiation plan"} />
      <Card>
        <CustomerListHeader
          loading={loading}
          selectedIds={selectedIds}
          tableContext={tableContext}
          negotiationYear={filter.negotiationYear}
          refetch={refetch}
          numShowing={customers?.length ?? 0}
        />

        <StandardTable
          config={config}
          items={canQuery ? customers : []}
          noItemsLabel={noItemsLabel}
          tableContext={tableContext}
          loading={canQuery ? loading : false}
          error={canQuery ? getGQLErrors(error) : undefined}
        />
      </Card>
      <Space num={SpaceValues.TWENTYFOUR} />
    </SearchFilterContext>
  );
};
