import { FormModelChangeHandler } from "@/common/forms/types";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import {
  MovementType,
  PriceCalculatorFormData,
} from "@/features/price-calculator-v2/types";
import { Box } from "@stenajs-webui/core";
import { Collapsible } from "@stenajs-webui/panels";
import * as React from "react";
import { PriceCalculatorLoadingPreferenceRadioGroup } from "../sectional/PriceCalculatorLoadingPreferenceRadioGroup";
import { PriceCalculatorCargoGoodsDetails } from "./PriceCalculatorCargoGoodsDetails";
import { PriceCalculatorConditions } from "./PriceCalculatorConditions";
import { GapValues } from "@/common/utils/GapValues";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
  defaultFormState: PriceCalculatorFormData;
}

export const PriceCalculatorMoreOptionsCollapsible: React.FC<Props> = ({
  formData,
  onChangeFormData,
  defaultFormState,
}) => {
  return (
    <Collapsible
      label={"More options"}
      collapsed={formData.moreOptionsPanelCollapsed ?? false}
      onClick={() =>
        onChangeFormData(
          "moreOptionsPanelCollapsed",
          !formData.moreOptionsPanelCollapsed
        )
      }
    >
      <Box
        gap={GapValues.TWENTYFOUR}
        indent={IndentValues.SIXTEEN}
        spacing={SpacingValues.SIXTEEN}
      >
        {formData.movementType === MovementType.SECTIONAL && (
          <PriceCalculatorLoadingPreferenceRadioGroup
            formData={formData}
            onChangeFormData={onChangeFormData}
          />
        )}
        <PriceCalculatorCargoGoodsDetails
          formData={formData}
          onChangeFormData={onChangeFormData}
          defaultFormState={defaultFormState}
        />
        <PriceCalculatorConditions
          formData={formData}
          onChangeFormData={onChangeFormData}
        />
      </Box>
    </Collapsible>
  );
};
