import * as React from "react";
import { useMemo, useState } from "react";
import { Select, SelectProps } from "@stenajs-webui/select";
import { isEqual } from "lodash";
import { formatLongSailingType } from "@/common/formatters/SailingStatusCodeFormatter";
import { PriceCalcSailingsFragment, SailingTypeCode } from "@/gql/graphql";

const formatTimeWithColon = (time: string) =>
  `${time.slice(0, 2)}:${time.slice(2)}`;

const transformSailingDeparturesToOptions = (
  sailings?: PriceCalcSailingsFragment[]
) =>
  sailings?.map((sailing) => ({
    label:
      formatTimeWithColon(sailing.departureTime) +
      " - " +
      formatLongSailingType(sailing.sailingTypeCode as SailingTypeCode),
    value: sailing.sailingTypeCode,
    type: "departureTime",
    departureTime: sailing.departureTime,
  })) ?? [];

export interface SailingCategoryOption {
  label?: string;
  value?: string;
  type?: string;
  departureTime?: string;
}

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: SailingCategoryOption) => void;
  sailings?: PriceCalcSailingsFragment[];
  loading?: boolean;
}
export const enterManuallyDefaultOption = {
  label: "Enter manually",
  value: "Manual",
  type: "sailingCategory",
};
//FIXME: REMOVE file and folder
export const SailingCategoryTimeTableSelect: React.FC<Props> = ({
  onValueChange,
  value,
  sailings,
  loading,
  ...selectProps
}) => {
  const [selectedDeparture, setSelectedDeparture] =
    useState<SailingCategoryOption>(enterManuallyDefaultOption);

  const sailingCategories = useMemo(() => {
    return [
      enterManuallyDefaultOption,
      ...transformSailingDeparturesToOptions(sailings),
    ];
  }, [sailings]);

  const selected = useMemo(
    () => sailingCategories.find((o) => value && isEqual(o, selectedDeparture)),
    [sailingCategories, selectedDeparture, value]
  );

  return (
    <Select
      {...selectProps}
      value={selected || null}
      options={sailingCategories}
      isLoading={loading}
      menuPortalTarget={document.body}
      onChange={(item: SailingCategoryOption) => {
        setSelectedDeparture(item);
        onValueChange && onValueChange(item);
      }}
    />
  );
};
