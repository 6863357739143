import React, { useCallback, useMemo, useRef, useState } from "react";
import { Box, Row, Spacing } from "@stenajs-webui/core";
import { groupBy, sortBy } from "lodash";
import { StandardTableHtmlCopyToClipboardButton } from "@stenajs-webui/grid-export";
import { Select, SelectProps } from "@stenajs-webui/select";
import { StandardTable } from "@stenajs-webui/grid";
import { tableConfig } from "../config/PriceDetailsTableConfig";
import {
  transformPriceDetailTableRows,
  transformQueryToPriceDetailTableRow,
} from "../transformers/TransformPriceDetailTableRow";
import { tableConfigHtmlExport } from "../config/PriceCalculatorHtmlRenderTableConfig";
import { BookingSearchResultData } from "../../PriceCalculatorFormFetcher";
import { PaddedHeading } from "../../../../common/components/padded-heading/PaddedHeading";
import { transformSailingTypeCodeToSailingTypeName } from "../../../../common/transformers/SailingTypeTransformer";
import { parseTimeString } from "../../common/transformers/time";
import { PriceDetailTableRow } from "../config/PriceDetailTableRow";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props extends SelectProps<any> {
  bookingSearchResultData: BookingSearchResultData;
}
interface LegOption {
  label: string;
  value: string;
}

export const PriceCalculatorBookingDetailMultiLegTab: React.FC<Props> = ({
  bookingSearchResultData,
  ...selectProps
}) => {
  const headingH5HolderForCopy = useRef<HTMLDivElement>(null);
  const [selectedRouteCode, setSelectedRouteCode] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<LegOption>({
    label: "All legs",
    value: "",
  });

  const priceCalculatorData = bookingSearchResultData.priceCalcData;
  const consignmentData = bookingSearchResultData.consignmentData;

  const vehicleInformation =
    consignmentData.length > 0
      ? `${consignmentData[0].vehicleType.shortDescription}, ${consignmentData[0].length}m`
      : "";

  const options = useMemo(() => {
    return [
      {
        label: "All legs",
        value: "",
      } as LegOption,
      ...consignmentData.map((leg) => ({
        label: leg.sailingArchive.route.name,
        value: leg.sailingArchive.route.code,
      })),
    ];
  }, [consignmentData]);

  const renderCopy = (html: string): string => {
    return `<b>${heading()}</b>
    <br>
    <br>
    <b>${vehicleInformation}</b>
    ${headingH5HolderForCopy.current?.innerHTML}
    ${html}`;
  };

  let tableRowItems: PriceDetailTableRow[] = [];
  const isSelectedRouteAll = selectedRouteCode === "";
  if (isSelectedRouteAll) {
    const priceDetailTableRows = priceCalculatorData.map((priceCalc) =>
      transformQueryToPriceDetailTableRow(priceCalc)
    );

    const priceDetailTableRowsFlattened = priceDetailTableRows.reduce(
      (acc, x) => acc.concat(x)
    );

    const reducedPriceRow = priceCalculatorData.reduce((acc, curr) => ({
      ...acc,
      seaFreightPrice: acc.seaFreightPrice + curr.seaFreightPrice,
      totalPrice: acc.totalPrice + curr.totalPrice,
      totalVat: acc.totalVat + curr.totalVat,
    }));
    const grouped = groupBy(priceDetailTableRowsFlattened, "code");

    const reduceTableRows = sortBy(
      Object.keys(grouped).map((articleCode) => {
        return grouped[articleCode].reduce((acc, curr) => ({
          ...acc,
          priceExclVat: acc.priceExclVat + curr.priceExclVat,
          priceInclVat: acc.priceInclVat + curr.priceInclVat,
        }));
      }),
      ["code"]
    );
    tableRowItems = transformPriceDetailTableRows(
      reducedPriceRow,
      reduceTableRows
    );
  } else {
    const selectedRouteLeg = consignmentData.find(
      (leg) => leg.sailingArchive.route.code === selectedRouteCode
    );
    const selectedPriceCalc = priceCalculatorData.find(
      (legPriceData) => legPriceData.route.code === selectedRouteCode
    );
    if (selectedPriceCalc) {
      const priceDetailTableRows =
        transformQueryToPriceDetailTableRow(selectedPriceCalc);
      tableRowItems = selectedRouteLeg
        ? transformPriceDetailTableRows(selectedPriceCalc, priceDetailTableRows)
        : [];
    }
  }
  const heading = useCallback(() => {
    let headingString = "";
    if (consignmentData.length > 0) {
      let selectedConsignmentData = consignmentData[0];
      if (selectedRouteCode) {
        selectedConsignmentData =
          consignmentData.find(
            (consignment) =>
              consignment.sailingArchive.route.code === selectedRouteCode
          ) ?? selectedConsignmentData;
        headingString += `${selectedConsignmentData.sailingArchive.route?.name}`;
      } else {
        headingString += `${selectedConsignmentData.multiLegRoute?.name}`;
      }
      headingString += `, ${
        selectedConsignmentData.sailingArchive.departureDate
      }, ${parseTimeString(
        selectedConsignmentData.sailingArchive.departureTime
      )}, ${transformSailingTypeCodeToSailingTypeName(
        String(selectedConsignmentData.sailingArchive.sailingType)
      )}`;
    }
    return headingString;
  }, [selectedRouteCode, consignmentData]);
  return (
    <>
      <Box spacing={2} indent={3}>
        <Row justifyContent={"space-between"}>
          <Box>
            <div
              data-testid={
                testIdConstants.priceCalculatorResultPriceTripInformation
              }
            >
              <PaddedHeading variant={"h4"}>{heading()}</PaddedHeading>
            </div>
          </Box>

          {isSelectedRouteAll && (
            <StandardTableHtmlCopyToClipboardButton
              config={tableConfigHtmlExport}
              items={tableRowItems.filter((tr) => tr.priceExclVat > 0)}
              renderContent={renderCopy}
              size={"medium"}
            />
          )}
        </Row>
      </Box>

      <Box indent={3}>
        <div
          data-testid={
            testIdConstants.priceCalculatorResultPriceVehicleInformation
          }
        >
          <PaddedHeading variant={"h4"}>{vehicleInformation}</PaddedHeading>
        </div>
      </Box>
      <Row indent={3} justifyContent={"space-between"}>
        <Box ref={headingH5HolderForCopy}>
          <PaddedHeading variant={"h5"}>Articles specification</PaddedHeading>
        </Box>
        <Box width={200}>
          <Select
            options={options}
            value={selectedOption}
            onChange={(value) => {
              const option = value as LegOption;
              setSelectedRouteCode(option.value);
              setSelectedOption(option);
            }}
            menuPortalTarget={document.body}
            {...selectProps}
          />
        </Box>
      </Row>
      <div data-testid={testIdConstants.priceCalculatorResultPriceTable}>
        <StandardTable config={tableConfig} items={tableRowItems} />
      </div>
      <Spacing num={1} />
    </>
  );
};
