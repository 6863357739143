import { transformSailingTypeCodeToSailingTypeName } from "@/common/transformers/SailingTypeTransformer";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import { StandardTable } from "@stenajs-webui/grid";
import { StandardTableHtmlCopyToClipboardButton } from "@stenajs-webui/grid-export";
import { Select, SelectProps } from "@stenajs-webui/select";
import { groupBy, sortBy } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { BookingResultData } from "../../types";
import { tableConfigHtmlExport } from "../config/PriceCalculatorHtmlRenderTableConfig";
import { tableConfig } from "../config/PriceDetailsTableConfig";
import { PriceDetailTableRow } from "../config/PriceDetailTableRow";
import {
  parseTimeString,
  transformPriceDetailTableRows,
  transformQueryToPriceDetailTableRow,
} from "../transformers";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

interface Props extends SelectProps<any> {
  bookingResultData: BookingResultData;
}
interface LegOption {
  label: string;
  value: string;
}

export const PriceCalculatorBookingDetailMultiLegTab: React.FC<Props> = ({
  bookingResultData,
  ...selectProps
}) => {
  const headingH5HolderForCopy = useRef<HTMLDivElement>(null);
  const [selectedRouteCode, setSelectedRouteCode] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<LegOption>({
    label: "All legs",
    value: "",
  });

  const priceCalculatorData = bookingResultData.priceCalculatorData;
  const bookingData = bookingResultData.bookingData;

  const vehicleInformation =
    bookingData.length > 0
      ? `${bookingData[0].vehicleType.shortDescription}, ${bookingData[0].length}m`
      : "";

  const options = useMemo(() => {
    return [
      {
        label: "All legs",
        value: "",
      } as LegOption,
      ...bookingData.map((leg) => ({
        label: leg.sailingBookingConsignment.route.name,
        value: leg.sailingBookingConsignment.route.code,
      })),
    ];
  }, [bookingData]);

  const renderCopy = (html: string): string => {
    return `<b>${heading()}</b>
    <br>
    <br>
    <b>${vehicleInformation}</b>
    ${headingH5HolderForCopy.current?.innerHTML}
    ${html}`;
  };

  let tableRowItems: PriceDetailTableRow[] = [];
  const isSelectedRouteAll = selectedRouteCode === "";
  if (isSelectedRouteAll) {
    const priceDetailTableRows = priceCalculatorData.map((priceCalc) =>
      transformQueryToPriceDetailTableRow(priceCalc)
    );

    const priceDetailTableRowsFlattened = priceDetailTableRows.reduce(
      (acc, x) => acc.concat(x)
    );

    const reducedPriceRow = priceCalculatorData.reduce((acc, curr) => ({
      ...acc,
      seaFreightPrice: acc.seaFreightPrice + curr.seaFreightPrice,
      totalPrice: acc.totalPrice + curr.totalPrice,
      totalVat: acc.totalVat + curr.totalVat,
    }));
    const grouped = groupBy(priceDetailTableRowsFlattened, "code");

    const reduceTableRows = sortBy(
      Object.keys(grouped).map((articleCode) => {
        return grouped[articleCode].reduce((acc, curr) => ({
          ...acc,
          priceExclVat: acc.priceExclVat + curr.priceExclVat,
          priceInclVat: acc.priceInclVat + curr.priceInclVat,
        }));
      }),
      ["code"]
    );
    tableRowItems = transformPriceDetailTableRows(
      reducedPriceRow,
      reduceTableRows
    );
  } else {
    const selectedRouteLeg = bookingData.find(
      (leg) => leg.sailingBookingConsignment.route.code === selectedRouteCode
    );
    const selectedPriceCalc = priceCalculatorData.find(
      (legPriceData) => legPriceData.route.code === selectedRouteCode
    );
    if (selectedPriceCalc) {
      const priceDetailTableRows =
        transformQueryToPriceDetailTableRow(selectedPriceCalc);
      tableRowItems = selectedRouteLeg
        ? transformPriceDetailTableRows(selectedPriceCalc, priceDetailTableRows)
        : [];
    }
  }
  const heading = useCallback(() => {
    const firstLeg = 1;
    let headingString = "";
    if (bookingData.length > 0) {
      let selectedBookingData = bookingData?.find(
        (booking) => booking.bookingLegNumber === firstLeg
      );
      if (selectedRouteCode) {
        selectedBookingData =
          bookingData.find(
            (consignment) =>
              consignment.sailingBookingConsignment.route.code ===
              selectedRouteCode
          ) ?? selectedBookingData;
        headingString += `${selectedBookingData?.sailingBookingConsignment.route?.name}`;
      } else {
        headingString += `${selectedBookingData?.multiLegRoute?.name}`;
      }
      headingString += `, ${
        selectedBookingData?.sailingBookingConsignment.departureDate
      }, ${parseTimeString(
        selectedBookingData?.sailingBookingConsignment.departureTime ?? ""
      )}, ${transformSailingTypeCodeToSailingTypeName(
        String(selectedBookingData?.sailingBookingConsignment.sailingType)
      )}`;
    }
    return headingString;
  }, [selectedRouteCode, bookingData]);
  return (
    <>
      <Box indent={IndentValues.TWENTYFOUR}>
        <Row justifyContent={"space-between"}>
          <Box
            alignSelf={"flex-end"}
            data-testid={
              testIdConstants.priceCalculatorResultPriceTripInformation
            }
          >
            <Heading variant={"h4"}>{heading()}</Heading>
          </Box>

          {isSelectedRouteAll && (
            <StandardTableHtmlCopyToClipboardButton
              config={tableConfigHtmlExport}
              items={tableRowItems.filter((tr) => tr.priceExclVat > 0)}
              renderContent={renderCopy}
              size={"medium"}
            />
          )}
        </Row>
      </Box>
      <Space num={SpaceValues.SIXTEEN} />
      <Box
        indent={IndentValues.TWENTYFOUR}
        data-testid={
          testIdConstants.priceCalculatorResultPriceVehicleInformation
        }
      >
        <Heading variant={"h4"}>{vehicleInformation}</Heading>
      </Box>
      <Row indent={IndentValues.TWENTYFOUR} justifyContent={"space-between"}>
        <Box alignSelf={"flex-end"} ref={headingH5HolderForCopy}>
          <Heading variant={"h5"}>Articles specification</Heading>
        </Box>
        <Box width={200}>
          <Select
            options={options}
            value={selectedOption}
            onChange={(value) => {
              const option = value as LegOption;
              setSelectedRouteCode(option.value);
              setSelectedOption(option);
            }}
            menuPortalTarget={document.body}
            {...selectProps}
          />
        </Box>
      </Row>
      <Space />
      <div data-testid={testIdConstants.priceCalculatorResultPriceTable}>
        <StandardTable config={tableConfig} items={tableRowItems} />
      </div>
      <Space />
    </>
  );
};
