import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import {
  CustomerHeaderCustomersByIdFragment,
  HeadAgreementInfoRowSectionFragment,
  HeadAgreementStatusCode,
} from "@/gql/graphql";
import {
  ActionMenu,
  ActionMenuItem,
  ActionMenuSeparator,
  stenaBell,
  stenaBusinessAgreement,
  stenaCalculate,
  stenaCopy,
  stenaPen,
  stenaSwitch,
  stenaTrash,
} from "@stenajs-webui/elements";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { routeFactory } from "../../../../../RouteFactory";
import { headAgreementCommonActions } from "../../common/redux/reducer";
import {
  canChangeHeadAgreementStatusToActive,
  canChangeStatusToPending,
  canChangeStatusToVoid,
  canDeleteAgreement,
} from "../features/existing-agreement/util/AgreementStatusUtil";
import { CreateReportMenuItems } from "./CreateReportMenuItems";

interface Props {
  customer: CustomerHeaderCustomersByIdFragment | null | undefined;
  headAgreement: HeadAgreementInfoRowSectionFragment;
  onOpenSwitchModal: () => void;
  onOpenDeleteModal: () => void;
  onRequestClose?: () => void;
  onChangeStatus: (statusCode: HeadAgreementStatusCode) => Promise<void>;
}

export const HeadAgreementMenu: React.FC<Props> = ({
  headAgreement,
  customer,
  onOpenSwitchModal,
  onOpenDeleteModal,
  onRequestClose,
  onChangeStatus,
}) => {
  const { customerId } = useParams() as { customerId: string };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <ActionMenu>
      <ActionMenuItem
        label={"Switch agreement"}
        leftIcon={stenaSwitch}
        onClick={() => {
          onOpenSwitchModal();
          if (onRequestClose) {
            onRequestClose();
          }
        }}
      />
      <ActionMenuSeparator />
      <ActionMenuItem
        label={"Copy agreement"}
        leftIcon={stenaCopy}
        onClick={() =>
          navigate(
            routeFactory.productAndPrice.headAgreement.copyHeadAgreement({
              customerId,
              headAgreementId: headAgreement.id,
            })
          )
        }
        data-testid={testIdConstants.headerAgreementActionsMenuCopyAgreement}
      />
      <ActionMenuItem
        label={"Copy to next year"}
        leftIcon={stenaCopy}
        onClick={() => {
          dispatch(headAgreementCommonActions.setCopyToNextYear(true));
          navigate(
            routeFactory.productAndPrice.headAgreement.copyHeadAgreement({
              customerId,
              headAgreementId: headAgreement.id,
            })
          );
        }}
      />
      <ActionMenuItem
        label={"Copy to other customer"}
        leftIcon={stenaCopy}
        onClick={() =>
          navigate(
            routeFactory.productAndPrice.headAgreement.copyHeadAgreementToOtherCustomer(
              {
                customerId,
                headAgreementId: headAgreement.id,
              }
            )
          )
        }
        data-testid={
          testIdConstants.headerAgreementActionsMenuCopyToOtherCustomer
        }
      />
      <ActionMenuItem
        label={"Edit agreement"}
        leftIcon={stenaPen}
        onClick={() =>
          navigate(
            routeFactory.productAndPrice.headAgreement.editHeadAgreement({
              customerId,
              headAgreementId: headAgreement.id,
            })
          )
        }
        data-testid={testIdConstants.headerAgreementActionsMenuEditAgreement}
      />
      <ActionMenuItem
        label={"New agreement"}
        leftIcon={stenaBusinessAgreement}
        onClick={() =>
          navigate(
            routeFactory.productAndPrice.headAgreement.createHeadAgreement({
              customerId,
            })
          )
        }
        data-testid={testIdConstants.headerAgreementActionsMenuNewAgreement}
      />
      {customer?.isStandardCustomer && (
        <ActionMenuItem
          label={"Agreement articles"}
          leftIcon={stenaCalculate}
          onClick={() =>
            navigate(
              routeFactory.productAndPrice.headAgreement.agreementArticles({
                customerId,
                headAgreementId: headAgreement.id,
              })
            )
          }
          data-testid={
            testIdConstants.headerAgreementActionsMenuAgreementArticles
          }
        />
      )}
      <ActionMenuSeparator />
      <CreateReportMenuItems
        headAgreement={headAgreement}
        customer={customer}
        onRequestClose={() => {
          if (onRequestClose) {
            onRequestClose();
          }
        }}
      />
      <ActionMenuSeparator />
      <ActionMenuItem
        label={"Set to pending"}
        disabled={
          headAgreement.valid.start?.isoString
            ? !canChangeStatusToPending(
                new Date(headAgreement.valid.start.isoString),
                headAgreement.statusCode
              )
            : false
        }
        leftIcon={stenaBell}
        onClick={() => onChangeStatus(HeadAgreementStatusCode.Pending)}
        data-testid={testIdConstants.headerAgreementActionsMenuSetToPending}
      />
      <ActionMenuItem
        label={"Activate"}
        disabled={
          !canChangeHeadAgreementStatusToActive(headAgreement.statusCode)
        }
        leftIcon={stenaBell}
        onClick={() =>
          navigate(
            routeFactory.productAndPrice.headAgreement.activateHeadAgreement({
              customerId,
              headAgreementId: headAgreement.id,
            })
          )
        }
        data-testid={testIdConstants.headerAgreementActionsMenuActivate}
      />
      <ActionMenuItem
        label={"Set to void"}
        disabled={
          !canChangeStatusToVoid(
            headAgreement.statusCode,
            headAgreement.valid.start?.isoString ?? null
          )
        }
        leftIcon={stenaBell}
        onClick={() => onChangeStatus(HeadAgreementStatusCode.Void)}
        data-testid={testIdConstants.headerAgreementActionsMenuSetToVoid}
      />
      <ActionMenuSeparator />
      <ActionMenuItem
        label={"Delete"}
        disabled={!canDeleteAgreement(headAgreement.statusCode)}
        leftIcon={stenaTrash}
        onClick={() => {
          onOpenDeleteModal();
          if (onRequestClose) {
            onRequestClose();
          }
        }}
        data-testid={testIdConstants.headerAgreementActionsMenuDelete}
      />
    </ActionMenu>
  );
};
