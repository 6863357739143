import {
  MovementType,
  PriceCalculatorFormData,
} from "@/features/price-calculator-v2/types";

const isMultiLegDataCorrect = (formData: PriceCalculatorFormData) => {
  if (formData.routeLegs === undefined) {
    return true;
  }

  const invalidRouteLegsExist = formData.routeLegs?.find((rl) => {
    return rl.departureTime === "";
  });

  return (
    !formData.selectedCustomerNum ||
    !formData.multiLegRouteData?.code ||
    isGoodsSelectionFinished(formData) ||
    isAncillarySelectionFinished(formData) ||
    invalidRouteLegsExist !== undefined
  );
};

const isGoodsSelectionFinished = (data: PriceCalculatorFormData) => {
  if (data.conditionsGoods?.length === 0) {
    return false;
  }

  return data.conditionsGoods?.some((cg) => {
    return cg.goodsCode === "" || isNaN(Number(cg.weight));
  });
};

const isAncillarySelectionFinished = (data: PriceCalculatorFormData) => {
  if (data.conditionsAncillary?.length === 0) {
    return false;
  }

  return data.conditionsAncillary?.some((ca) => {
    return ca.ancillaryCode === "" || ca.quantity < 1 || isNaN(ca.quantity);
  });
};

const isSectionalDataCorrect = (formData: PriceCalculatorFormData) => {
  return (
    !formData.selectedCustomerNum ||
    !formData.routeCode ||
    !formData.sailingDate ||
    !formData.vehicleType ||
    !formData.departureTime ||
    isGoodsSelectionFinished(formData) ||
    isAncillarySelectionFinished(formData)
  );
};

export const isCalculationDisabled = (formData: PriceCalculatorFormData) => {
  return formData.movementType === MovementType.MULTILEG
    ? isMultiLegDataCorrect(formData)
    : isSectionalDataCorrect(formData);
};
