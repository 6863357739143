import { Box } from "@stenajs-webui/core";
import React from "react";
import { CustomerMultilegRouteRateSheetListItem } from "./CustomerMultilegRouteRateSheetListItem";
import { RouteAgreementsForMultilegRouteFragment } from "@/gql/graphql";

interface Props {
  headAgreement: RouteAgreementsForMultilegRouteFragment["headAgreement"];
  routeAgreements: RouteAgreementsForMultilegRouteFragment["routeAgreements"];
  customerId: string;
  multiLegAgreement: RouteAgreementsForMultilegRouteFragment;
  bulkUpdateModeEnabled: boolean;
}

export const CustomerMultilegRouteRateSheetList: React.FC<Props> = React.memo(
  ({
    headAgreement,
    customerId,
    routeAgreements,
    multiLegAgreement,
    bulkUpdateModeEnabled,
  }) => {
    return (
      <>
        {routeAgreements.map((routeAgreement) => (
          <Box key={routeAgreement.id}>
            <CustomerMultilegRouteRateSheetListItem
              bulkUpdateModeEnabled={bulkUpdateModeEnabled}
              multiLegAgreement={multiLegAgreement}
              customerId={customerId}
              routeAgreement={routeAgreement}
              headAgreement={headAgreement}
              key={routeAgreement.id}
            />
          </Box>
        ))}
      </>
    );
  }
);
