import { AncillarySelect } from "@/common/components/data-driven-inputs/selects/ancillary-select/AncillarySelect";
import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { FormModelChangeHandler } from "@/common/forms/types";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { Box, Row, Space } from "@stenajs-webui/core";
import {
  FlatButton,
  SecondaryButton,
  stenaMinus,
  stenaPlusCircle,
} from "@stenajs-webui/elements";
import { Tooltip } from "@stenajs-webui/tooltip";
import { FormLabelWithChildren } from "../PriceCalculatorCustomerForm";
import { GapValues } from "@/common/utils/GapValues";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorAddAncillaryRow: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  const isAncillaryQuantityEmpty = (index: number) =>
    !formData?.conditionsAncillary?.[index]?.quantity;
  return (
    <Row gap={GapValues.TWENTYFOUR}>
      <Box width={200}>
        <FormLabelWithChildren label={"Ancillary code"}>
          {formData?.conditionsAncillary?.map((ancillary, index) => (
            <Box key={`ancillary-select-${index}`}>
              <AncillarySelect
                value={ancillary.ancillaryCode}
                onValueChange={(ancillaryCode) => {
                  const mergedConditionsAncillary =
                    formData.conditionsAncillary;
                  if (mergedConditionsAncillary) {
                    mergedConditionsAncillary[index] = {
                      ...mergedConditionsAncillary[index],
                      ancillaryCode,
                    };
                    onChangeFormData(
                      "conditionsAncillary",
                      mergedConditionsAncillary
                    );
                  }
                }}
                height={"38px"}
                menuPortalTarget={document.body}
                menuPlacement={"auto"}
                minMenuHeight={300}
              />
              <Space num={SpaceValues.SIXTEEN} />
            </Box>
          ))}
          <SecondaryButton
            label={"Add ancillary"}
            leftIcon={stenaPlusCircle}
            onClick={() => {
              onChangeFormData("conditionsAncillary", [
                ...(formData?.conditionsAncillary || []),
                { ancillaryCode: "", quantity: 1 },
              ]);
            }}
            size={"medium"}
          />
        </FormLabelWithChildren>
      </Box>
      <FormLabelWithChildren label={"Quantity"}>
        {formData.conditionsAncillary?.length === 0 ? (
          <Row gap={GapValues.TWENTYFOUR}>
            <NumericTextInput disabled />
            <FlatButton leftIcon={stenaMinus} />
          </Row>
        ) : (
          formData?.conditionsAncillary?.map((ancillary, index) => (
            <Row gap={GapValues.TWENTYFOUR} key={`quantity-row-${index}`}>
              <Box width={187}>
                <Tooltip
                  label={
                    isAncillaryQuantityEmpty(index)
                      ? "Please provide a quantity above 0"
                      : ""
                  }
                  zIndex={ZIndex.lowest}
                  variant="warning"
                  appendTo={document.body}
                  disabled={!isAncillaryQuantityEmpty(index)}
                >
                  <NumericTextInput
                    value={
                      isAncillaryQuantityEmpty(index)
                        ? ""
                        : ancillary.quantity.toString()
                    }
                    onValueChange={(quantity) => {
                      const mergedConditionsAncillary =
                        formData.conditionsAncillary;
                      if (mergedConditionsAncillary) {
                        mergedConditionsAncillary[index] = {
                          ...mergedConditionsAncillary[index],
                          quantity: parseInt(quantity),
                        };
                        onChangeFormData(
                          "conditionsAncillary",
                          mergedConditionsAncillary
                        );
                      }
                    }}
                    variant={
                      isAncillaryQuantityEmpty(index) ? "warning" : "standard"
                    }
                  />
                </Tooltip>
                <Space num={SpaceValues.SIXTEEN} />
              </Box>
              <FlatButton
                leftIcon={stenaMinus}
                onClick={() =>
                  onChangeFormData("conditionsAncillary", [
                    ...(formData?.conditionsAncillary || []).filter(
                      (item, formIndex) => index !== formIndex
                    ),
                  ])
                }
              />
            </Row>
          ))
        )}
      </FormLabelWithChildren>
    </Row>
  );
};
