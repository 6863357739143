import { FormModelChangeHandler } from "@/common/forms/types";
import { GapValues } from "@/common/utils/GapValues";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { Box } from "@stenajs-webui/core";
import * as React from "react";
import { PriceCalculatorAddAncillaryRow } from "./PriceCalculatorAddAncillaryRow";
import { PriceCalculatorAddGoodsRow } from "./PriceCalculatorAddGoodsRow";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorGoodsAndAncillaries: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  return (
    <Box gap={GapValues.TWENTYFOUR}>
      <PriceCalculatorAddGoodsRow
        formData={formData}
        onChangeFormData={onChangeFormData}
      />
      <PriceCalculatorAddAncillaryRow
        formData={formData}
        onChangeFormData={onChangeFormData}
      />
    </Box>
  );
};
