import { SpaceValues } from "@/common/utils/SpaceValues";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { PriceCalcLoadingPreferencesRadioQuery } from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { LoadingPanel } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import * as React from "react";
import { useMemo } from "react";
import { FormModelChangeHandler } from "../../../../../common/forms/types";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

const query = gql`
  query PriceCalcLoadingPreferencesRadio {
    productPrice {
      loadingPreference {
        all {
          id
          code
          description
          presentationOrder
        }
      }
    }
  }
`;

export const PriceCalculatorLoadingPreferenceRadioGroup: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  const { data, loading } =
    useQuery<PriceCalcLoadingPreferencesRadioQuery>(query);

  const loadingPreferences = useMemo(() => {
    return data?.productPrice.loadingPreference.all ?? [];
  }, [data]);

  const options = useMemo(() => {
    const loadingPreferencesArrayForSort = [...loadingPreferences];
    return loadingPreferencesArrayForSort
      .sort((l) => l.presentationOrder)
      .map((r) => ({
        label: `${r.description}`,
        value: r.id,
        data: r,
      }));
  }, [loadingPreferences]);

  const selected = useMemo(
    () => options.filter((o) => o.value === formData.loadingPreference),
    [options, formData.loadingPreference]
  );
  if (loading)
    return (
      <Box height={"120px"}>
        <LoadingPanel />
      </Box>
    );
  return (
    <Box>
      <Heading variant={"h4"}>Loading preferences</Heading>
      <Space num={SpaceValues.SIXTEEN} />
      <Row flexWrap={"wrap"}>
        {options?.map((option, index) => (
          <Box flex={"30%"} height={"40px"} key={index}>
            <RadioButtonWithLabel
              label={option.label}
              value={option.value}
              checked={selected && selected[0] === option}
              onClick={() => {
                if (formData.loadingPreference === option.value) {
                  onChangeFormData("loadingPreference", "");
                }
              }}
              onValueChange={() =>
                onChangeFormData("loadingPreference", option.value)
              }
            />
          </Box>
        ))}
      </Row>
    </Box>
  );
};
