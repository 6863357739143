import { CustomerSelect } from "@/common/components/data-driven-inputs/selects/customer-select/CustomerSelect";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { CSSObject } from "@emotion/react";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import { Select } from "@stenajs-webui/select";
import { cssColor } from "@stenajs-webui/theme";
import { useContext, useMemo } from "react";
import { PriceCalculatorBookingSearch } from "../price-calculator-forms/booking/PriceCalculatorBookingSearch";
import { PriceCalculatorCustomerDetails } from "../price-calculator-forms/customer/common/PriceCalculatorCustomerDetails";
import {
  PriceCalculatorContext,
  PriceCalculatorContextType,
} from "../PriceCalculatorContext";
import { CustomerOrBookingSelect, SearchEntityType } from "../types";

interface Props {
  onSelectBookingVersion: (
    bookingNum: number,
    consignmentVersion: number
  ) => void;
}

export const PriceCalculatorSearch: React.FC<Props> = ({
  onSelectBookingVersion,
}) => {
  const {
    formData,
    onChangeFormData,
    setState,
    defaultFormState,
    setCustomerCalculatedState,
  } = useContext(PriceCalculatorContext) as PriceCalculatorContextType;

  const options: Array<CustomerOrBookingSelect> = useMemo(() => {
    return [
      {
        label: "Customer",
        value: SearchEntityType.CUSTOMER,
      },
      {
        label: "Booking",
        value: SearchEntityType.BOOKING,
      },
    ];
  }, []);

  const selected = useMemo(
    () => options.find((o) => o.value === formData.searchEntityType),
    [formData.searchEntityType, options]
  );

  return (
    <>
      {formData.selectedCustomerNum ? (
        <PriceCalculatorCustomerDetails
          selectedCustomerNum={formData.selectedCustomerNum}
          onChangeCustomer={() =>
            onChangeFormData("selectedCustomerNum", undefined)
          }
        />
      ) : (
        <Box
          background={cssColor("--lhds-color-ui-200")}
          indent={IndentValues.TWENTYFOUR}
          spacing={SpacingValues.TWENTYFOUR}
        >
          <Heading
            variant={"h4"}
            data-testid={testIdConstants.priceCalculatorSearchFieldHeader}
          >
            Search customer or booking
          </Heading>
          <Space />
          <Row>
            <Box
              width={"120px"}
              data-testid={testIdConstants.priceCalculatorCustomerOrBookingBox}
            >
              <Select
                options={options}
                value={selected}
                onChange={(entity) => {
                  setState({
                    ...defaultFormState,
                    searchEntityType: entity?.value,
                    selectedCustomerNum: undefined,
                  });
                  setCustomerCalculatedState({
                    ...defaultFormState,
                    searchEntityType: entity?.value,
                    selectedCustomerNum: undefined,
                  });
                }}
              />
            </Box>
            <Space num={SpaceValues.SIXTEEN} />
            <Box
              data-testid={
                formData.searchEntityType === SearchEntityType.CUSTOMER
                  ? testIdConstants.priceCalculatorCustomerSearchField
                  : formData.searchEntityType === SearchEntityType.BOOKING
                  ? testIdConstants.priceCalculatorBookingSearchField
                  : null
              }
            >
              {formData.searchEntityType === SearchEntityType.CUSTOMER ? (
                <Box width={"250px"}>
                  <CustomerSelect
                    value={String(formData.selectedCustomerNum)}
                    placeholder={"Number/Index"}
                    onValueChange={(option) => {
                      onChangeFormData("selectedCustomerNum", option.custNo);
                    }}
                    styles={{
                      control: (base: CSSObject) => ({
                        ...base,
                        height: "38px",
                      }),
                    }}
                  />
                </Box>
              ) : (
                <PriceCalculatorBookingSearch
                  onSelectBookingVersion={onSelectBookingVersion}
                  onChangeFormData={onChangeFormData}
                />
              )}
            </Box>
          </Row>
        </Box>
      )}
    </>
  );
};
