import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { CustomerInfoPairBox } from "@/features/product-and-price/info-boxes/customer/CustomerInfoPairBox";
import { CustomerThreeYearStatisticsInfoBox } from "@/features/product-and-price/info-boxes/customer/statistics/CustomerThreeYearStatisticsInfoBox";
import { DocumentsInfoBox } from "@/features/product-and-price/info-boxes/document/DocumentsInfoBox";
import { TopHeadAgreementsInfoBox } from "@/features/product-and-price/info-boxes/head-agreement/top-head-agreements/TopHeadAgreementsInfoBox";
import { Box, Row, Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingPanel } from "@stenajs-webui/panels";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useNegotiationYears } from "../../common/negotiation-year/NegotiationYearGate";
import { useCustomerDetailsView } from "../hooks/useCustomerDetails";
import { CustomerHeader } from "./CustomerHeader";

export interface CustomerScreenParams {
  customerId: string;
}

interface Props {}

export const CustomerScreen: React.FC<Props> = () => {
  const numberOfAgreementsToShow = 3;
  const numberOfDocumentsToShow = 3;
  const { customerId } = useParams() as { customerId: string };
  const { defaultYear } = useNegotiationYears();
  const { loading, customer } = useCustomerDetailsView(
    customerId,
    numberOfAgreementsToShow,
    numberOfDocumentsToShow,
    defaultYear
  );
  const headAgreements = customer?.topHeadAgreements ?? [];
  const agreementDocuments = customer?.topAgreementDocuments ?? [];
  const rebateAgreementDocuments = customer?.topRebateAgreementDocuments ?? [];
  const customerThreeYearStatistics =
    customer?.customerThreeYearsStatistics ?? [];
  const currencyCode = customer?.invoiceCurrency.code;

  if (!customerId) {
    return <ErrorScreen text={"Missing custIndex."} />;
  }

  if (loading) {
    return (
      <Spacing num={SpacingValues.TWENTYFOUR}>
        <LoadingPanel />
      </Spacing>
    );
  }

  return (
    <>
      <Helmet title="Customer - FPP" />
      <CustomerHeader
        breadCrumbName={customer?.breadCrumbName ?? ""}
        customerName={customer?.name ?? ""}
      />
      <PageLayout>
        {customer && <CustomerInfoPairBox customer={customer} />}
        <Row gap={3}>
          <Box width={"50%"}>
            <TopHeadAgreementsInfoBox
              headAgreements={headAgreements}
              customerId={customerId}
            />
          </Box>
          <Box width={"50%"} gap={3}>
            <DocumentsInfoBox
              agreementDocuments={agreementDocuments}
              rebateAgreementDocuments={rebateAgreementDocuments}
              customerNumber={customerId}
            />
            <CustomerThreeYearStatisticsInfoBox
              customerStatistics={customerThreeYearStatistics}
              currencyCode={currencyCode ?? ""}
            />
          </Box>
        </Row>
      </PageLayout>
    </>
  );
};
