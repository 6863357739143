import * as React from "react";
import { PageHeader } from "../../../../../common/components/page-header/PageHeader";
import { Crumb } from "../../../../../common/components/bread-crumbs/Crumb";
import { routeFactory } from "../../../../../RouteFactory";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";

import {
  BreadCrumbs,
  FlatButton,
  stenaStatisticsBar,
} from "@stenajs-webui/elements";
import { CurrencySelect } from "@/common/components/data-driven-inputs/selects/currency-select/CurrencySelect";
import { NegotiationYearSelect } from "@/common/components/data-driven-inputs/selects/negotiation-year-select/NegotiationYearSelect";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Space } from "@stenajs-webui/core";
import { useNavigate } from "react-router-dom";
import { useStatisticsYearSelect } from "@/features/statistics/common/hooks/UseStatisticsYearSelector";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { useDispatch } from "react-redux";
import {
  StatisticsContext,
  StatisticsContextType,
} from "@/features/statistics/StatisticsContext";
import { useContext } from "react";
import { useFilterWorkspace } from "@/features/search-filter/hooks/UseFilterWorkspace";
import { SearchFilterActionsContext } from "@stenajs-webui/filter";
import { transformStatusItemsToFilterStatus } from "@/features/statistics/common/utils/statisticsUtil";
import {
  StatisticsBySellerDetailsHeaderQuery,
  StatisticsBySellerDetailsHeaderQueryVariables,
} from "@/gql/graphql";

interface Props {
  sellerId: string;
  currency: string;
  handleCurrencyChange: (currency: string) => void;
}

const query = gql`
  query StatisticsBySellerDetailsHeader($sellerId: ID!) {
    productPrice {
      user {
        byId(id: $sellerId) {
          id
          name
        }
      }
    }
  }
`;

export const StatisticsBySellerDetailsHeader: React.FC<Props> = ({
  sellerId,
  currency,
  handleCurrencyChange,
}) => {
  const { data, loading } = useQuery<
    StatisticsBySellerDetailsHeaderQuery,
    StatisticsBySellerDetailsHeaderQueryVariables
  >(query, { variables: { sellerId } });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { year, handleYearChange } = useStatisticsYearSelect();
  const { sellerSelectedStatuses } = React.useContext(
    StatisticsContext
  ) as StatisticsContextType;

  const onSelectCurrencyChange = (currency: string) => {
    handleCurrencyChange(currency);
  };
  const seller = data?.productPrice.user.byId;
  const searchFilterActions = useContext(SearchFilterActionsContext);
  const filterWorkspace = useFilterWorkspace();

  const updateAndSetCustomerTabState = () => {
    if (seller) {
      dispatch(searchFilterActions.clearFormModel());
      dispatch(
        searchFilterActions.setFormModelFields({
          negotiationStatus: transformStatusItemsToFilterStatus(
            sellerSelectedStatuses
          ),
          sellers: [{ code: seller.id, id: seller.id, name: seller.name }],
        })
      );
      filterWorkspace.commit();
    }
  };

  return (
    <PageHeader
      icon={stenaStatisticsBar}
      title={seller?.name ?? ""}
      zIndex={ZIndex.low}
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"statistics"}
            path={routeFactory.statistics.totalTab()}
          />
          <Crumb label={"Seller"} path={routeFactory.statistics.sellerTab()} />
          <Crumb
            label={seller?.name ?? ""}
            isLast
            path={
              seller ? routeFactory.statistics.sellerDetails(seller.id) : ""
            }
          />
        </BreadCrumbs>
      }
      contentRight={
        <>
          <FlatButton
            disabled={loading}
            label="Show customer list "
            onClick={() => {
              updateAndSetCustomerTabState();
              navigate(routeFactory.statistics.customerTab());
            }}
          />
          <Space num={SpaceValues.SIXTEEN} />
          <NegotiationYearSelect
            value={year}
            onValueChange={(value) => handleYearChange(value)}
          />
          <Space num={SpaceValues.SIXTEEN} />
          <CurrencySelect
            value={currency}
            onValueChange={onSelectCurrencyChange}
            containerWidth={"80px"}
          />
        </>
      }
    />
  );
};
