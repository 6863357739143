import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import {
  PriceDetailsRouteQuery,
  PriceDetailsRouteQueryVariables,
} from "@/gql/graphql";

export const usePriceDetailsRoute = (routeCode: string | undefined) => {
  const query = gql`
    query PriceDetailsRoute($input: ID!) {
      productPrice {
        route {
          byId(id: $input) {
            id
            name
          }
        }
      }
    }
  `;

  const { data, loading, error } = useQuery<
    PriceDetailsRouteQuery,
    PriceDetailsRouteQueryVariables
  >(query, {
    variables: {
      input: routeCode ?? "",
    },
  });
  return { data, loading, error };
};
