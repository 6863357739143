import { Box, Txt } from "@stenajs-webui/core";
import React, { useState } from "react";
import { CreateMultiLegRouteAgreementModal } from "../../multileg-agreement/components/create-multi-leg-route-agreement/CreateMultiLegRouteAgreementModal";
import { CustomerMultilegRouteRateSheetList } from "./CustomerMultilegRouteRateSheetList";
import { NoRouteAgreementBox } from "./NoRouteAgreementBox";
import { cssColor } from "@stenajs-webui/theme";
import {
  CustomerMultilegRouteFetcherQuery,
  HeadAgreementForMultilegFragment,
} from "@/gql/graphql";

interface Props {
  multiLegAgreement: CustomerMultilegRouteFetcherQuery["productPrice"]["multiLegRouteAgreement"]["byId"];
  headAgreement: HeadAgreementForMultilegFragment;
  bulkUpdateModeEnabled: boolean;
}

export const CustomerMultilegRouteLegList: React.FC<Props> = ({
  multiLegAgreement,
  headAgreement,
  bulkUpdateModeEnabled,
}) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const routeLegs = multiLegAgreement?.routeLegs;
  const routeAgreements = multiLegAgreement?.routeAgreements;
  const agreementRouteIds = routeAgreements?.flatMap((ra) =>
    ra.routes.map((r) => r.id)
  );
  const selectedRoute = routeLegs?.find(
    (r) => r.id.slice(-1) === String(index + 1)
  )?.legRoute;

  const getMultiLegAgreement = () => {
    if (headAgreement && multiLegAgreement) {
      return headAgreement.multiLegAgrRoutes.find(
        (mla) => mla.id === multiLegAgreement.id
      );
    }
  };

  return (
    <>
      {routeLegs?.map((routeLeg, index) => {
        const filteredRouteAgreementsByLeg = routeAgreements?.filter(
          (routeAgreement) =>
            routeAgreement.routeDescription === routeLeg.legRoute.code
        );
        return (
          <Box key={routeLeg.id}>
            <Box style={{ marginBottom: "8px" }}>
              <Txt
                variant={"overline"}
                size={"smaller"}
                color={cssColor("--lhds-color-ui-600")}
              >
                LEG {routeLeg.legNo}: {routeLeg.legRoute.name.toUpperCase()}
              </Txt>
            </Box>

            {agreementRouteIds?.includes(routeLeg.legRoute.id) &&
            multiLegAgreement?.headAgreement.customer.custNo &&
            filteredRouteAgreementsByLeg ? (
              <CustomerMultilegRouteRateSheetList
                multiLegAgreement={multiLegAgreement}
                customerId={multiLegAgreement?.headAgreement.customer.custNo.toString()}
                bulkUpdateModeEnabled={bulkUpdateModeEnabled}
                headAgreement={multiLegAgreement?.headAgreement}
                routeAgreements={filteredRouteAgreementsByLeg}
              />
            ) : (
              <>
                <Box justifyContent={"center"} alignItems={"center"}>
                  <NoRouteAgreementBox
                    bannerHeader={"No route agreement exists"}
                    buttonLabel={"New route agreement"}
                    bannerText={"Please create a route agreement for this leg."}
                    onClick={() => {
                      setOpen(true);
                      setIndex(index);
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        );
      })}
      <CreateMultiLegRouteAgreementModal
        isOpen={open}
        multiLegAgreement={getMultiLegAgreement()!}
        onRequestClose={() => setOpen(false)}
        name={selectedRoute?.name}
        selectedRouteCode={selectedRoute?.code}
      />
    </>
  );
};
