import { CustomsClassificationSelectQuery } from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { Select, SelectProps } from "@stenajs-webui/select";
import { gql } from "apollo-boost";
import * as React from "react";
import { useMemo } from "react";

interface Props extends Omit<SelectProps<unknown>, "value" | "onChange"> {
  value?: string;
  onValueChange?: (value: string) => void;
}

const query = gql`
  query CustomsClassificationSelect {
    productPrice {
      customsClassification {
        all {
          id
          code
          description
        }
      }
    }
  }
`;

export const CustomsClassificationSelect: React.FC<Props> = ({
  onValueChange,
  value,
  ...selectProps
}) => {
  const { data, loading } = useQuery<CustomsClassificationSelectQuery>(query);

  const customsClassifications = useMemo(() => {
    return data?.productPrice.customsClassification.all;
  }, [data]);

  const allOptions = useMemo(
    () =>
      customsClassifications?.map((customsClassification) => ({
        label: customsClassification.description,
        value: customsClassification.code,
      })),
    [customsClassifications]
  );

  const selected = useMemo(
    () => allOptions?.find((o) => o.value === value),
    [allOptions, value]
  );

  return (
    <Select
      isLoading={loading}
      options={allOptions}
      value={selected || null}
      isClearable
      onChange={(v: any) => {
        if (!v) {
          onValueChange?.("");
        } else if (onValueChange && v) {
          onValueChange?.(v.value);
        }
      }}
      {...selectProps}
    />
  );
};
