import { NumericTextInputNoScroll } from "@/common/components/forms/NumericTextInputNoScroll";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import * as React from "react";
import { FormLabelWithChildren } from "../PriceCalculatorCustomerForm";
import { PriceCalculatorFormData } from "@/features/price-calculator-v2/types";
import { FormModelChangeHandler } from "@/common/forms/types";
import { GapValues } from "@/common/utils/GapValues";

interface Props {
  formData: PriceCalculatorFormData;
  onChangeFormData: FormModelChangeHandler<PriceCalculatorFormData>;
}

export const PriceCalculatorDriversDetails: React.FC<Props> = ({
  formData,
  onChangeFormData,
}) => {
  return (
    <Box>
      <Heading variant={"h4"}>Drivers and passengers</Heading>
      <Space num={SpaceValues.SIXTEEN} />
      <Row gap={GapValues.TWENTYFOUR}>
        <FormLabelWithChildren label={"Adults (16+)"} width={80}>
          <NumericTextInputNoScroll
            value={formData.passengersAdults?.toString()}
            onValueChange={(value) =>
              onChangeFormData("passengersAdults", parseInt(value))
            }
            min={0}
          />
        </FormLabelWithChildren>
        <FormLabelWithChildren label={"Children (4-15)"} width={80}>
          <NumericTextInputNoScroll
            value={formData.passengersChildren?.toString()}
            onValueChange={(value) =>
              onChangeFormData("passengersChildren", parseInt(value))
            }
            min={0}
          />
        </FormLabelWithChildren>
        <FormLabelWithChildren label={"Infants (0-3)"} width={80}>
          <NumericTextInputNoScroll
            value={formData.passengersInfants?.toString()}
            onValueChange={(value) =>
              onChangeFormData("passengersInfants", parseInt(value))
            }
            min={0}
          />
        </FormLabelWithChildren>
        <FormLabelWithChildren label={"No of sleepers"} width={80}>
          <NumericTextInputNoScroll
            value={formData.passengersSleepers?.toString()}
            onValueChange={(value) =>
              onChangeFormData("passengersSleepers", parseInt(value))
            }
            min={0}
          />
        </FormLabelWithChildren>
      </Row>
    </Box>
  );
};
