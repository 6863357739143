import { usePrompt } from "@/common/hooks/usePrompt";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { Space } from "@stenajs-webui/core";
import {
  PrimaryButton,
  ResultListBanner,
  SecondaryButton,
  stenaBusinessNslh,
} from "@stenajs-webui/elements";
import { groupBy } from "lodash";
import * as React from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "../../../common/components/page-header/PageHeader";
import { StoreState } from "../../../config/redux/RootReducer";
import {
  noShowLateHandlingRoutePricesActions,
  noShowLateHandlingRoutePricesCurrentlySelectedActions,
} from "./redux/actions";
import { fetchNoShowLateHandlingRoutePrices } from "./redux/thunks/NoShowLateHandlingRoutePricesFetcher";
import {
  updateAllRoutePrices,
  UpdateAllRoutePricesData,
} from "./redux/thunks/RoutePriceMutations";
import { handleUpdateRoutePricesDataForUpdateTransformer } from "./transformers/HandleUpdateRoutePricesDataForUpdateTransformer";
import { dataHasBeenModified } from "./util/DataHasBeenModified";

interface Props {
  isDataModified: boolean;
  isDataModifiedDiscard: boolean;
  handleResetTable: () => void;
  handleUpdateRoutePrices: () => void;
}

const HeaderContentRight: React.FC<Props> = ({
  isDataModified,
  isDataModifiedDiscard,
  handleResetTable,
  handleUpdateRoutePrices,
}) => {
  return (
    <>
      <SecondaryButton
        label={"Discard"}
        disabled={!isDataModifiedDiscard}
        onClick={handleResetTable}
        data-testid={testIdConstants.nSLHRoutePricesDiscardButton}
      />
      <Space num={2} />
      <PrimaryButton
        label={"Save"}
        disabled={!isDataModified}
        onClick={handleUpdateRoutePrices}
        data-testid={testIdConstants.nSLHRoutePricesSaveButton}
      />
    </>
  );
};

const noShowLateHandlingRoutePricesDataSelector = (state: StoreState) =>
  state.noShowLateHandlingPrices.allNoShowHandlingPriceData;
const bannerStateSelector = (state: StoreState) =>
  state.noShowLateHandlingPrices.progress.bannerState;

export const NoShowLateHandlingRoutePricesHeader: React.FC = () => {
  const noShowLateHandlingRoutePricesData = useSelector(
    noShowLateHandlingRoutePricesDataSelector
  );
  const bannerState = useSelector(bannerStateSelector);

  const dispatch = useDispatch();

  const handleResetTable = () => {
    dispatch(
      noShowLateHandlingRoutePricesActions.setProgress({
        bannerState: undefined,
      })
    );
    const persistedData = noShowLateHandlingRoutePricesData
      .filter((routePricesData) => {
        return routePricesData.persisted.newlyAddedRow === false;
      })
      .map((routePriceData) => {
        return {
          id: routePriceData.id,
          editable: routePriceData.persisted,
          persisted: routePriceData.persisted,
        };
      });

    const persistedId = persistedData.flatMap((pd) => pd.id);

    const groupPersistedIdByRoutePairCode = groupBy(persistedId, (group) =>
      group?.slice(0, 3)
    );

    const currentlySelected = Object.values(
      groupPersistedIdByRoutePairCode
    ).map((selected) => {
      return {
        currentlySelectedId: selected[0]!,
        routePairCode: selected[0]!.slice(0, 3),
      };
    });

    dispatch(
      noShowLateHandlingRoutePricesCurrentlySelectedActions.setSelectedFields(
        currentlySelected
      )
    );
    dispatch(
      noShowLateHandlingRoutePricesActions.resetTableState(persistedData)
    );
  };

  const isDataModified = useMemo(() => {
    return noShowLateHandlingRoutePricesData.some((routePriceData) =>
      dataHasBeenModified(routePriceData, true)
    );
  }, [noShowLateHandlingRoutePricesData]);

  const isDataModifiedDiscard = useMemo(() => {
    return noShowLateHandlingRoutePricesData.some((routePriceData) =>
      dataHasBeenModified(routePriceData)
    );
  }, [noShowLateHandlingRoutePricesData]);

  const handleUpdateRoutePrices = async (): Promise<void> => {
    const dataForUpdate: UpdateAllRoutePricesData =
      noShowLateHandlingRoutePricesData
        .filter((routePriceData) => {
          return dataHasBeenModified(routePriceData, true);
        })
        .map((data) => handleUpdateRoutePricesDataForUpdateTransformer(data));

    const newTableData = noShowLateHandlingRoutePricesData.map(
      (routePriceData) => {
        return {
          id: routePriceData.id,
          editable: { ...routePriceData.editable, newlyAddedRow: false },
          persisted: { ...routePriceData.editable, newlyAddedRow: false },
        };
      }
    );

    await dispatch(updateAllRoutePrices(dataForUpdate, newTableData));
    await dispatch(fetchNoShowLateHandlingRoutePrices());
  };

  usePrompt({
    message: "You have unsaved changes, would you like to leave the page?",
    shouldBlock: isDataModified || isDataModifiedDiscard,
  });

  return (
    <PageHeader
      title={"Route prices"}
      offsetLeft={0}
      offsetTop={0}
      icon={stenaBusinessNslh}
      sticky
      width={"100vw"}
      contentRight={
        <HeaderContentRight
          isDataModified={isDataModified}
          isDataModifiedDiscard={isDataModifiedDiscard}
          handleResetTable={handleResetTable}
          handleUpdateRoutePrices={handleUpdateRoutePrices}
        />
      }
      contentUnder={
        bannerState && (
          <ResultListBanner bannerState={bannerState} variant={"error"} />
        )
      }
    />
  );
};
