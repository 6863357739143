import { InfoPair } from "@/common/components/info-pair/InfoPair";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import {
  PriceCalculatorCustomerDetailsV2Query,
  PriceCalculatorCustomerDetailsV2QueryVariables,
} from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { Box, Heading, Row, Space } from "@stenajs-webui/core";
import {
  Banner,
  FlatButton,
  PrimaryButton,
  Spinner,
} from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import { gql } from "apollo-boost";
import * as React from "react";

//FIXME: Remove V2
const query = gql`
  query PriceCalculatorCustomerDetailsV2($customerId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          name
          custIndex
          custNo
          statisticalGrouping {
            id
            custNo
          }
          parent {
            id
            custNo
          }
        }
      }
    }
  }
`;

export interface Props {
  selectedCustomerNum: number;
  onChangeCustomer: () => void;
}

export const PriceCalculatorCustomerDetails: React.FC<Props> = ({
  selectedCustomerNum,
  onChangeCustomer,
}) => {
  const { loading, error, data } = useQuery<
    PriceCalculatorCustomerDetailsV2Query,
    PriceCalculatorCustomerDetailsV2QueryVariables
  >(query, {
    variables: {
      customerId: String(selectedCustomerNum),
    },
  });
  const customerDetails = data?.productPrice.customers.byId;

  if (error) {
    return (
      <Box>
        <Banner
          headerText={"Could not fetch customer information"}
          text={error.message}
          variant={"error"}
          contentRight={
            <PrimaryButton
              size={"medium"}
              label={"Change customer"}
              onClick={onChangeCustomer}
            />
          }
        />
      </Box>
    );
  }

  return (
    <Box
      background={cssColor("--lhds-color-ui-200")}
      indent={IndentValues.TWENTYFOUR}
      spacing={SpacingValues.TWENTYFOUR}
    >
      <Row justifyContent={"space-between"} alignItems={"center"}>
        <Heading variant={"h4"}>
          {loading ? <Spinner size={"small"} /> : customerDetails?.name}
        </Heading>
        <FlatButton
          size={"medium"}
          label={"Change customer"}
          onClick={onChangeCustomer}
          data-testid={
            testIdConstants.priceCalculatorCalculateChangeCustomerButton
          }
        />
      </Row>
      <Space num={SpaceValues.SIXTEEN} />
      <Row>
        <InfoPair
          leftContent={"No."}
          leftTextSize={"medium"}
          leftTextColor={cssColor("--lhds-color-ui-500")}
          rightContent={customerDetails?.custNo}
          rightTextSize={"medium"}
          rightTextColor={cssColor("--lhds-color-ui-700")}
          borderBottom={false}
          indentAfterRightContent={2}
          spacingTopAndBottom={0}
        />
        <InfoPair
          leftContent={"Index"}
          leftTextSize={"medium"}
          leftTextColor={cssColor("--lhds-color-ui-500")}
          rightContent={customerDetails?.custIndex}
          rightTextSize={"medium"}
          rightTextColor={cssColor("--lhds-color-ui-700")}
          borderBottom={false}
          indentAfterRightContent={2}
          spacingTopAndBottom={0}
        />
        <InfoPair
          leftContent={"Stat no."}
          leftTextSize={"medium"}
          leftTextColor={cssColor("--lhds-color-ui-500")}
          rightContent={customerDetails?.statisticalGrouping?.custNo}
          rightTextSize={"medium"}
          rightTextColor={cssColor("--lhds-color-ui-700")}
          borderBottom={false}
          indentAfterRightContent={2}
          spacingTopAndBottom={0}
        />
        <InfoPair
          leftContent={"Parent no."}
          leftTextSize={"medium"}
          leftTextColor={cssColor("--lhds-color-ui-500")}
          rightContent={
            customerDetails?.parent?.custNo ?? customerDetails?.custNo
          }
          rightTextSize={"medium"}
          rightTextColor={cssColor("--lhds-color-ui-700")}
          borderBottom={false}
          indentAfterRightContent={2}
          spacingTopAndBottom={0}
        />
      </Row>
    </Box>
  );
};
